import { StyleSheet } from "react-native";
import { palette } from "@huum/theme/helpers";
export const getStyles = (theme) => {
    const { colors, space } = theme;
    return StyleSheet.create({
        text: {
            fontWeight: "400",
            color: palette.dimmedText
        },
        activeText: {
            fontWeight: "bold",
        },
    });
};
