import { useStores } from "@huum/store";
import { useMutation } from "@tanstack/react-query";
import { get } from "lodash";
import { updateControllerSoftwareRequest } from "@huum/api/requests/sauna";
import { translate } from "@huum/i18n";
import { useTheme } from "native-base";
export const useControllerSoftwareUpdate = () => {
    const { auth, modal, sauna } = useStores();
    const { config } = useTheme();
    const session = get(auth, "sessionHash", null);
    const { mutate } = useMutation({
        mutationFn: updateControllerSoftwareRequest,
    });
    const handleUpdateControllerSoftware = () => {
        modal.openModal("confirmModal", {
            onConfirm: () => {
                mutate({ session, saunaId: sauna.saunaId });
                modal.closeModal();
            },
            modalTitle: translate("text.software_update_modal_heading"),
            contentText: translate("text.software_update_modal_text", { supportEmail: config.helpEmail }),
            confirmButtonLabel: translate("label.yes"),
            cancelButtonLabel: translate("label.no"),
        });
    };
    return {
        handleUpdateControllerSoftware
    };
};
