import React from "react";
import { Box, Divider, Heading, FlatList, Pressable, Row, Text, VStack, Stack } from "native-base";
import { Icon } from "@huum/components/atoms/icon";
import Card from "@huum/components/atoms/card";
const Playlist = ({ songs, onSongPress, currentSongId }) => {
    const renderSongCard = ({ item }) => {
        const isActive = currentSongId === item.id;
        return (<Pressable onPress={() => onSongPress(item.id)} mb={3}>
        <Card bg="white" borderWidth={1} borderColor="gray.100" background="white.100" shadow={2} minWidth={{
                md: 380,
                sm: "100%"
            }}>
          <Row justifyContent="space-between" alignItems="center">
            <VStack>
              <Text fontSize="md" fontWeight="600" color="darkText">
                {item.title}
              </Text>
            </VStack>
            <Box pr={4}>
              <Icon icon={isActive ? "pause" : "play"} size={6}/>
            </Box>
          </Row>
        </Card>
      </Pressable>);
    };
    return (<Stack flex={1} height="full">
      <FlatList ListHeaderComponent={<>
            <Heading size="md" mb={4} color="darkText">
              Playlist
            </Heading>
            <Divider mb={4}/>
          </>} data={songs} renderItem={renderSongCard} keyExtractor={(item) => item.id} showsVerticalScrollIndicator={false} flex={1} height="100%" // Ensure FlatList takes up all available space
     contentContainerStyle={{
            flexGrow: 1,
            paddingTop: 16,
            paddingHorizontal: 16
        }}/>
    </Stack>);
};
export default Playlist;
