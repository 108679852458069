import { connectSauna, saunaSessionRequest } from "@huum/api/requests/sauna";
import { useStores } from "@huum/store";
import { useMutation } from "@tanstack/react-query";
import { get, toNumber } from "lodash";
import { useCallback, useState } from "react";
import { isNil, isNull } from "lodash";
import { translate } from "@huum/i18n/translate";
import { navigate } from "@huum/services/NavigationService";
export const useSaunaConnect = () => {
    const { auth, sauna, modal } = useStores();
    const { mutate, data: response, isError, isSuccess } = useMutation({
        mutationFn: connectSauna,
    });
    const [showCodeInput, setShowCodeInput] = useState(false);
    const [saunaIsConnected, setSaunaIsConnected] = useState(false);
    const session = get(auth, "sessionHash", null);
    const saunaConnect = useCallback((values) => {
        mutate({ ...values, session }, {
            onSuccess: (data) => {
                if (isNull(data?.paymentEndDate) || data?.paymentEndDate) {
                    sauna.setPaymentEndDate(data.paymentEndDate);
                    modal.closeModal();
                }
                if (!isNil(data?.isFirstUser)) {
                    sauna.setFirstUser(data.isFirstUser);
                }
                if (!values.saunaCode) {
                    if (data?.needsPurchase) {
                        sauna.setPaymentSaunaId(values.saunaID);
                        modal.openModal('confirmModal', {
                            content: translate('text.app_usage_payment_info_confirm', { price: data?.needsPurchase }),
                            onConfirm: () => navigate('paymentSettings'),
                            modalTitle: translate('title.connect_with_sauna'),
                            contentText: translate("text.app_usage_payment_info_confirm", { price: data?.needsPurchase }),
                            confirmButtonLabel: translate('button.confirm'),
                            cancelButtonLabel: translate('button.cancel'),
                        });
                    }
                    else {
                        setShowCodeInput(true);
                    }
                }
                if (values.saunaCode) {
                    sauna.setSaunaId(values.saunaID);
                    setSaunaIsConnected(true);
                    if (toNumber(values.saunaID) >= 500000) {
                        navigate('smartConfigConnect');
                        modal.closeModal();
                        return;
                    }
                }
            },
            onError: () => {
                setSaunaIsConnected(false);
            }
        });
    }, [mutate, response]);
    const setSaunaIsPrivate = useCallback((isPrivate) => {
        sauna.setSaunaIsPrivate(!!isPrivate);
        saunaSessionRequest({ isPrivate, type: "update", session });
    }, [session]);
    return { saunaConnect, isError, isSuccess: saunaIsConnected, showCodeInput, setSaunaIsPrivate };
};
