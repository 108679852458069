import { Box, useTheme } from "native-base";
import React from "react";
const Card = (props) => {
    const { children, ...rest } = props;
    const { colors } = useTheme();
    return (<Box bg={colors.white[100]} borderRadius={16} padding={4} shadow={3} {...rest}>
      {children}
    </Box>);
};
export default Card;
