import React from "react";
import { Text } from "@huum/components/atoms/text";
import { ModalContainer } from "./modal-container";
import { translate } from "@huum/i18n";
import { Stack } from "native-base";
import Button from "@huum/components/atoms/button";
import TextButton from "@huum/components/molecules/text-button";
const ConfirmModal = (props) => {
    const { onConfirm, onCancel, modalTitle, closeModal, contentText, confirmButtonLabel, cancelButtonLabel, } = props;
    const handleConfirm = () => {
        onConfirm && onConfirm();
        closeModal();
    };
    const handleCancel = () => {
        onCancel && onCancel();
        closeModal();
    };
    return (<ModalContainer title={modalTitle} closeModal={closeModal}>
      <Text preset="subtitle2" alignSelf={"center"} mb={8}>
        {contentText}
      </Text>
      <Stack space={4} justifyContent="center" alignItems="center">
        <Button width="100%" onPress={handleConfirm} label={confirmButtonLabel}/>
        {cancelButtonLabel && <TextButton onPress={handleCancel} title={cancelButtonLabel || translate('button.cancel')}/>}
      </Stack>
    </ModalContainer>);
};
export default ConfirmModal;
