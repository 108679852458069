import React from "react";
import { Stack, } from "native-base";
import { translate } from "@huum/i18n";
import { observer } from "mobx-react-lite";
import DeviceSettingsView from "./components/deviceSettings";
import { MainHeader } from "@huum/components/molecules/header/main-header";
const LedControllerSettings = observer(() => {
    return (<Stack flex={1} space={4} safeArea>
      <MainHeader withBackNavigation title={translate("label.led_controller_settings")}/>
      <DeviceSettingsView />
    </Stack>);
});
export default LedControllerSettings;
