import { Animated, } from "react-native";
import { DEFAULT_CONTROLLER_WIDTH } from "./helpers";
import React, { useRef, useState } from "react";
import Svg from "react-native-svg";
import { Box, View } from "native-base";
import { Text } from "@huum/components/atoms/text";
import { getTemperatureValue } from "@huum/utils/helpers/sauna-temperature-helpers";
import { useStores } from "@huum/store";
import { observer } from "mobx-react-lite";
import { useDerivedValue } from "react-native-reanimated";
import { useControllerPanResponder } from "./hooks";
import PulsatingShadowCircle from "@huum/components/molecules/pulsating-circle";
import ControllerGradientPath from "./gradient-path";
import { ControllerArc } from "./empty-arc";
const SaunaController = observer((props) => {
    const { width: customWidth, isHeating, heatingTemperature, targetTemperature, mode = "temperature", topOffset = 0, temperatureEditIsDisabled, temperatureColor, controllerCircleText, onTemperatureChange, InnerCircleContent, ExtraContent, onPress, } = props;
    const width = customWidth || DEFAULT_CONTROLLER_WIDTH;
    const { sauna } = useStores();
    const controllerCircleSize = width < 200 ? 30 : 52;
    const controllerCircleTextSize = width < 200 ? 10 : 16;
    const innerCircleSize = width / 2 + (width < 200 ? 24 : 48);
    const controllerTempTimeoutRef = useRef(null);
    const [showTargetTemp, setShowTargetTemp] = useState(mode === "power");
    const onTemperatureChangeStartCallback = (temperature) => {
        clearTimeout(controllerTempTimeoutRef.current);
        setShowTargetTemp(true);
    };
    const onTemperatureChangeEndCallback = (temperature) => {
        onTemperatureChange(temperature);
        if (controllerTempTimeoutRef.current) {
            clearTimeout(controllerTempTimeoutRef.current);
        }
        controllerTempTimeoutRef.current = setTimeout(() => {
            setShowTargetTemp(false);
        }, 3000);
    };
    const onControllerPressCallback = () => {
        onPress && onPress();
        if (!temperatureEditIsDisabled) {
            setShowTargetTemp(false);
        }
    };
    const { panResponder, position, temperatureSharedPercentage, controllerTargetTemperature, handleControllerLayout, } = useControllerPanResponder({
        width,
        minTemperature: 0,
        maxTemperature: 110,
        targetTemperature,
        isHeating,
        mode,
        topOffset,
        onTemperatureChangeEndCallback,
        onTemperatureChangeStartCallback,
    });
    const getControllerCircleText = () => {
        if (controllerCircleText) {
            return controllerCircleText({ temperature: controllerTargetTemperature });
        }
        return `${getTemperatureValue(controllerTargetTemperature, sauna.temperatureUnit)}°`;
    };
    const gradientColor = temperatureColor(controllerTargetTemperature);
    const heatingTemperaturePercentage = useDerivedValue(() => {
        if (isHeating && heatingTemperature) {
            return heatingTemperature / 110 * 100;
        }
        return temperatureSharedPercentage?.value || 0;
    }, [heatingTemperature, isHeating]);
    const visibleTemperature = showTargetTemp ? controllerTargetTemperature : heatingTemperature || 0;
    return (<Box alignItems="center" justifyContent="center" alignSelf="center" width={width} height={width} onLayout={handleControllerLayout}>
      <Svg height={width} width={width} viewBox={"0 0 294 294"}>
        {ExtraContent && <ExtraContent />}
        <ControllerArc width={width}/>
        <ControllerGradientPath monotone={mode === "power"} temperatureColor={gradientColor} temperaturePercentage={heatingTemperaturePercentage} size={width}/>
      </Svg>
      {!temperatureEditIsDisabled && (<View position="absolute" width={width + 30} height={width + 30}>
          <Animated.View style={position.current?.getLayout()} {...panResponder?.current?.panHandlers}>
            <Box height={`${controllerCircleSize}px`} width={`${controllerCircleSize}px`} borderRadius={controllerCircleSize / 2} alignItems="center" justifyContent="center" background={gradientColor}>
              <Text color="white.100" fontSize={controllerCircleTextSize}>
                {getControllerCircleText()}
              </Text>
            </Box>
          </Animated.View>
        </View>)}
      <PulsatingShadowCircle isHeating={isHeating} innerCircleSize={innerCircleSize} gradientColor={gradientColor} onPress={onControllerPressCallback} InnerCircleContent={InnerCircleContent} temperature={visibleTemperature}/>
      {/* <Pressable
          onPress={onControllerPressCallback}
          position="absolute"
          height={`${innerCircleSize}px`}
          width={`${innerCircleSize}px`}
          shadow={isHeating ? 9 : 3}
          background="white.100"
          borderRadius={innerCircleSize / 2}
          borderWidth={isHeating? 1 : 0}
          borderColor={isHeating? gradientColor : "transparent"}
          style={{ shadowColor: isHeating ? gradientColor : "black", shadowOffset: { width: 0, height: isHeating ? 8 : 2 }}}
          alignItems="center"
          justifyContent="center"
          _pressed={{
            shadow: 0,
          }}
        >
          {InnerCircleContent && (
            <InnerCircleContent temperature={visibleTemperature} />
          )}
        </Pressable> */}
    </Box>);
});
export default SaunaController;
