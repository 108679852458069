import { includes } from 'lodash';
import { useTheme } from 'native-base';
import { Platform } from 'react-native';
import { Dimensions } from 'react-native';
const { width } = Dimensions.get('screen');
export const COLOR_WHEEL_SIZE = !includes(["android", "ios"], Platform.OS) ? Math.min(width - 32, 450) : Math.ceil(width * 0.8);
const COLOR_WHEEL_INNER_SIZE = COLOR_WHEEL_SIZE * 0.6;
const COLOR_WHEEL_IMAGE_SIZE = COLOR_WHEEL_SIZE * 0.2;
export const useStyles = () => {
    const { colors } = useTheme();
    return {
        slider: {
            width: width * 0.4
        },
        thumb: {
            width: 28,
            height: 28,
            backgroundColor: colors.primaryDark,
            borderRadius: 14
        },
        track: {},
        colorPicker: {
            height: COLOR_WHEEL_SIZE,
            width: COLOR_WHEEL_SIZE,
            margin: -10,
        },
        colorPickerImageBox: {
            position: "absolute",
            width: COLOR_WHEEL_INNER_SIZE,
            height: COLOR_WHEEL_INNER_SIZE,
            borderRadius: COLOR_WHEEL_INNER_SIZE / 2,
        },
        colorPickerImage: {
            height: COLOR_WHEEL_IMAGE_SIZE,
            width: COLOR_WHEEL_IMAGE_SIZE,
            margin: COLOR_WHEEL_IMAGE_SIZE,
        }
    };
};
