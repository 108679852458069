import { Text } from "@huum/components/atoms/text";
import { Row } from "native-base";
import { IconButton } from "../icon-button";
import { useCallback } from "react";
import { useStores } from "@huum/store";
const InfoLabel = (props) => {
    const { label, infoText, ...rest } = props;
    const { modal } = useStores();
    const handleInfoPress = useCallback(() => {
        modal.openModal('iconModal', { contentText: infoText });
    }, [infoText]);
    return (<Row alignItems="center" space={2}>
      <Text {...rest}>
        {label}
      </Text>
      <IconButton size={8} icon="info" onPress={handleInfoPress}/>
    </Row>);
};
export default InfoLabel;
