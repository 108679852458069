import { types } from "mobx-state-tree";
import uuid from "react-native-uuid";
/**
 * Model description here for TypeScript hints.
 */
export const ToastStoreModel = types
    .model("ToastStore")
    .props({
    messages: types.optional(types.array(types.frozen()), []),
})
    .views(self => ({})) // eslint-disable-line @typescript-eslint/no-unused-vars
    .actions(self => ({
    showToast: (toastMessage) => {
        self.messages.push({
            ...toastMessage,
            id: uuid.v4()
        });
    },
    hideToast: (toastId) => {
        self.messages = self.messages.filter(message => message.id !== toastId);
    },
    clearAll: () => {
        self.messages.clear();
    }
})); // eslint-disable-line @typescript-eslint/no-unused-vars
export const createToastStoreDefaultModel = () => types.optional(ToastStoreModel, {});
