import React, { useCallback } from "react";
import { FormInput } from "@huum/components/atoms/form-input";
import { Text } from "@huum/components/atoms/text";
import { ModalContainer } from "./modal-container";
import { translate } from "@huum/i18n";
import { useForm } from "react-hook-form";
import { Box } from "native-base";
import { useSaunaConnect } from "@huum/hooks/api/useSaunaConnect";
import { validateNumber } from "@huum/utils/validator";
import { useStores } from "@huum/store";
import Button from "@huum/components/atoms/button";
const SaunaModal = (props) => {
    const { saunaId, buttonLabel, modalTitle, closeModal, } = props;
    const { auth, sauna } = useStores();
    const { control, handleSubmit, watch } = useForm();
    const { saunaConnect, isSuccess, isError, showCodeInput } = useSaunaConnect();
    const saunaIdValue = watch("newSaunaId");
    const saunaCodeValue = watch("newSaunaCode");
    const isValidId = validateNumber(saunaIdValue);
    const handleSaunaChange = useCallback((values) => {
        saunaConnect({
            saunaID: values.newSaunaId,
            saunaCode: values.newSaunaCode,
        });
        if (isSuccess && saunaCodeValue) {
            sauna.setSaunaId(values.newSaunaId);
        }
    }, [isSuccess, saunaCodeValue]);
    const errorMessages = useCallback(() => {
        if (!isValidId && saunaIdValue) {
            return translate("error.sauna_id");
        }
        else if (isError) {
            return translate("error.error_connect_sauna");
        }
        else
            "";
    }, [isValidId, saunaIdValue, isError]);
    return (<ModalContainer title={modalTitle} closeModal={closeModal}>
      <Text preset="subtitle2" alignSelf={"center"} mb={8}>
        {translate("button.change_sauna_id")}
      </Text>
      <Box mb={8}>
        <FormInput name="newSaunaId" control={control} placeholder={saunaId} keyboardType={"number-pad"}/>
        {showCodeInput && (<FormInput name="newSaunaCode" control={control} placeholder={translate("label.sauna_code")} keyboardType={"number-pad"}/>)}
        <Box alignItems="center">
          <Text preset="error">{errorMessages()}</Text>
        </Box>
      </Box>
      <Box>
        <Button onPress={handleSubmit(handleSaunaChange)} label={buttonLabel}/>
      </Box>
    </ModalContainer>);
};
export default SaunaModal;
