import { Box } from "native-base";
export const ARC_PATH = "M65.5918 258.549C41.9294 241.357 24.3169 217.116 15.2787 189.299C6.24045 161.482 6.24044 131.518 15.2787 103.701C24.3169 75.8844 41.9293 51.6429 65.5917 34.4512C89.2541 17.2594 117.752 8 147 8C176.248 8 204.746 17.2594 228.408 34.4511C252.071 51.6429 269.683 75.8843 278.721 103.701C287.76 131.518 287.76 161.482 278.721 189.299C269.683 217.116 252.071 241.357 228.408 258.549";
export const ControllerArc = (props) => {
    const { width } = props;
    return (<Box position="absolute" width={width} height={width} flex={1}>
      <svg width={width} height={width} viewBox="0 0 294 294" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g filter="url(#filter0_i_1209_51)">
          <path d={`${ARC_PATH}`} stroke="#FBFBFB" stroke-width="16" stroke-linecap="round"/>
        </g>
        <defs>
          <filter id="filter0_i_1209_51" x="0.5" y="0" width="295" height="267" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
            <feFlood flood-opacity="0" result="BackgroundImageFix"/>
            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
            <feOffset dx="2" dy="2"/>
            <feGaussianBlur stdDeviation="2.5"/>
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
            <feColorMatrix type="matrix" values="0 0 0 0 0.0196078 0 0 0 0 0.356863 0 0 0 0 0.439216 0 0 0 0.25 0"/>
            <feBlend mode="normal" in2="shape" result="effect1_innerShadow_1209_51"/>
          </filter>
        </defs>
      </svg>
    </Box>);
};
