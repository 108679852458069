import { Icon } from "@huum/components/atoms/icon";
import { Text } from "@huum/components/atoms/text";
import { Touchable } from "@huum/components/atoms/touchable/touchable";
import { translate } from "@huum/i18n";
import { hitSlop } from "@huum/theme/helpers/styles";
import { Box, Row } from "native-base";
export const DateSelector = (props) => {
    const { onDateChange, label, sessionsCount = 0 } = props;
    const handleDateChange = (increment) => () => {
        onDateChange(increment);
    };
    return (<Row h={16} justifyContent="space-between" alignItems="center" borderRadius={16} px={4}>
      <Touchable hitSlop={hitSlop} onPress={handleDateChange(-1)}>
        <Icon icon="large-left" size={8}/>
      </Touchable>
      <Box flexDir={"column"} alignItems={"center"}>
        <Text preset="subtitle2" fontWeight="700">
          {label}
        </Text>
        <Text fontSize={14} lineHeight={20}>{`${translate("label.sessions_count", { count: sessionsCount })}`}</Text>
      </Box>
      <Touchable hitSlop={hitSlop} onPress={handleDateChange(1)}>
        <Icon icon="large-right" size={8}/>
      </Touchable>
    </Row>);
};
