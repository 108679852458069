import Button from "@huum/components/atoms/button";
import { Text } from "@huum/components/atoms/text";
import PlaceholderController from "@huum/components/organisms/sauna-controller/placeholder-controller";
import PowerLevelController from "@huum/components/organisms/sauna-controller/power-level-controller";
import TemperatureController from "@huum/components/organisms/sauna-controller/temperature-controller";
import { useSaunaController } from "@huum/hooks/api/useSaunaController";
import { translate } from "@huum/i18n";
import { Row, Stack } from "native-base";
import { Dimensions } from "react-native";
const { width } = Dimensions.get("screen");
const CONTROLLER_WIDTH = (width / 2) - (4 * 12);
const getControllerComponent = (controllerType) => {
    switch (controllerType) {
        case "power":
            return PowerLevelController;
        case "temperature":
            return TemperatureController;
        case "add":
            return PlaceholderController;
        default:
            return PlaceholderController;
    }
};
const ControllerListItem = (props) => {
    const { controllerType, onPress, saunaId, saunaName, onStartStopPress, ...restStatus } = props;
    const { temperature, targetTemperature, statusCode } = restStatus;
    const Controller = getControllerComponent(controllerType);
    const { startSaunaSession, stopSaunaSession, } = useSaunaController(saunaId, restStatus);
    const saunaNameText = saunaName || saunaId;
    const handleStartSauna = async () => {
        await startSaunaSession({ targetTemperature: targetTemperature });
        onStartStopPress && onStartStopPress();
    };
    const handleStopSauna = async () => {
        await stopSaunaSession();
        onStartStopPress && onStartStopPress();
    };
    return (<Stack mb={4} space={4} p={4} alignItems="center">
      <Row alignItems="center" space={2} height="34px">
        <Text fontSize="lg" bold>
          {controllerType !== "add" ? saunaNameText : ""}
        </Text>
        {/* <Icon size={6} icon="right" /> */}
      </Row>
      <Controller width={CONTROLLER_WIDTH} temperature={temperature || 0} targetTemperature={targetTemperature || 0} onTemperatureChange={() => null} temperatureEditIsDisabled={true} handleSaunaStopPress={() => handleStopSauna()} handleSaunaStartPress={() => handleStartSauna()} statusCode={statusCode}/>
      <Row height="34px">
        {controllerType !== "add" && (<Button onPress={onPress} label={translate("button.select")} height="34px" _text={{ fontSize: "xs", px: 2, lineHeight: "sm" }}/>)}
      </Row>
    </Stack>);
};
export default ControllerListItem;
