import { create } from "apisauce";
/**
 * Manages all requests to the API.
 */
export class Api {
    /**
     * Configurable options.
     */
    huum;
    /**
     * Creates the api.
     *
     * @param config The configuration to use.
     */
    constructor() {
        this.huum = create({
            baseURL: "https://saunadev.huum.eu",
            timeout: 20000,
            headers: {
                Accept: "application/json",
            },
        });
    }
    /**
     * Sets up the API.  This will be called during the bootup
     * sequence and will happen before the first React component
     * is mounted.
     *
     * Be as quick as possible in here.
     */
    setup() {
        this.huum = create({
            baseURL: "https://saunadev.huum.eu",
            timeout: 20000,
            headers: {
                Accept: "application/json",
            },
        });
    }
    setAuthToken(token) {
        const authValue = token ? `Bearer ${token}` : undefined;
        this.huum.setHeader("Authorization", authValue || "");
    }
}
