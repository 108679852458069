import React, { useCallback, useState } from "react";
import Button from "@huum/components/atoms/button";
import { translate } from "@huum/i18n";
import { useStores } from "@huum/store";
import { Box, } from "native-base";
import { ModalContainer } from "@huum/components/views/modals/modal-container";
import NumberSelect from ".";
const NumberSelectModal = (props) => {
    const { modal } = useStores();
    const { closeModal } = modal;
    const { title, value, onValueSelect, ...rest } = props;
    const [selectedValue, setSelectedValue] = useState(value);
    const handleValueChange = useCallback((value) => {
        setSelectedValue(value);
    }, []);
    const handleValueSave = useCallback(() => {
        onValueSelect(selectedValue);
        closeModal();
    }, [selectedValue, onValueSelect]);
    return (<ModalContainer title={title} closeModal={closeModal}>
      <Box flexGrow={1} justifyContent="space-between">
        <NumberSelect value={selectedValue} onValueSelect={handleValueChange} {...rest}/>
        <Button onPress={handleValueSave} label={translate("button.save")}/>
      </Box>
    </ModalContainer>);
};
export default NumberSelectModal;
