import { Text } from "@huum/components/atoms/text";
import { ActiveToolbarItem } from "@huum/utils/sauna-toolbar";
import { Center, Row, Stack } from "native-base";
import { useCallback } from "react";
import * as Animatable from "react-native-animatable";
import SaunaHumidityController from "../humidity-controller";
import SaunaLightController from "../light-controller";
import { Icon } from "@huum/components/atoms/icon";
import { translate } from "@huum/i18n";
import { Pressable } from "react-native";
import { Switch } from "@huum/components/molecules/switch";
import { useStores } from "@huum/store";
import { SaunaInfoSection } from "../sauna-info-section";
import { navigate } from "@huum/services/NavigationService";
const LightsController = (props) => {
    const { saunaStatus, onLightChange } = props;
    return (<Animatable.View animation="fadeInUp" duration={300}>
      <SaunaLightController statusCode={saunaStatus.statusCode} config={saunaStatus.config} setSaunaLightStatus={onLightChange} lightStatus={saunaStatus.light === 1}/>
    </Animatable.View>);
};
const HumidityController = (props) => {
    const { saunaStatus, onHumidityChange } = props;
    return (<SaunaHumidityController statusCode={saunaStatus.statusCode} config={saunaStatus.config} humidity={saunaStatus.humidity} updateHumidity={onHumidityChange}/>);
};
const ConfigButton = (props) => {
    const { subtitle, label, icon, onPress, iconSize = 10, buttonColor, onSwitch, switchStatus, iconColor, ExtraContent, } = props;
    return (<Row alignItems="center" space={8}>
      <Pressable onPress={onPress}>
        <Row alignItems="center" space={8}>
          <Center height={12} width={12} borderRadius={32} backgroundColor={buttonColor} shadow={2}>
            <Icon tint={iconColor || "white.100"} icon={icon} size={iconSize}/>
          </Center>
          <Stack>
            {!!label && (<Text bold color="primaryDark" fontSize="lg">
              {label}
            </Text>)}
            {!!subtitle && (<Text preset="subtitle1" color="primaryDark" fontSize="lg">
                {subtitle}
              </Text>)}
            {ExtraContent}
          </Stack>
        </Row>
      </Pressable>
      {onSwitch && <Switch value={switchStatus} onToggle={onSwitch}/>}
    </Row>);
};
const SaunaConfigsTabs = (props) => {
    const { modal } = useStores();
    const { onChangeItem, saunaStatus = {}, onHumidityChange, onLightChange, onTimerChange, saunaIsHeating, } = props;
    const { config, statusCode, humidity } = saunaStatus;
    const handleSelectActiveItem = useCallback((item) => {
        onChangeItem(item);
    }, [onChangeItem]);
    const onTimerPress = () => {
        modal.openModal("timerSelect", {
            title: translate('button.timer'),
            onValueSelect: onTimerChange,
        });
    };
    const onHumidityPress = () => {
        modal.openModal("humiditySelect", {
            statusCode: statusCode,
            config: config,
            humidity: humidity,
            updateHumidity: onHumidityChange,
        });
    };
    const onLedPress = () => {
        navigate("ledController");
    };
    return (<Stack p={8} space={10}>
      <ConfigButton onPress={onTimerPress} icon="timer" label={!saunaIsHeating && translate("button.timer")} buttonColor={saunaIsHeating ? "red.600" : "primaryLight"} ExtraContent={saunaIsHeating && (<SaunaInfoSection visible={true} saunaStatus={saunaStatus}/>)}/>
      <ConfigButton onPress={() => {
            handleSelectActiveItem(ActiveToolbarItem.LIGHTS);
        }} icon="lights-new" label={translate("button.lights")} buttonColor="primaryDark" onSwitch={onLightChange} switchStatus={saunaStatus.light === 1}/>
      <ConfigButton onPress={onHumidityPress} icon={"humidity-new"} label={translate("button.humidity")} buttonColor="primaryLight" onSwitch={onHumidityPress} switchStatus={humidity > 0}/>
      <ConfigButton onPress={onLedPress} icon="led-new" label={translate("button.led_controller")} buttonColor="yellow.400"/>
    </Stack>);
};
export default SaunaConfigsTabs;
