import AnimatedInfoSection from "@huum/components/organisms/animated-info-section";
import { useSaunaInfoSections } from "@huum/hooks/useSaunaInfoSections";
import { Box } from "native-base";
export const SaunaInfoSection = (props) => {
    const { saunaStatus, visible } = props;
    const infoSections = useSaunaInfoSections({ saunaStatus });
    return (<Box>
      {visible &&
            <AnimatedInfoSection visible={visible} contentSections={infoSections}/>}
    </Box>);
};
