import { Text } from "@huum/components/atoms/text";
import InfoLabel from "@huum/components/molecules/info-label";
import { Switch } from "@huum/components/molecules/switch";
import { useSaunaConfig } from "@huum/hooks/api/useSaunaConfig";
import { translate } from "@huum/i18n";
import { useStores } from "@huum/store";
import { hitSlop } from "@huum/theme/helpers/styles";
import { Row, Stack } from "native-base";
import { useEffect, useState } from "react";
import { Pressable } from "react-native";
const NumberSelect = (props) => {
    const { label, value, onChange, minValue, maxValue } = props;
    const { modal } = useStores();
    const handleValueSelect = (value) => {
        onChange(value);
    };
    const handleValuePress = () => {
        modal.openModal('numberSelect', {
            title: label,
            value,
            onValueSelect: handleValueSelect,
            minValue: minValue || 0,
            maxValue: maxValue || 11,
        });
    };
    return (<Row space="2" justifyContent="space-between">
      <Text preset="button">
        {label}
      </Text>

      <Pressable hitSlop={hitSlop} onPress={handleValuePress}>
        <Row space={2} alignItems="center">
          <Text bold>{value}</Text>
        </Row>
      </Pressable>
    </Row>);
};
const SoloControllerSettings = (props) => {
    const { saunaId, saunaStatus } = props;
    const { saunaConfig, updateSaunaConfig } = useSaunaConfig({ saunaId });
    const [configState, setConfigState] = useState(saunaConfig || {});
    const onChangeConfig = (field) => (value) => {
        const isOneClick = field.includes("oneClickConfig");
        const fieldKey = isOneClick ? field.split(".")[1] : field;
        const newValue = isOneClick ? { ...configState.oneClickConfig, [fieldKey]: value } : value;
        const updateKey = isOneClick ? "oneClickConfig" : field;
        setConfigState({
            ...configState,
            [updateKey]: newValue
        });
        updateSaunaConfig({
            ...configState,
            [updateKey]: newValue
        });
    };
    const onChangeConfigSwitch = (field) => (value) => {
        const switchValue = value ? "ON" : "OFF";
        onChangeConfig(field)(switchValue);
    };
    useEffect(() => {
        if (saunaConfig)
            setConfigState(saunaConfig);
    }, [saunaConfig]);
    if (parseInt(saunaId) < 500000 || !saunaConfig)
        return null;
    const oneClickConfig = configState.oneClickConfig || {};
    return (<Stack space={4}>
      <NumberSelect label={translate("label.min_heating_value")} value={configState.minTemp || 0} onChange={onChangeConfig("minTemp")}/>
      <NumberSelect label={translate("label.max_heating_value")} value={configState.maxTemp || 0} onChange={onChangeConfig("maxTemp")}/>
      <NumberSelect label={translate("label.min_heating_time")} value={configState.minHeatingTime || 0} onChange={onChangeConfig("minHeatingTime")}/>
      <NumberSelect label={translate("label.max_heating_time")} value={configState.maxHeatingTime || 0} onChange={onChangeConfig("maxHeatingTime")}/>
      <Row direction={"row"} justifyContent={"space-between"}>
        <InfoLabel preset="button" label={translate("label.rental_mode")} infoText={translate("info.rental_mode_info")}/>
       <Switch value={configState.rentalMode !== "OFF"} onToggle={onChangeConfigSwitch("rentalMode")}/>
      </Row>
      <Row direction={"row"} justifyContent={"space-between"}>
        <InfoLabel preset="button" label={translate("label.child_lock")}/>
       <Switch value={configState.childLock !== "OFF"} onToggle={onChangeConfigSwitch("childLock")}/>
      </Row>
      <NumberSelect label={translate("label.max_timer")} value={configState.maxTimer || 0} onChange={onChangeConfig("maxTimer")}/>
      <Stack space={4}>
        <Text bold>{translate("label.one_click_config")}</Text>
        <Row direction={"row"} justifyContent={"space-between"}>
          <InfoLabel preset="button" label={translate("label.one_click_start_lock")} infoText={translate("info.one_click_start_lock_info")}/>
          <Switch value={oneClickConfig.oneClickLock !== "OFF"} onToggle={onChangeConfigSwitch("oneClickConfig.oneClickLock")}/>
        </Row>
        <NumberSelect label={translate("label.one_click_config_duration")} value={oneClickConfig.oneClickHeatingDuration || 0} onChange={onChangeConfig("oneClickConfig.oneClickHeatingDuration")}/>
        <NumberSelect label={translate("label.one_click_config_timer_duration")} value={oneClickConfig.oneClickTimerDuration || 0} onChange={onChangeConfig("oneClickConfig.oneClickTimerDuration")}/>
        <NumberSelect label={translate("label.one_click_target_temperature")} value={oneClickConfig.oneClickTargetTemp || 0} onChange={onChangeConfig("oneClickConfig.oneClickTargetTemperature")}/>
      </Stack>
    </Stack>);
};
export default SoloControllerSettings;
