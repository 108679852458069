import React, { useCallback, useState } from "react";
import { Row, Stack } from "native-base";
import { Switch } from "../switch";
import { Text } from "@huum/components/atoms/text";
import InfoLabel from "../info-label";
const ToggleSection = (props) => {
    const { isToggled, onToggle, disabled, infoText, ExtraComponent, label, } = props;
    const [toggleState, setToggleState] = useState(isToggled);
    const handleOnTogglePress = useCallback((state) => {
        if (disabled)
            return;
        setToggleState(state);
        onToggle && onToggle(state);
    }, [onToggle]);
    const Label = () => {
        if (!infoText)
            return (<Text>{label}</Text>);
        return (<InfoLabel label={label || ""} infoText={infoText}/>);
    };
    ;
    return (<Stack>
      <Row justifyContent="space-between" alignItems="center">
        <Row>
          <Label />
        </Row>
        <Switch value={isToggled} onToggle={handleOnTogglePress} disabled={!!disabled}/>
      </Row>
      {!!ExtraComponent && isToggled && (<ExtraComponent isToggled={isToggled} disabled={!!disabled}/>)}
    </Stack>);
};
export default ToggleSection;
