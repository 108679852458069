import React from "react";
import { Touchable } from "@huum/components/atoms/touchable/touchable";
import { Text } from "@huum/components/atoms/text";
import { palette } from "@huum/theme/helpers";
import { Row } from "native-base";
import { Icon } from "@huum/components/atoms/icon";
const TextButton = (props) => {
    const { title, extraTitle, preset, onPress, hitSlop, iconRight, ...rest } = props;
    return (<Touchable hitSlop={hitSlop} onPress={onPress}>
     <Row alignItems="center">
        <Text preset={preset} {...rest}>
          {title}{" "}
          <Text preset={preset} style={{ color: palette.invalid }}>
            {extraTitle}
          </Text>
        </Text>
        {iconRight && (<Icon icon={iconRight} size={8}/>)}
     </Row>
    </Touchable>);
};
export default TextButton;
