import { Icon } from "@huum/components/atoms/icon";
import { Pressable, Row, Stack } from "native-base";
import { Text } from '@huum/components/atoms/text';
import { useCallback, useMemo, useState } from "react";
import { map } from "lodash";
import { getHitSlop } from "@huum/utils/hitSlop";
const SidebarItem = (props) => {
    const { isActive, label, icon, onPress } = props;
    return (<Pressable hitSlop={getHitSlop(8)} onPress={onPress}>
      <Row space={2} alignItems="center">
        <Icon color="primaryDark" icon={icon} size={6}/>
        <Text bold={!!isActive} color="primaryDark" fontSize={12}>{label}</Text>
      </Row>
    </Pressable>);
};
const SideBar = (props) => {
    const { items, activeItem, onActiveItemChange, HeaderComponent } = props;
    const [activeKey, setActiveKey] = useState(activeItem);
    const handleListItemPress = useCallback((key) => () => {
        setActiveKey(key);
        onActiveItemChange(key);
    }, [onActiveItemChange]);
    const itemsList = useMemo(() => (map(items, (item) => (<SidebarItem {...item} onPress={handleListItemPress(item.key)} isActive={item.key === activeKey}/>))), [items, activeKey, handleListItemPress]);
    return (<Stack width="1/5">
      {HeaderComponent}
      <Stack mt={4} flex={1} space={4} px={8}>
        {itemsList}
      </Stack>
    </Stack>);
};
export default SideBar;
