import { toNumber } from "lodash";
import { Select } from "native-base";
export const LedChannelSelect = (props) => {
    const { visible, activeChannel = 0 } = props;
    if (!visible) {
        return null;
    }
    return (<Select minWidth={150} defaultValue={activeChannel?.toString()} selectedValue={activeChannel?.toString()} onValueChange={(itemValue) => {
            props.onChannelChange && props.onChannelChange(toNumber(itemValue));
        }} variant="unstyled">
      <Select.Item label="All channels" value="0"/>
      <Select.Item label="Channel 1" value="1"/>
      <Select.Item label="Channel 2" value="2"/>
    </Select>);
};
