import React from "react";
import { SafeAreaView } from "react-native-safe-area-context";
import { getStyles } from "./styles";
import { Box, Row } from "native-base";
import { goBack } from "@huum/services/NavigationService";
import { useThemedStyles } from "@huum/hooks/useThemedStyles";
import { IconButton } from "../icon-button";
/**
 * Header that appears on many screens. Will hold navigation buttons and screen title.
 */
export function BaseHeader(props) {
    const { withBackNavigation, children, isPlain = false, onBackPress, } = props;
    const style = useThemedStyles(getStyles);
    const headerContainerStyle = isPlain ? style.headerContainerPlain : style.headerContainer;
    // const extraPadding = Platform.OS === "web" ? 4 : 0;
    return (<SafeAreaView edges={["top"]} style={headerContainerStyle}>
      <Row flex={1} minHeight={8} alignItems="center" px={2}>
        {withBackNavigation && (<Box pr={4}>
            <IconButton size={10} onPress={onBackPress || goBack} icon="arrow-left"/>
          </Box>)}
        {children}
      </Row>
    </SafeAreaView>);
}
;
