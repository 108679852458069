import { MainHeader } from "@huum/components/molecules/header/main-header";
import { IconButton } from "@huum/components/molecules/icon-button";
import { useSaunaController } from "@huum/hooks/api/useSaunaController";
import { useSaunaStatus } from "@huum/hooks/api/useSaunaStatus";
import { navigate } from "@huum/services/NavigationService";
import { useStores } from "@huum/store";
import { ActiveToolbarItem } from "@huum/utils/sauna-toolbar";
import dayjs from "@huum/utils/date";
import { Box, Row, Stack } from "native-base";
import { useCallback, useState } from "react";
import SaunaConfigTabs from "../../components/sauna-config-tabs";
import SaunaController from "../../components/sauna-controller";
import TopButtonsRow from "../../components/top-buttons-row";
import { toNumber } from "lodash";
import { translate } from "@huum/i18n";
import { observer } from "mobx-react-lite";
import SaunaStatusCode from "@huum/utils/sauna-status-code";
const MainControllerScreen = observer(() => {
    const { sauna, modal, auth } = useStores();
    const { door, remoteSafetyState, ...restStatus } = useSaunaStatus();
    const { statusCode } = restStatus || {};
    const { startSaunaSession, stopSaunaSession, modifySaunaSession } = useSaunaController();
    const [controllerIsDisabled, setControllerIsDisabled] = useState(false);
    const [activeToolbarItem, setActiveToolbarItem] = useState(ActiveToolbarItem.HEATING);
    const [timerValue, setTimerValue] = useState({ start: null, end: null });
    const [controllerLayout, setControllerLayout] = useState();
    const saunaSessionInProgress = statusCode === SaunaStatusCode.SAUNA_ACTIVE ||
        statusCode === SaunaStatusCode.SAUNA_ACTIVE_BLOCKED;
    const handleToolbarItemChange = useCallback((item) => {
        setActiveToolbarItem(item);
    }, []);
    const handleStartSauna = useCallback((isImmediate) => () => {
        if (!door) {
            return modal.openModal("iconModal", {
                icon: "door-open",
                contentText: translate("text.door_is_open"),
            });
        }
        if (remoteSafetyState === "notSafe") {
            return modal.openModal("iconModal", {
                icon: "stopped",
                contentText: translate("error.sauna_not_safe"),
            });
        }
        startSaunaSession({
            ...(isImmediate ? {} : timerValue),
            targetTemperature: sauna.latestTargetTemp, //todo
        });
        if (isImmediate) {
            setTimerValue({ start: null, end: null });
        }
    }, [startSaunaSession, timerValue, door, remoteSafetyState]);
    const handleTimerValueChange = useCallback((values) => {
        setTimerValue({
            start: dayjs(values.start),
            end: dayjs(values.end),
        });
        if (saunaSessionInProgress) {
            modifySaunaSession(restStatus, {
                start: values.start,
                end: values.end,
            });
        }
        else {
            handleStartSauna(false)();
        }
    }, [saunaSessionInProgress, modifySaunaSession, restStatus, handleStartSauna]);
    const handleStopSauna = useCallback(() => {
        stopSaunaSession();
    }, [stopSaunaSession]);
    const handleSaunaTemperatureChange = useCallback((targetTemperature) => {
        if (saunaSessionInProgress) {
            modifySaunaSession(restStatus, {
                targetTemperature,
            });
        }
        sauna.setLatestTargetTemp(targetTemperature);
    }, [modifySaunaSession, restStatus]);
    const handleSaunaHumidityChange = useCallback((humidity) => {
        modifySaunaSession(restStatus, {
            humidity,
        });
    }, [modifySaunaSession, restStatus]);
    const handleSaunaLightChange = useCallback((enabled) => {
        modifySaunaSession(restStatus, {
            light: enabled ? 1 : 0,
            type: "light",
        });
    }, [modifySaunaSession, restStatus]);
    const hideBookingButton = toNumber(sauna.saunaId) < 110000 ||
        statusCode === SaunaStatusCode.SAUNA_OFFLINE ||
        statusCode === SaunaStatusCode.SAUNA_EMERGENCY_STOP;
    return (<Stack flex={1}>
      <MainHeader withBackNavigation={false}>
        <Row space={4} alignItems="center">
          <IconButton onPress={() => navigate("bookings")} icon={"calendar"} visible={!hideBookingButton} size={8}/>
          <IconButton onPress={() => navigate("menu")} icon={"menu"} testID={"main-menu-btn"} accessibilityLabel={"main-menu-btn"} size={8}/>
        </Row>
      </MainHeader>
      <Box py={4} mb={8}>
        <TopButtonsRow statusCode={statusCode} door={door}/>
      </Box>
      <Stack space={16} alignItems="center" justifyContent="center" key={`controller-${auth.language}`} flexDirection={{
            base: "column",
            md: "row",
        }}>
        <Stack onLayout={({ nativeEvent: { layout } }) => setControllerLayout(layout)} mr={{
            base: 0,
            md: 16,
        }}>
          <SaunaController saunaId={sauna.saunaId} layout={controllerLayout} key={`controller-${auth.language}`} onStartSauna={handleStartSauna(true)} onStopSauna={handleStopSauna} onSaunaTemperatureChange={handleSaunaTemperatureChange} isHeating={saunaSessionInProgress} saunaStatus={restStatus}/>
        </Stack>
        <SaunaConfigTabs saunaIsHeating={saunaSessionInProgress} saunaStatus={restStatus} activeToolbarItem={activeToolbarItem} onChangeItem={handleToolbarItemChange} onHumidityChange={handleSaunaHumidityChange} onLightChange={handleSaunaLightChange} onTimerChange={handleTimerValueChange}/>
      </Stack>
    </Stack>);
});
export default MainControllerScreen;
