import React, { useState, useRef, useEffect } from 'react';
import * as Animatable from 'react-native-animatable';
import { getLoaderStyles } from './styles';
import { Box } from 'native-base';
import { Text } from '@huum/components/atoms/text';
import { translate } from '@huum/i18n';
import { useThemedStyles } from '@huum/hooks/useThemedStyles';
const AnimatedLoader = () => {
    const dropIconRef = useRef(null);
    const [isDropVisible, setIsDropVisible] = useState(true);
    const styles = useThemedStyles(getLoaderStyles);
    useEffect(() => {
        setTimeout(() => {
            if (isDropVisible) {
                dropIconRef.current?.fadeOutDown(200);
            }
            else {
                dropIconRef.current?.fadeInDown(300);
            }
            setIsDropVisible(!isDropVisible);
        }, 1200);
    }, [isDropVisible]);
    return (<Box style={styles.container}>
      <Text size={20} fontWeight="700">
        {translate("text.loading")}
      </Text>
      <Box style={styles.animationContainer}>
        <Animatable.Image animation="fadeInDown" duration={300} ref={dropIconRef} style={styles.dropImage} source={require("@huum/assets/legacy/drop.png")}/>
      </Box>
    </Box>);
};
// mininumDuration in ms
const Loader = (props) => {
    const { isLoading, minimumDuration } = props;
    const [loading, setLoading] = useState(true);
    useEffect(() => {
        if (isLoading && minimumDuration !== undefined) {
            setLoading(true);
            setTimeout(() => {
                setLoading(false);
            }, minimumDuration);
        }
    }, [isLoading]);
    if (!isLoading && !loading)
        return null;
    return <AnimatedLoader />;
};
export default Loader;
