//@ts-nocheck
import React, { useCallback, useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import Modal from "react-native-modal";
import { useStores } from "../../../store";
import { MODAL_TYPES } from "./modal-types-map";
import { Box } from "native-base";
import { GestureHandlerRootView } from "react-native-gesture-handler";
import { isTablet } from "react-native-device-info";
const MODAL_ROOT_STYLE = { margin: 0, padding: 0, justifyContent: 'flex-end' }; // to remove default maring around modal
/**
 * Describe your component here
 */
export const ModalRoot = observer(function ModalRoot() {
    const { modal } = useStores();
    const { modalType, closeModal, modalProps, modalShouldClose, cleanModalState, modalStash, popModal } = modal;
    const [isModalVisible, setModalVisible] = useState(false);
    useEffect(() => {
        cleanModalState(true);
    }, []);
    useEffect(() => {
        setModalVisible(modalShouldClose ? false : !!modalType);
    }, [modalType, modalShouldClose]);
    const Component = useCallback((props) => {
        if (modalType) {
            return MODAL_TYPES()[modalType](props);
        }
        return null;
    }, [modalType]);
    const handleClose = useCallback(() => {
        if (modalStash?.length > 0 && !modalProps.clearStash) {
            popModal();
        }
        else if (!modalProps?.persistant) {
            closeModal();
        }
    }, [modalProps, closeModal]);
    return (<Modal animationIn="slideInUp" animationOut="slideOutDown" isVisible={!!isModalVisible} hideModalContentWhileAnimating useNativeDriver onBackButtonPress={handleClose} onBackdropPress={handleClose} onModalHide={cleanModalState} avoidKeyboard propagateSwipe>
      <GestureHandlerRootView style={{ flex: 1, justifyContent: "center" }}>
        <Box safeArea borderRadius={30} width={isTablet() ? "50%" : "100%"} alignSelf="center">
          <Component {...modalProps} closeModal={handleClose}/>
        </Box>
      </GestureHandlerRootView>
    </Modal>);
});
