import React, { useCallback, useState } from "react";
import { Box, Row } from 'native-base';
import { Icon } from "@huum/components/atoms/icon";
import { Text } from "@huum/components/atoms/text";
import { useStyles } from "../styles";
import SliderSelect from "@huum/components/molecules/slider-select";
const BrightnessController = (props) => {
    const { defaultValue, onChange } = props;
    const [brightness, seBrightness] = useState(defaultValue || 100);
    const styles = useStyles();
    const onSlideComplete = useCallback((value) => {
        onChange(value);
    }, [onChange]);
    return (<Row alignItems="center" justifyContent="space-between">
        <Box width={8} alignItems="center">
          <Icon tint="primaryDark" icon="lights"/>
        </Box>
        <Box flex={1} px={4}>
          <SliderSelect step={1} defaultValue={defaultValue} onProgress={seBrightness} onChange={onSlideComplete} showValue={false}/>
        </Box>
        <Box width={10}>
          <Text fontSize="sm">{`${brightness}%`}</Text>
        </Box>
      </Row>);
};
export default BrightnessController;
