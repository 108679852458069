import React, { useCallback } from "react";
import { Text } from "@huum/components/atoms/text";
import { Box, Row, Stack } from "native-base";
import { useStores } from "@huum/store";
import { Icon } from "@huum/components/atoms/icon";
import { IconButton } from "@huum/components/molecules/icon-button";
const IconModal = (props) => {
    const { contentText, icon, closeModal, infoText } = props;
    const { modal } = useStores();
    const handleInfoPress = useCallback(() => {
        modal.openModal('iconModal', { contentText: infoText, icon });
    }, [infoText]);
    return (<Box justifyContent="center" alignItems="center">
      <Stack minW={200} minH={200} p={12} borderRadius={16} space={4} justifyContent="center" alignItems="center" backgroundColor="primaryDark">
        <Row position="absolute" top={6} right={6} left={6} justifyContent="space-between">
          {infoText && <IconButton tint="white.100" size={12} icon="info" onPress={handleInfoPress}/> || <Box />}
          <IconButton icon="close" tint="white.100" size={8} onPress={closeModal}/>
        </Row>
        <Icon tint="white.100" icon={icon} size={28}/>
        <Text fontWeight="bold" color="white.100" textAlign="center">
          {contentText}
        </Text>
      </Stack>
    </Box>);
};
export default IconModal;
