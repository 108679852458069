import api from "@huum/services/api";
import { getGeneralApiProblem } from "@huum/services/api/api-problem";
export async function connectSauna(data) {
    const { saunaID, saunaCode, session } = data;
    const response = await api.huum.get("action/changesaunaid/", {
        saunaID,
        saunaCode,
        version: 3,
        session,
        multiSauna: true,
    });
    if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem)
            throw problem;
    }
    return response.data;
}
export async function fetchSaunaStatus(data) {
    const { session, saunaId } = data;
    const response = await api.huum.get("action/status", {
        version: 3,
        session,
        saunaId,
    });
    if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem)
            throw problem;
    }
    return response.data;
}
export async function saunaSessionRequest(data) {
    const { session, type, ...rest } = data;
    const response = await api.huum.post(`action/${type}`, {
        version: 3,
        session,
        ...rest,
    });
    if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem)
            throw problem;
    }
    return response.data;
}
export async function stopSaunaSessionRequest(data) {
    const { session, saunaId } = data;
    const response = await api.huum.get(`action/stop_sauna`, {
        version: 3,
        saunaId,
        session,
    });
    if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem)
            throw problem;
    }
    return response.data;
}
export async function updateSaunaHumidityRequest(data) {
    const { session, saunaId } = data;
    const response = await api.huum.get(`action/modify`, {
        version: 3,
        session,
        saunaId,
    });
    if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem)
            throw problem;
    }
    return response.data;
}
export async function emergencyBreakRequest(data) {
    const { session, saunaId } = data;
    const response = await api.huum.get(`action/break`, {
        version: 3,
        session,
        saunaId,
    });
    if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem)
            throw problem;
    }
    return response.data;
}
export async function updateControllerSoftwareRequest(data) {
    const { session, saunaId } = data;
    const response = await api.huum.post(`action/update`, {
        version: 3,
        session,
        saunaId
    });
    if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem)
            throw problem;
    }
    return response.data;
}
export async function fetchSaunaConfig(data) {
    const { saunaId, session } = data;
    const response = await api.huum.get("sauna/config", {
        version: 3,
        saunaId,
        session,
    });
    if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem)
            throw problem;
    }
    const configResponse = response.data;
    return configResponse?.[saunaId];
}
export async function modifySaunaConfig(data) {
    const response = await api.huum.post(`sauna/config`, {
        version: 3,
        ...data
    });
    if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem)
            throw problem;
    }
    return response.data;
}
export async function fetchSaunaName(data) {
    const response = await api.huum.get(`sauna/name`, {
        version: 3,
        ...data
    });
    if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem)
            throw problem;
    }
    return response.data;
}
export async function modifySaunaName(data) {
    const response = await api.huum.post(`sauna/name`, {
        version: 3,
        ...data
    });
    if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem)
            throw problem;
    }
    return response.data;
}
export async function removeUserSauna(data) {
    const response = await api.huum.get(`user/remove/userSauna`, {
        version: 3,
        ...data
    });
    if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem)
            throw problem;
    }
    return response.data;
}
