import Button from "@huum/components/atoms/button";
import { FormInput } from "@huum/components/atoms/form-input";
import { Text } from "@huum/components/atoms/text";
import { translate } from "@huum/i18n";
import { Row, ScrollView, Stack, useTheme } from "native-base";
import { useCallback, useEffect } from "react";
import { useSaunaConnect } from "@huum/hooks/api/useSaunaConnect";
import { Switch } from "@huum/components/molecules/switch";
import { useStores } from "@huum/store";
import InfoLabel from "@huum/components/molecules/info-label";
const ConnectSaunaView = (props) => {
    const { control, handleSubmit, setViewComponent } = props;
    const { sauna } = useStores();
    const { saunaConnect, isSuccess, showCodeInput, setSaunaIsPrivate } = useSaunaConnect();
    const { config } = useTheme();
    const handleConnect = useCallback((values) => {
        saunaConnect({ ...values });
    }, []);
    useEffect(() => {
        if (isSuccess) {
            setViewComponent('saunaActivated');
        }
    }, [isSuccess]);
    return (<ScrollView keyboardDismissMode="interactive" keyboardShouldPersistTaps="handled">
      <Stack mt={16} mb={6} space={4}>
        <Text textAlign="center" preset="h4">
          {translate("title.connect_with_sauna")}
        </Text>
        <Text mb={6} preset="subtitle2" textAlign="center">
          {translate("text.find_saunaid_and_code_main_module")}
        </Text>
        <FormInput placeholder={translate("text.sauna_id")} name="saunaID" control={control} keyboardType="numeric"/>
       {showCodeInput && (<FormInput placeholder={translate("label.sauna_code")} name="saunaCode" control={control} keyboardType="numeric"/>)}
       {showCodeInput && (<Row justifyContent="space-between">
          <InfoLabel label={translate("label.is_sauna_private")} infoText={translate('info.private_sauna', { supportEmail: config.helpEmail })}/>
          <Switch disabled={!sauna.isFirstUser} value={!!sauna.saunaIsPrivate} onToggle={setSaunaIsPrivate}/>
        </Row>)}
      </Stack>
      <Stack space={4}>
        <Button onPress={handleSubmit(handleConnect)} label={translate("button.confirm")}/>
        <Text preset="subtitle2" textAlign="center">
          {translate("text.connect_sauna_additional_text")}
        </Text>
      </Stack>
    </ScrollView>);
};
export default ConnectSaunaView;
