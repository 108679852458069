import { useCallback } from "react";
import { useMutation } from "@tanstack/react-query";
import { useStores } from "@huum/store";
import { get } from "lodash";
import { userTemperatureUnitChange } from "@huum/api/requests/profile";
export const useUserTemperatureUnitChange = () => {
    const { sauna, auth } = useStores();
    const session = get(auth, "sessionHash", null);
    const { mutate } = useMutation({
        mutationFn: userTemperatureUnitChange,
    });
    const changeTemperatureUnit = useCallback((unit) => {
        sauna.updateSaunaTemperatureUnit(unit);
        mutate({
            tempConf: unit === "celsius" ? "C" : "F",
            session,
        });
    }, []);
    return { changeTemperatureUnit, unit: sauna.temperatureUnit };
};
