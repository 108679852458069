import { DEFAULT_CONTROLLER_WIDTH, } from "./helpers";
import React, { useCallback } from "react";
import { Row, Stack, useTheme, Pressable } from "native-base";
import { Text } from "@huum/components/atoms/text";
import { Icon } from "@huum/components/atoms/icon";
import Svg from "react-native-svg";
import { translate } from "@huum/i18n";
import { navigate } from "@huum/services/NavigationService";
const PlaceholderController = (props) => {
    const { width: customWidth, temperature, targetTemperature, handleSaunaStartPress, topOffset = 0, } = props;
    const width = customWidth || DEFAULT_CONTROLLER_WIDTH;
    const innerCircleSize = width / 2 + (width < 200 ? 24 : 48);
    const { colors } = useTheme();
    const onPress = useCallback(() => {
        navigate("saunaConnect", { mode: "change" });
    }, []);
    const InnerCircleContent = useCallback(() => {
        return <Row alignItems="center" p={2}>
      <Icon icon="plus"/>
      <Text fontSize="xs" lineHeight="xs">
        {translate("button.add_new_device")}
      </Text>
    </Row>;
    }, []);
    return (<Stack alignItems="center" justifyContent="center" alignSelf="center" width={width} height={width}>
      <Svg height={width} width={width} viewBox={"0 0 294 294"}>
        {/* <ControllerArc width={width} /> */}
      </Svg>
      <Pressable onPress={onPress} position="absolute" height={`${innerCircleSize}px`} width={`${innerCircleSize}px`} borderRadius={innerCircleSize / 2} background={colors.white[100]} alignItems="center" justifyContent="center" shadow={3} _pressed={{
            shadow: 0,
        }}>
        <InnerCircleContent />
      </Pressable>
    </Stack>);
};
export default PlaceholderController;
