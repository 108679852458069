import Button from "@huum/components/atoms/button";
import { FormInput } from "@huum/components/atoms/form-input";
import { Text } from "@huum/components/atoms/text";
import { useResetPassword } from "@huum/hooks/api/useResetPassword";
import { translate } from "@huum/i18n";
import { useStores } from "@huum/store";
import { validateEmail } from "@huum/utils/validator";
import { Stack } from "native-base";
import { useCallback, useEffect } from "react";
const PasswordResetView = (props) => {
    const { control, handleSubmit, setViewComponent } = props;
    const { toast } = useStores();
    const { passwordReset, data, isError, isSuccess } = useResetPassword();
    useEffect(() => {
        if (isSuccess) {
            toast.showToast({
                message: `${translate("text.success")} ${translate("text.password_reset")}`,
                type: "success",
                duration: 6000,
            });
            setTimeout(() => {
                setViewComponent("login");
            }, 6000);
        }
    }, [isSuccess]);
    const handlePasswordReset = useCallback((value) => {
        const { email } = value?.reset;
        if (!validateEmail(email)) {
            toast.showToast({
                message: translate("error.username"),
                type: "error",
                duration: 3000,
            });
        }
        else if (isError) {
            toast.showToast({
                message: translate("error.error_login"),
                type: "error",
                duration: 3000,
            });
        }
        else {
            passwordReset(email);
        }
    }, [isError]);
    return (<>
      <Stack mt={20} mb={4} space={4} width={{
            sm: "100%",
            md: "420px"
        }} alignSelf={{
            sm: "flex-start",
            md: "center"
        }}>
        <FormInput placeholder={translate("label.email")} name="reset.email" control={control} keyboardType="email-address"/>
      </Stack>
      <Stack space={4}>
        <Text textAlign="center" preset="subtitle1">
          {translate("label.enter_email_associated")}
        </Text>
        <Button label={translate("button.reset_password")} onPress={handleSubmit(handlePasswordReset)}/>
      </Stack>
    </>);
};
export default PasswordResetView;
