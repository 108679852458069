import { DEFAULT_CONTROLLER_WIDTH, MAX_TEMPERATURE, CONTROLLER_COLORS, pickHexColor, } from "./helpers";
import React, { useCallback, useMemo } from "react";
import { Row, Stack } from "native-base";
import SaunaStatusCode from "@huum/utils/sauna-status-code";
import { Text } from "@huum/components/atoms/text";
import { getTemperatureUnit, getTemperatureValue, } from "@huum/utils/helpers/sauna-temperature-helpers";
import SaunaController from ".";
import { Icon } from "@huum/components/atoms/icon";
import { useStores } from "@huum/store";
const TemperatureController = (props) => {
    const { width: customWidth, temperature, targetTemperature, handleSaunaStartPress, handleSaunaStopPress, onTemperatureChange, topOffset = 0, statusCode, temperatureEditIsDisabled = false, } = props;
    const width = customWidth || DEFAULT_CONTROLLER_WIDTH;
    const { sauna } = useStores();
    const saunaIsHeating = useMemo(() => {
        // sauna is heating codes
        if (statusCode === SaunaStatusCode.SAUNA_ACTIVE ||
            statusCode === SaunaStatusCode.SAUNA_ACTIVE_BLOCKED)
            return true;
        return false;
    }, [statusCode]);
    const getTemperatureColor = (temperature) => {
        const temperaturePercentage = temperature / MAX_TEMPERATURE;
        return `rgb(${pickHexColor(CONTROLLER_COLORS.end, CONTROLLER_COLORS.start, temperaturePercentage).join(",")})`;
    };
    const heatingColor = saunaIsHeating ? "#CA4E69" : undefined;
    const handleControllerPress = () => {
        if (saunaIsHeating) {
            handleSaunaStopPress && handleSaunaStopPress();
        }
        else {
            handleSaunaStartPress && handleSaunaStartPress();
        }
    };
    const renderInnerCircleContent = useCallback((props) => {
        const { temperature: visibleTemperatureNumber } = props;
        const fontSize = width < 200 ? "3xl" : "6xl";
        const iconSize = width < 200 ? 12 : undefined;
        return (<Stack space={2} alignItems="center">
        <Row mt={2} alignItems="baseline">
          <Text fontSize={fontSize} lineHeight="xs" fontWeight="semibold" color={heatingColor}>
            {getTemperatureValue(visibleTemperatureNumber, sauna.temperatureUnit)}
          </Text>
          <Text 
        // mt={4}
        color={heatingColor}>
            {getTemperatureUnit(sauna.temperatureUnit)}
          </Text>
        </Row>
        <Icon icon="heating2" tint={heatingColor} size={iconSize}/>
      </Stack>);
    }, [saunaIsHeating, sauna.temperatureUnit]);
    return (<SaunaController width={width} topOffset={topOffset} InnerCircleContent={renderInnerCircleContent} temperatureColor={getTemperatureColor} onPress={handleControllerPress} heatingTemperature={temperature} isHeating={saunaIsHeating} temperatureEditIsDisabled={temperatureEditIsDisabled} onTemperatureChange={onTemperatureChange} targetTemperature={targetTemperature}/>);
};
export default TemperatureController;
