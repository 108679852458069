import React, { useCallback, useMemo } from "react";
import dayjs from "@huum/utils/date";
import { map, toUpper } from "lodash";
import { Text } from "@huum/components/atoms/text";
import { Pressable, Row } from "native-base";
// dow can be changed, to change the first day of the week
// by default it is 0 (Sunday)
// NB! when changing dow, CalendarDatesList needs to be adjusted
dayjs.locale(dayjs.locale(), { weekStart: 1 });
const WeekDaysSelector = (props) => {
    const { selectedDays, disabled, onPress } = props;
    const weekDays = dayjs.weekdaysMin(true);
    const checkIsDaySelected = useCallback((indexOfDay) => selectedDays?.[indexOfDay] === 1, [selectedDays]);
    const handleOnItemPress = useCallback((index) => {
        onPress(index);
    }, [onPress]);
    const SelectItem = useCallback((item) => {
        const { item: day, index } = item;
        const isSelected = checkIsDaySelected(index);
        return (<Pressable onPress={() => !disabled && handleOnItemPress(index)} key={day} bg={isSelected ? "green.200" : "transparent"} width={8} alignItems="center" borderRadius={8}>
          <Text fontSize="md">
            {toUpper(day) || ""}
          </Text>
        </Pressable>);
    }, [disabled, handleOnItemPress, checkIsDaySelected]);
    const weekDaysRow = useMemo(() => map(weekDays, (day, index) => <SelectItem index={index} item={day}/>), []);
    return (<Row space={2} alignItems="center" justifyContent="space-between">
      {weekDaysRow}
    </Row>);
};
export default WeekDaysSelector;
