import React from 'react';
import Svg, { G, Path, Defs, Filter, FeFlood, FeBlend, FeColorMatrix, FeOffset, FeGaussianBlur, FeComposite } from 'react-native-svg';
const PlayEllipse = ({ width = 180, height = 180, strokeColor = '#FBFBFB', strokeWidth = 8, }) => {
    return (<Svg width={width} height={height} viewBox="0 0 280 280" fill="none">
      <G filter="url(#filter0_i_5498_690)">
        <Path d="M236.167 236.167C210.662 261.671 176.069 276 140 276C103.931 276 69.3384 261.671 43.8335 236.167C18.3285 210.662 4 176.069 4 140C4 103.931 18.3285 69.3384 43.8335 43.8335C69.3384 18.3285 103.931 4 140 4C176.069 4 210.662 18.3285 236.167 43.8335C261.671 69.3384 276 103.931 276 140C276 176.069 261.671 210.662 236.167 236.167L236.167 236.167Z" stroke={strokeColor} strokeWidth={strokeWidth} strokeLinecap="round"/>
      </G>
      <Defs>
        <Filter id="filter0_i_5498_690" x="0" y="0" width="282" height="282" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
          <FeFlood floodOpacity="0" result="BackgroundImageFix"/>
          <FeBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
          <FeColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
          <FeOffset dx="2" dy="2"/>
          <FeGaussianBlur stdDeviation="2.5"/>
          <FeComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
          <FeColorMatrix type="matrix" values="0 0 0 0 0.0196078 0 0 0 0 0.356863 0 0 0 0 0.439216 0 0 0 0.25 0"/>
          <FeBlend mode="normal" in2="shape" result="effect1_innerShadow_5498_690"/>
        </Filter>
      </Defs>
    </Svg>);
};
export default PlayEllipse;
