import { palette } from '@huum/theme/helpers';
import { Dimensions, StyleSheet } from "react-native";
const { width } = Dimensions.get("screen");
const HEIGHT = 44;
const SMALLER_HEIGHT = 38;
const DAY_WIDTH = width / 7 - 4;
export const getCalendarStyles = (theme) => {
    const { colors, space, shadows } = theme;
    return StyleSheet.create({
        weekCalendarWeek: {
            height: 64,
            justifyContent: "space-between",
            alignItems: "center",
            flexDirection: "row",
        },
        monthCalendarWeek: {
            justifyContent: "space-between",
            flexDirection: "row",
        },
        weekCalendarDay: {
            height: DAY_WIDTH,
            width: DAY_WIDTH,
            justifyContent: "center",
            alignItems: "center",
        },
        monthCalendarDay: {
            height: SMALLER_HEIGHT,
            width: SMALLER_HEIGHT,
            marginHorizontal: 4,
            justifyContent: "center",
            alignItems: "center",
        },
        weekCalendarDaySelected: {
            height: DAY_WIDTH,
            width: DAY_WIDTH,
            justifyContent: "center",
            alignItems: "center",
            borderRadius: DAY_WIDTH / 2,
            backgroundColor: colors.white[100],
            ...shadows[4],
        },
        monthCalendarDaySelected: {
            height: SMALLER_HEIGHT,
            width: SMALLER_HEIGHT,
            lineHeight: 18,
            marginHorizontal: 4,
            justifyContent: "center",
            alignItems: "center",
            borderRadius: SMALLER_HEIGHT / 2,
            backgroundColor: colors.white[100],
            ...shadows[6],
        },
        selectedText: {
            fontSize: 10,
            lineHeight: 16,
            color: palette.dimmedText,
        },
        activityIndicator: {
            height: 5,
            width: 5,
            borderRadius: 3,
            position: "absolute",
            bottom: 10,
            backgroundColor: colors.primary[500],
        },
        disabledDay: {
            opacity: 0.5,
        },
        monthText: {
            fontSize: 10,
            lineHeight: 16,
            color: palette.dimmedText,
        },
        calendarDayText: {
            lineHeight: 18,
            fontWeight: "700",
        },
        sessionNumber: {
            fontSize: 8,
            lineHeight: 8,
            color: palette.invalid,
            position: "absolute",
            bottom: 2
        },
    });
};
