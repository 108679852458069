import { useStores } from "@huum/store";
import { useQuery } from "@tanstack/react-query";
import { get } from "lodash";
import { useMemo } from "react";
import { getBookingsList } from "@huum/api/requests/bookings";
import { getUnAvailableHours, selectIntoBookingListItems } from "@huum/utils/selectors/booking";
export const useBookingList = (date, maxHeatingTime) => {
    const { auth, sauna } = useStores();
    const session = get(auth, "sessionHash", null);
    const saunaId = get(sauna, "saunaId", "");
    const { data, isError, isSuccess, isLoading } = useQuery({
        queryKey: ['bookingsList', date],
        queryFn: () => getBookingsList({ session, date, saunaId }),
        enabled: !!date && !!saunaId && !!session,
    });
    const bookingList = useMemo(() => selectIntoBookingListItems(data, auth.accountId) || [], [data, auth.accountId]);
    const unavailableHours = useMemo(() => getUnAvailableHours(bookingList, maxHeatingTime || 6), [bookingList]);
    return { data: bookingList, unavailableHours, isError, isSuccess, isLoading };
};
