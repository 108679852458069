import React, { useCallback, useEffect, useMemo, useState } from "react";
import { getStatisticsStyles } from "./styles";
import { MainHeader } from "@huum/components/molecules/header/main-header";
import { translate } from "@huum/i18n";
import { useStores } from "@huum/store";
import { useStatistics } from "@huum/hooks/api/useStatistics";
import { getSessionsCount, selectStatisticsData, } from "@huum/utils/helpers/statistics-selectors";
import StatisticsLineGraph from "@huum/components/organisms/statistics-line-graph";
import StatisticsBarGraph from "@huum/components/organisms/statistics-bar-graph";
import StatisticsCalendarGraph from "@huum/components/organisms/statistics-calendar-graph";
import { Tabs } from "@huum/components/molecules/tabs";
import { useThemedStyles } from "@huum/hooks/useThemedStyles";
import { Box, ScrollView, Stack } from "native-base";
import { get, map } from "lodash";
import dayjs from "@huum/utils/date";
import { isTablet } from "react-native-device-info";
import { formatDate } from "@huum/utils/date";
import { AveragesSection } from "./components/AveragesSection";
import { DateSelector } from "./components/DateSelector";
import Loader from "@huum/components/organisms/loader";
var TabKey;
(function (TabKey) {
    TabKey["DAY"] = "day";
    TabKey["WEEK"] = "week";
    TabKey["MONTH"] = "month";
})(TabKey || (TabKey = {}));
const TABS = [TabKey.DAY, TabKey.WEEK, TabKey.MONTH];
const StatisticsView = () => {
    const [activeTab, setActiveTab] = useState(TabKey.DAY);
    const [selectedDate, setDate] = useState(formatDate(dayjs(), "YYYY-MM-DD"));
    const { sauna } = useStores();
    const { getStatistics, statisticsResults, isSuccess, isLoading } = useStatistics();
    useEffect(() => {
        getStatistics({ month: selectedDate });
    }, []);
    const temperatureUnit = get(sauna, "temperatureUnit", "");
    const styles = useThemedStyles(getStatisticsStyles);
    const goToDayView = useCallback((value) => {
        setDate(value);
        setActiveTab(TabKey.DAY);
    }, []);
    const StatsComponent = useCallback((props) => {
        const { tabKey, date, data } = props;
        if (tabKey === TabKey.DAY)
            return (<StatisticsLineGraph temperatureUnit={temperatureUnit} statistics={data} selectedDate={date}/>);
        else if (tabKey === TabKey.WEEK)
            return (<StatisticsBarGraph selectedDate={date} statistics={data} temperatureUnit={temperatureUnit}/>);
        else
            return (<StatisticsCalendarGraph goToDayView={goToDayView} selectedDate={date} statistics={data}/>);
    }, [temperatureUnit]);
    const tabsList = useMemo(() => {
        return map(TABS, (tab) => ({
            title: translate(`button.${tab}`),
            onPress: () => setActiveTab(tab),
            isFocused: activeTab === tab,
        }));
    }, [activeTab]);
    const averagesSectionData = useMemo(() => selectStatisticsData(statisticsResults, dayjs(selectedDate), activeTab, temperatureUnit), [statisticsResults, activeTab]);
    const handleDateChange = useCallback((increment) => {
        const dateValue = formatDate(dayjs(selectedDate).add(increment, activeTab).startOf(activeTab), "YYYY-MM-DD");
        setDate(dateValue);
        getStatistics({ month: dateValue });
    }, [selectedDate, activeTab]);
    const displayDates = useMemo(() => {
        if (activeTab === TabKey.WEEK) {
            const firstDayOfWeek = dayjs(selectedDate).startOf("week");
            const lastDayOfWeek = dayjs(selectedDate).endOf("week");
            const firstDayMonth = formatDate(dayjs(firstDayOfWeek), "MMMM");
            const lastDayMonth = formatDate(dayjs(lastDayOfWeek), "MMMM");
            const firstDayDate = dayjs(firstDayOfWeek).date();
            const lastDayDate = dayjs(lastDayOfWeek).date();
            if (dayjs(firstDayOfWeek).month() === dayjs(lastDayOfWeek).month()) {
                return `${firstDayMonth} ${firstDayDate} – ${lastDayDate}`;
            }
            return `${firstDayDate} ${firstDayMonth} – ${lastDayDate} ${lastDayMonth}`;
        }
        else if (activeTab === TabKey.MONTH) {
            return formatDate(dayjs(selectedDate), "MMMM, YYYY");
        }
        return formatDate(dayjs(selectedDate), "D MMMM");
    }, [selectedDate, activeTab]);
    const displaySessionCount = useMemo(() => {
        const data = getSessionsCount(statisticsResults, selectedDate);
        if (activeTab === TabKey.DAY)
            return data?.[TabKey.DAY] || 0;
        else if (activeTab === TabKey.WEEK)
            return data?.[TabKey.WEEK] || 0;
        else if (activeTab === TabKey.MONTH)
            return data?.[TabKey.MONTH] || 0;
    }, [activeTab, statisticsResults, selectedDate]);
    return (<Stack flex={1} bg="white.100">
      <MainHeader withBackNavigation title={translate("button.statistics")}/>
      <ScrollView showsVerticalScrollIndicator={false} contentContainerStyle={styles.scrollContainer} bounces={false}>
        <StatsComponent tabKey={activeTab} date={selectedDate} data={statisticsResults}/>
        <Box mx={8} mt={10} pb={8} alignSelf="center" maxWidth={isTablet() ? "65%" : undefined}>
          <AveragesSection data={averagesSectionData}/>
          <Box mt={3}/>
          <DateSelector onDateChange={handleDateChange} label={displayDates} sessionsCount={displaySessionCount}/>
        </Box>
        <Loader isLoading={isLoading} minimumDuration={500}/>
      </ScrollView>
      <Tabs tabs={tabsList}/>
    </Stack>);
};
export default StatisticsView;
