import React, { useState, useEffect, useRef } from 'react';
import { size } from 'lodash';
import * as Animatable from 'react-native-animatable';
import styles from './AnimatedInfoSection.style';
import { Text } from '@huum/components/atoms/text';
const AnimatedInfoSection = ({ contentSections }) => {
    const [activeIndex, setActiveIndex] = useState(0);
    const animatedView = useRef(null);
    let animatedInterval = null;
    const animateViewComponent = () => {
        const contentMaxIndex = size(contentSections) - 1;
        const nextIndex = activeIndex === contentMaxIndex ? 0 : activeIndex + 1;
        if (!animatedView.current)
            return;
        animatedView.current.fadeOut(700).then(() => {
            setActiveIndex(nextIndex);
            animatedView.current.fadeIn(700);
        });
    };
    useEffect(() => {
        if (size(contentSections) > 1) {
            animatedInterval = setInterval(animateViewComponent, 10000);
        }
        return () => {
            if (animatedInterval) {
                clearInterval(animatedInterval);
            }
        };
    }, [contentSections]);
    const visibleContent = contentSections[activeIndex || 0];
    if (size(contentSections) === 0)
        return null;
    return (<Animatable.View animation="fadeIn" ref={animatedView} style={styles.infoContainer} duration={300}>
      <Text fontSize={12} lineHeight={16}>
        {visibleContent?.label}
      </Text>
      <Text fontSize={18} lineHeight={22} fontWeight="bold">
        {visibleContent?.value}
      </Text>
    </Animatable.View>);
};
export default AnimatedInfoSection;
