import { MainHeader } from "@huum/components/molecules/header/main-header";
import { translate } from "@huum/i18n";
import { useStores } from "@huum/store";
import { Row, Stack } from "native-base";
import Playlist from "./components/playlist";
import { find, throttle, times } from "lodash";
import { useCallback, useState } from "react";
import Player from "./components/player";
import { useLedController } from "../led-controller/hooks/useLedController";
const AudioController = () => {
    const { iot } = useStores();
    const [isPlaying, setIsPlaying] = useState(false);
    const { setAudioTrack, setAudioVolume, deviceInfo, activeSongId, volume } = useLedController();
    const songs = times(deviceInfo?.audio_track_max || 0, (index) => ({
        id: index + 1,
        order: index + 1,
        title: `Song ${index + 1}`,
    }));
    const currentSong = songs.find((s) => s.id === activeSongId) || { order: 0, title: "", id: 0 };
    // Extract track number from song ID (e.g., "song-1" -> 1)
    const getTrackNumber = (songId) => {
        if (!songId)
            return 0;
        return songId;
    };
    const handleSongPress = (songId) => {
        if (songId === activeSongId) {
            // Toggle play/pause if the same song is pressed
            setIsPlaying(!isPlaying);
            setAudioTrack(isPlaying ? 0 : getTrackNumber(songId)); // Set track to 0 when pausing
        }
        else {
            // Select new song and start playing
            setIsPlaying(true);
            setAudioTrack(getTrackNumber(songId));
        }
    };
    const handlePlayPause = () => {
        setIsPlaying((prev) => !prev);
        // If playing, set the track number; if pausing, set to 0
        if (activeSongId) {
            setAudioTrack((cur) => getTrackNumber(cur));
        }
    };
    const handleNext = () => {
        const nextSong = find(songs, (s) => s.order === (currentSong?.order || 0) + 1);
        if (nextSong) {
            setIsPlaying(true);
            setAudioTrack(getTrackNumber(nextSong.id));
        }
    };
    const handlePrevious = () => {
        const previousSong = find(songs, (s) => s.order === (currentSong?.order || 0) - 1);
        if (previousSong) {
            setIsPlaying(true);
            setAudioTrack(getTrackNumber(previousSong.id));
        }
    };
    const handleAudioVolumeChange = useCallback(throttle((volume) => {
        setAudioVolume(volume);
    }, 200), [setAudioVolume]);
    return (<Stack flex={1}>
        <MainHeader withBackNavigation title={translate("label.audio_controller")} info={iot.connectedDeviceName || undefined}/>
        <Row flex={1} flexDirection={{
            sm: 'column',
            md: 'row',
        }} justifyContent={{ sm: 'space-between' }} overflowY="auto">
          <Playlist songs={songs} onSongPress={handleSongPress} activeSongId={activeSongId}/>
          <Player isPlaying={isPlaying} currentSong={currentSong} onPlayPause={handlePlayPause} onNext={handleNext} onPrevious={handlePrevious} volume={volume} onVolumeChange={handleAudioVolumeChange} onClose={() => {
            setIsPlaying(false);
            setAudioTrack(0);
        }}/>
        </Row>
    </Stack>);
};
export default AudioController;
