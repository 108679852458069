import React, { useCallback, useEffect, useMemo, useState } from "react";
import { i18n, translate } from "@huum/i18n";
import { MainHeader } from "@huum/components/molecules/header/main-header";
import { Stack } from "native-base";
import { useForm } from "react-hook-form";
import { useStores } from "@huum/store";
import { get, map, orderBy } from "lodash";
import { FormInput } from "@huum/components/atoms/form-input";
import { Screen } from "@huum/components/organisms/screen";
import { usePaymentFlow } from "@huum/hooks/api/usePaymentFlow";
import Button from "@huum/components/atoms/button";
import { palette } from "@huum/theme/helpers";
import ScrollPicker from "@huum/components/molecules/scroll-picker";
import { navigate } from "@huum/services/NavigationService";
const PaymentSettings = () => {
    const { auth } = useStores();
    const { control, handleSubmit, setValue, reset } = useForm();
    const { customerInfo } = usePaymentFlow("info");
    const userCountry = `${get(customerInfo, "countryCode", "")}`;
    const [country, setCountry] = useState(userCountry);
    useEffect(() => {
        if (customerInfo) {
            reset(customerInfo);
        }
    }, [customerInfo]);
    const getFormatedCountries = useMemo(() => {
        const countries = require("./countries-list/countries.json");
        return orderBy(map(countries, (value) => ({
            value: value.code,
            label: get(value, `name.${i18n.locale}`)
        })), 'label');
    }, []);
    const dropdownPlaceholder = useMemo(() => ({
        label: translate("label.country"),
        value: null,
        color: palette.primaryDark,
    }), []);
    const onCountrySelect = useCallback((selectedCountry) => {
        setCountry(selectedCountry);
        setValue("countryCode", selectedCountry);
    }, [setValue]);
    const handleBillingDetails = useCallback((values) => {
        navigate("billingForm", { customer: {
                ...(customerInfo || {}),
                ...values,
            } });
    }, [customerInfo]);
    return (<>
      <MainHeader withBackNavigation title={translate("button.payment")}/>
      <Screen>
        <Stack space={8} py={8}>
          <FormInput name="firstName" placeholder={`${translate("label.given_name")}*`} defaultValue={get(customerInfo, "firstName", "")} autoCapitalize="words" control={control}/>
          <FormInput name="lastName" placeholder={`${translate("label.family_name")}*`} defaultValue={get(customerInfo, "lastName", "")} autoCapitalize="words" control={control}/>
          <FormInput name="email" placeholder={`${translate("label.email")}*`} defaultValue={`${get(customerInfo, "email", "")}`} autoCapitalize="none" keyboardType="email-address" control={control}/>
          <FormInput name="city" placeholder={`${translate("label.city")}*`} defaultValue={get(customerInfo, "city", "")} autoCapitalize="words" control={control}/>
          <FormInput name="address" placeholder={`${translate("label.street_address")}*`} defaultValue={`${get(customerInfo, "address", "")}`} autoCapitalize="words" control={control}/>
          <FormInput name="extendedAddress" placeholder={`${translate("label.apartment")}`} defaultValue={`${get(customerInfo, "extendedAddress") || ""}`} autoCapitalize="words" control={control}/>
          <FormInput name="postalCode" placeholder={`${translate("label.zip_code")}*`} defaultValue={`${get(customerInfo, "postalCode", "")}`} autoCapitalize="words" control={control}/>
          <ScrollPicker name="countryCode" items={getFormatedCountries} placeholder={dropdownPlaceholder} onValueChange={onCountrySelect} selectedValue={country || userCountry}/>
          <Button label={translate("button.confirm")} onPress={handleSubmit(handleBillingDetails)}/>
        </Stack>
      </Screen>
    </>);
};
export default PaymentSettings;
