import React, { useCallback, useMemo, useState } from "react";
import { Stack, Row, useTheme, Center, Box, Image } from "native-base";
import EffectSpeedController from "./components/effectSpeed";
import BrightnessController from "./components/brightness";
import EffectsSelect from "./components/effects";
import { ColorPicker, fromHsv } from "react-native-color-picker";
import { COLOR_WHEEL_SIZE, useStyles } from "./styles";
import { Pressable } from "react-native";
import { WHITE_COLOR, useLedController, } from "@huum/features/led-controller/hooks/useLedController";
import { Text } from "@huum/components/atoms/text";
import { Icon } from "@huum/components/atoms/icon";
import { IconButton } from "@huum/components/molecules/icon-button";
import { translate } from "@huum/i18n";
import { observer } from "mobx-react-lite";
import DevicesList from "./components/devicesList";
import DeviceInactiveView from "./components/deviceInactiveView";
import { LedChannelSelect } from "./components/ledChannelSelect";
import { useStores } from "@huum/store";
import { MainHeader } from "@huum/components/molecules/header/main-header";
import { navigate } from "@huum/services/NavigationService";
import { useNavigation, useRoute, } from "@react-navigation/native";
import { debounce, throttle } from "lodash";
import LedBulbIcon from "@huum/assets/icons/LedBulbIcon";
const ColorPickerComponent = (props) => {
    const { visible, onChange } = props;
    const styles = useStyles();
    const debouncedOnChange = useMemo(() => {
        const debouncedFn = debounce((color) => {
            onChange(color);
        }, 300);
        const throttledFn = throttle((color) => {
            onChange(color);
            debouncedFn(color);
        }, 200, { leading: true, trailing: false });
        return (color) => {
            throttledFn(color);
            debouncedFn(color);
        };
    }, [onChange]);
    if (!visible)
        return null;
    return (<ColorPicker style={styles.colorPicker} hideSliders={true} onColorChange={debouncedOnChange}/>);
};
const LedController = observer(() => {
    const { params } = useRoute();
    const { setParams } = useNavigation();
    const { iot } = useStores();
    const [showColorPicker, setShowColorPicker] = useState(false);
    const [showEffects, setShowEffects] = useState(false);
    const { colors } = useTheme();
    const styles = useStyles();
    const { selectedColor, connectionIsActive, selectedEffect, activeBrightness, activeSpeed, setSpeed, setEffect, setLedColor, setWhiteLed, setBrightness, reconnectToLastDevice, } = useLedController();
    const showDevicesList = !iot.connectedDeviceId;
    const handleEffectsToggle = (show) => () => {
        setShowEffects(show);
    };
    const handleEffectSpeedChange = (value) => {
        setSpeed(value);
    };
    const handleShowColorPicker = () => {
        setShowColorPicker(true);
    };
    const handleHideColorPicker = () => {
        setShowColorPicker(false);
        setWhiteLed();
    };
    const handleBrightnessChange = (brightness) => {
        setBrightness(brightness);
    };
    const handleSelectEffect = (effect) => {
        setEffect(effect.name, effect.slot, effect.data);
        setLedColor({
            linearGradient: {
                colors: effect.gradient,
                start: [0, 0],
                end: [1, 0],
                locations: [0, 0.6],
            },
        }, true);
        setShowColorPicker(false);
    };
    const handleColorChange = useCallback((color) => {
        const hex = fromHsv(color);
        setLedColor(hex);
    }, [setLedColor]);
    if (showDevicesList) {
        return (<>
        <MainHeader withBackNavigation title={translate("label.led_controller")} info={translate("button.scan_devices")}/>
        <Stack flex={1} py={4}>
          <DevicesList onConnectDevice={() => setParams({ showList: false })}/>
        </Stack>
      </>);
    }
    if (!connectionIsActive) {
        return (<Stack flex={1} space={4} safeArea pb={4}>
        <MainHeader withBackNavigation title={translate("label.led_controller")} info={iot.connectedDeviceName || undefined}/>
        <DeviceInactiveView onReconnect={reconnectToLastDevice}/>;
      </Stack>);
    }
    return (<Stack flex={1} space={4} safeArea pb={4}>
      <MainHeader withBackNavigation title={translate("label.led_controller")} info={iot.connectedDeviceName || undefined}/>
      <Row alignItems="center" justifyContent="space-between" pr={4}>
        <LedChannelSelect visible={iot.useMutlipleChannels} activeChannel={iot.activeChannel} onChannelChange={iot.setActiveChannel}/>
        <Row space={4}>
          <IconButton icon="audio-round" onPress={() => navigate("audioController")}/>
          <IconButton icon="settings" onPress={() => navigate("ledControllerSettings")}/>
        </Row>
      </Row>
      <Stack flex={1}>
        <Center p={4} minH={COLOR_WHEEL_SIZE + 16}>
          <ColorPickerComponent onChange={handleColorChange} visible={showColorPicker}/>
          <Center style={styles.colorPickerImageBox} bg={selectedColor}>
            <LedBulbIcon strokeColor={selectedColor === WHITE_COLOR
            ? colors.primaryDark
            : colors.white[100]} style={styles.colorPickerImage}/>
          </Center>
        </Center>
      </Stack>
      <Row p={4} width="full" justifyContent="space-between" alignItems="center">
        <Pressable onPress={handleShowColorPicker}>
          <Image alt="wheel" size={12} source={require("@huum/assets/legacy/color_wheel.png")} shadow={2}/>
        </Pressable>
        <Pressable onPress={handleEffectsToggle(true)}>
          <Row alignItems="center" space={2}>
            <Text>{translate("button.show_effects")}</Text>
            <Icon icon="up" size={8}/>
          </Row>
        </Pressable>
        <Pressable onPress={handleHideColorPicker}>
          <Box size={12} borderRadius={100} bgColor="white.100" shadow={2}/>
        </Pressable>
      </Row>
      {showEffects && (<Stack space={4} bg="white.100" p={4} pb={8} left={0} right={0} bottom={-4} position="absolute" borderTopLeftRadius={16} borderTopRightRadius={16}>
          <Row justifyContent="space-between" alignItems="center" mb={4}>
            <Box />
            <Text textAlign="center" bold fontSize="lg">
              {translate("title.effects")}
            </Text>
            <IconButton visible icon="close" tint="primaryDark" size={8} onPress={handleEffectsToggle(false)}/>
          </Row>
          <EffectsSelect selected={selectedEffect} onSelect={handleSelectEffect}/>
          <EffectSpeedController onChange={handleEffectSpeedChange} defaultValue={activeSpeed}/>
          <BrightnessController onChange={handleBrightnessChange} defaultValue={activeBrightness}/>
        </Stack>)}
    </Stack>);
});
export default LedController;
