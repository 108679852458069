import Button from "@huum/components/atoms/button";
import { Text } from "@huum/components/atoms/text";
import { translate } from "@huum/i18n";
import { Center, Image, Stack, useTheme } from "native-base";
import { useStores } from "@huum/store";
const WifiRemoteControlSuccessView = (props) => {
    const { sauna } = useStores();
    const { colors } = useTheme();
    const { onContinue, label } = props;
    return (<Stack width={{
            base: "100%",
            md: "420px",
        }}>
      <Center>
        <Center my={20}>
          <Text preset="h4">
            {translate('title.thank_you_for_choosing_huum')}
          </Text>
            <Text preset="caption">
              {`${translate('text.id')} ${sauna.saunaId}`}
            </Text>

            <Center mt={8}>
              <Image height={200} resizeMode="contain" tintColor={colors.primaryLight} source={require('@huum/assets/legacy/drop.png')}/>
              <Image position="absolute" bottom={8} height={100} resizeMode="contain" source={require('@huum/assets/legacy/remote.png')}/>
            </Center>
          </Center>
      </Center>

      <Stack space={4}>
        <Button onPress={onContinue} label={label || translate('button.start')}/>
      </Stack>
    </Stack>);
};
export default WifiRemoteControlSuccessView;
