import { Button as BaseButton } from "native-base";
import React from "react";
import { Icon } from "../icon";
import { isTablet } from "react-native-device-info";
const PressEvents = {
    in: "in",
    out: "out",
};
const Button = (props) => {
    const { label, icon, iconSize = 10, visible = true, iconColor = "white.100", ...rest } = props;
    if (!visible)
        return null;
    return (<BaseButton startIcon={!!icon ? (<Icon size={iconSize} tint={iconColor} icon={icon}/>) : undefined} _text={{ fontSize: "md", px: 2 }} alignSelf={isTablet() ? "center" : undefined} {...rest}>
      {label}
    </BaseButton>);
};
export default Button;
