import { Text } from "@huum/components/atoms/text";
import { palette } from "@huum/theme/helpers";
import { map } from "lodash";
import { Box } from "native-base";
const AverageItem = (item) => {
    const { measure, value, unit } = item;
    return (<Box width="50%" alignItems="center" justifyContent="flex-start" paddingBottom={8}>
      <Box flexDir={"row"} alignItems={"center"}>
        <Text preset="subtitle1" fontWeight="700">
          {value}
        </Text>
      </Box>
      <Text color={palette.dimmedText} fontSize={14} lineHeight={20} marginTop={1} textAlign="center">
        {measure}
      </Text>
    </Box>);
};
export const AveragesSection = (props) => {
    const { data } = props;
    return (<Box flexDirection="row" flexWrap="wrap">
      {map(data, (item) => (<AverageItem {...item}/>))}
    </Box>);
};
