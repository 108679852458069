import { useStores } from "@huum/store";
import { useQuery } from "@tanstack/react-query";
import { get } from "lodash";
import { fetchSaunaStatus } from "@huum/api/requests/sauna";
export const useSaunasList = () => {
    const { auth } = useStores();
    const session = get(auth, "sessionHash", null);
    const { data, isError, isSuccess, refetch } = useQuery({
        queryKey: [`saunasList`, "all"],
        queryFn: () => fetchSaunaStatus({ session }),
    });
    return {
        saunas: data || {},
        isError,
        isSuccess,
        refetch
    };
};
