import { removeUserSauna } from "@huum/api/requests/sauna";
import { translate } from "@huum/i18n";
import { goBack } from "@huum/services/NavigationService";
import { useStores } from "@huum/store";
import { useMutation, useQueryClient } from "@tanstack/react-query";
export const useSaunaRemove = () => {
    const { auth, toast, sauna } = useStores();
    const { mutateAsync, isPending, isSuccess } = useMutation({
        mutationFn: removeUserSauna,
    });
    const queryClient = useQueryClient();
    const handleRemoveUserSauna = async (saunaID) => {
        const result = await mutateAsync({ saunaId: saunaID, session: auth.sessionHash });
        if (result.success) {
            toast.showToast({
                message: translate("info.sauna_removed"),
                type: "success",
            });
            if (saunaID === sauna.saunaId) {
                sauna.clearSaunaState();
            }
            goBack();
            queryClient.invalidateQueries({ queryKey: [`saunasList`, "all"] });
        }
        return result;
    };
    return {
        handleRemoveUserSauna,
        isPending,
        isSuccess,
    };
};
