import dayjs, { Dayjs } from "dayjs";
;
import localeData from "dayjs/plugin/localeData";
import duration from "dayjs/plugin/duration";
import weekOfYear from 'dayjs/plugin/weekOfYear';
import utc from "dayjs/plugin/utc";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";
import timezone from "dayjs/plugin/timezone";
import updateLocale from 'dayjs/plugin/updateLocale';
import isoweek from 'dayjs/plugin/isoWeek';
dayjs.extend(isSameOrAfter);
dayjs.extend(isSameOrBefore);
dayjs.extend(utc);
dayjs.extend(weekOfYear);
dayjs.extend(duration);
dayjs.extend(localeData);
dayjs.extend(timezone);
dayjs.extend(updateLocale);
dayjs.extend(isoweek);
import 'dayjs/locale/de';
import 'dayjs/locale/et';
import 'dayjs/locale/fi';
import 'dayjs/locale/fr';
import 'dayjs/locale/pl';
import 'dayjs/locale/ru';
import 'dayjs/locale/se';
import 'dayjs/locale/it';
const getDate = (date) => {
    return dayjs(date);
};
dayjs.updateLocale(dayjs.locale(), {
    weekStart: 1,
});
export const formatDate = (date, format) => {
    try {
        const dateObject = dayjs(date);
        return dateObject.format(format);
    }
    catch (e) {
        const en = require('dayjs/locale/en');
        dayjs.locale(en);
        dayjs.updateLocale(dayjs.locale(), {
            weekStart: 1,
        });
        return dayjs(date).format(format);
    }
};
export { dayjs as default, getDate, Dayjs };
