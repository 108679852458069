import { StyleSheet } from "react-native";
import { palette } from "@huum/theme/helpers/palette";
export const getModalStyles = (theme) => {
    const { colors, space, shadows } = theme;
    return StyleSheet.create({
        privateText: {
            fontWeight: "700",
            fontSize: 14,
            lineHeight: 20,
            color: palette.invalid,
        },
        dateText: {
            fontWeight: "700",
            fontSize: 14,
            lineHeight: 20,
        },
        dimmedText: {
            fontSize: 10,
            lineHeight: 16,
            color: palette.dimmedText,
        },
        dimmedLargeText: {
            fontSize: 10,
            lineHeight: 16,
            color: palette.dimmedText,
        },
        personText: {
            fontSize: 16,
            lineHeight: 24,
            fontWeight: "700",
        },
        titleText: {
            fontSize: 32,
            lineHeight: 40,
            fontWeight: "700",
        },
        buttonText: {
            fontWeight: "600",
            fontSize: 16,
            lineHeight: 24,
            color: palette.invalid,
        },
        buttonsContainer: {
            width: "100%",
        },
        button: {
            width: "60%",
        },
    });
};
