import { padStart, toString } from 'lodash';
import dayjs from "@huum/utils/date";
export const padNumber = (value) => padStart(toString(value), 2);
export const getRemainderToNextFive = (value) => 5 - (value % 5);
export const getTimerValues = (config) => (key, value) => {
    const { hour, minute } = value;
    const { start, end, duration, date, maxDurationHours } = config;
    const maxDuration = dayjs.duration(maxDurationHours, 'hours');
    const startDateTime = dayjs(date).hour(start.hour).minute(start.minute);
    ;
    const endDateTime = dayjs(date).hour(end.hour).minute(end.minute);
    switch (key) {
        case 'start': {
            const startTime = dayjs(startDateTime).hour(hour).minute(minute);
            const endTime = dayjs(startTime).add(duration);
            return {
                start: {
                    hour: startTime.hour(),
                    minute: startTime.minute()
                },
                duration,
                end: {
                    hour: endTime.hour(),
                    minute: endTime.minute()
                },
            };
        }
        case 'end': {
            const endTime = dayjs(endDateTime).hour(hour).minute(minute);
            const startTime = dayjs(startDateTime);
            if (endTime.isBefore(startTime)) {
                endTime.add(1, 'day');
            }
            const newDuration = endTime.diff(startDateTime, 'minutes');
            if (newDuration > maxDuration.asMinutes()) {
                startTime.add(newDuration - maxDuration.asMinutes(), 'minutes');
            }
            return {
                start: {
                    hour: startTime.hour(),
                    minute: startTime.minute()
                },
                duration: {
                    hour: dayjs.duration(newDuration, 'minutes').hours(),
                    minute: dayjs.duration(newDuration, 'minutes').minutes()
                },
                end: {
                    hour: endTime.hour(),
                    minute: endTime.minute()
                }
            };
        }
        case 'duration': {
            const duration = dayjs.duration({ hours: hour, minutes: minute });
            const chosenDurationLongerThanMaximum = duration.asMinutes() > maxDuration.asMinutes();
            const endTime = dayjs(startDateTime).add(maxDuration);
            if (chosenDurationLongerThanMaximum) {
                return {
                    start: {
                        hour: startDateTime.hour(),
                        minute: startDateTime.minute()
                    },
                    duration: {
                        hour: maxDuration.hours(),
                        minute: maxDuration.minutes()
                    },
                    end: {
                        hour: endTime.hour(),
                        minute: endTime.minute()
                    }
                };
            }
            else {
                const endTime = dayjs(startDateTime).add(duration.asMinutes(), 'minutes');
                return {
                    start,
                    duration: value,
                    end: {
                        hour: endTime.hour(),
                        minute: endTime.minute()
                    }
                };
            }
        }
        default:
            return {
                start,
                duration,
                end,
            };
    }
};
