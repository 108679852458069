//@ts-nocheck
import React from 'react';
import { StackActions } from '@react-navigation/native';
export const navigationRef = React.createRef();
export function navigate(name, params) {
    navigationRef.current?.navigate(name, params);
}
// export function navigateNested(rootScreen: mainRouteName, screen: nestedRouteName, params?: any) {
//   navigate(rootScreen, {
//     screen,
//     params,
//   })
// }
export function replaceStack(screen, params) {
    navigationRef.current?.dispatch(StackActions.replace(screen, params));
}
export function resetNavigation() {
    navigationRef.current.dispatch(StackActions.popToTop());
}
export function goBack() {
    navigationRef.current?.goBack();
}
