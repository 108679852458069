import { translate } from "@huum/i18n";
import { useStores } from "@huum/store";
import { useState } from "react";
import SmartConfig from "react-native-smartconfig-quan";
// timeout not work with android, on android default is 45s
const TIME_OUT_SMART_CONFIG = 30 * 1000; // 30s
export const useSmartConfig = () => {
    const { toast } = useStores();
    const [isLoading, setIsLoading] = useState();
    const [connectedDevice, setConnectedDevice] = useState();
    const handleStartConnection = (params) => {
        const { ssid, password, bssid } = params;
        setIsLoading(true);
        try {
            SmartConfig.start(ssid, bssid, password, TIME_OUT_SMART_CONFIG, (event) => {
                let { eventName } = event;
                if (eventName === "onFoundDevice") {
                    const data = JSON.parse(event.data);
                    setConnectedDevice(data);
                    toast.showToast({
                        message: translate("info.heater_is_connected"),
                        type: "success"
                    });
                }
                else if (eventName === "onFinishScan") {
                    if (!connectedDevice) {
                        // console.log("Device not found");
                        toast.showToast({
                            message: translate("error.device_not_found"),
                            type: "error"
                        });
                    }
                    else {
                        // console.log("All good");
                    }
                }
            });
        }
        catch (error) {
            // console.log("Error", error);
            toast.showToast({
                message: translate("error.device_connection_not_achieved"),
                type: "error"
            });
        }
        finally {
            setIsLoading(false);
        }
    };
    const handleCancelConnection = () => {
        setIsLoading(false);
        SmartConfig.stop();
    };
    return {
        connectedDevice,
        isLoading,
        handleStartConnection,
        handleCancelConnection,
    };
};
