import { useCallback } from "react";
import { useMutation } from "@tanstack/react-query";
import { resetPassword } from "@huum/api/requests/auth";
export const useResetPassword = () => {
    const { data, mutate, isError, isSuccess } = useMutation({
        mutationFn: resetPassword,
    });
    const passwordReset = useCallback((email) => {
        mutate(email);
    }, []);
    return { passwordReset, data, isError, isSuccess };
};
