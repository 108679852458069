import React, { useMemo } from "react";
import { Row } from "native-base";
import { map, split } from "lodash";
import dayjs from "@huum/utils/date";
import styles from "./styles";
import { Touchable } from "@huum/components/atoms/touchable/touchable";
import { Text } from "@huum/components/atoms/text";
import { translate } from "@huum/i18n";
import { useStores } from "@huum/store";
import { cleanNumberValue } from "@huum/utils/validator";
import { padNumber } from "@huum/utils/helpers/timer-helpers";
const TimeSelectorRow = (props) => {
    const { modal } = useStores();
    const { start, end, maxHeatingTime, onChange, defaultHeatingTime = 3 } = props;
    const startDateTime = dayjs(start || undefined);
    const endDateTime = end ? dayjs(end) : dayjs(start || undefined).add(defaultHeatingTime, 'hours');
    const duration = dayjs.duration(endDateTime.diff(startDateTime));
    const getTimeValues = (key, value) => {
        const { hour, minute } = value;
        const maxDuration = dayjs.duration({ hours: maxHeatingTime, minutes: 0 });
        switch (key) {
            case 'start': {
                const startTime = dayjs(startDateTime).hour(hour).minute(minute);
                const endTime = dayjs(startTime).add(duration);
                return {
                    start: startTime,
                    duration,
                    end: endTime,
                };
            }
            case 'end': {
                let endTime = dayjs(endDateTime).hour(hour).minute(minute);
                let startTime = dayjs(startDateTime);
                if (endTime.isBefore(startTime)) {
                    endTime = endTime.add(1, 'day');
                }
                const newDuration = endTime.diff(startDateTime, 'minutes');
                if (newDuration > maxDuration.asMinutes()) {
                    startTime = startTime.add(newDuration - maxDuration.asMinutes(), 'minutes');
                }
                return {
                    start: startTime,
                    duration: dayjs.duration(endTime.diff(startTime)),
                    end: endTime
                };
            }
            case 'duration': {
                const duration = dayjs.duration({ hours: hour, minutes: minute });
                const chosenDurationLongerThanMaximum = duration.asMinutes() > maxDuration.asMinutes();
                if (chosenDurationLongerThanMaximum) {
                    return {
                        start: startDateTime,
                        duration: maxDuration,
                        end: dayjs(startDateTime).add(maxDuration)
                    };
                }
                else {
                    return {
                        start: startDateTime,
                        duration: duration,
                        end: dayjs(startDateTime).add(duration.asMinutes(), 'minutes')
                    };
                }
            }
            default:
                return {
                    start: startDateTime,
                    duration,
                    end: endDateTime,
                };
        }
    };
    const handleTimeChange = (key, value) => {
        const values = getTimeValues(key, value);
        onChange && onChange(values);
    };
    const handleTimePress = (key, time, isDuration, maxHours) => {
        const [hour, minute] = split(time, /[^0-9]/g);
        const minuteNumber = cleanNumberValue(minute);
        const roundedMinute = minuteNumber > 0 ? minuteNumber + (5 - minuteNumber % 5) : minuteNumber;
        modal.openModal("timerSelect", {
            hour: cleanNumberValue(hour),
            minute: isDuration ? minuteNumber : roundedMinute,
            isDuration,
            onValueSelect: (value) => handleTimeChange(key, value),
            maxHours,
            title: translate(`button.${key}`),
        });
    };
    const tabs = useMemo(() => {
        return [
            {
                key: "start",
                title: translate("button.start"),
                time: `${startDateTime.hour()}:${padNumber(startDateTime.minute())}`,
            },
            {
                key: "duration",
                title: translate("button.duration"),
                time: `${duration.get("hours")} h ${padNumber(duration.get("minutes"))} m`,
                isDuration: true,
                maxHours: maxHeatingTime,
            },
            {
                key: "end",
                title: translate("button.end"),
                time: `${endDateTime.hour()}:${padNumber(endDateTime.minute())}`,
            },
        ];
    }, [startDateTime, endDateTime, duration, maxHeatingTime]);
    const Tab = ({ item }) => {
        const { title, time, isDuration, maxHours, key } = item;
        return (<Touchable onPress={() => handleTimePress(key, time, isDuration, maxHours)}>
        <Text style={styles.labelBold16}>{time}</Text>
        <Text style={styles.label14}>{title}</Text>
      </Touchable>);
    };
    return (<Row justifyContent={"space-between"} paddingX={2}>
      {map(tabs, (tab, index) => (<Tab key={index} item={tab}/>))}
    </Row>);
};
export default TimeSelectorRow;
