import { useStores } from "@huum/store";
import { useMutation } from "@tanstack/react-query";
import { get } from "lodash";
import { useCallback, useMemo } from "react";
import { getTemperatures } from "@huum/api/requests/statistics";
import dayjs from "@huum/utils/date";
import { formatDate } from "@huum/utils/date";
export const useStatistics = () => {
    const { auth, sauna } = useStores();
    const { mutate, data, isError, isSuccess, isPending: isLoading } = useMutation({
        mutationFn: getTemperatures,
    });
    const statisticsResults = useMemo(() => {
        return data;
    }, [data]);
    const session = get(auth, "sessionHash", null);
    const getStatistics = useCallback((value) => {
        mutate({
            session,
            saunaId: sauna.saunaId,
            monthData: formatDate(dayjs(value.month), "YYYY-MM"), // request must be made in this format
        });
    }, []);
    return { getStatistics, statisticsResults, isError, isSuccess, isLoading };
};
