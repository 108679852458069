import { useTheme } from "native-base";
import { useMemo } from "react";
export const useFrilliceTheme = () => {
    const theme = useTheme();
    return theme;
};
export const useThemedStyles = (styleFunction) => {
    const theme = useFrilliceTheme();
    return useMemo(() => styleFunction(theme), [theme]);
};
