import Button from "@huum/components/atoms/button";
import { Text } from "@huum/components/atoms/text";
import { translate } from "@huum/i18n";
import { Center, Stack } from "native-base";
const LandingView = (props) => {
    const { setViewComponent } = props;
    const showLoginView = () => setViewComponent("login");
    const showRegisterView = () => setViewComponent("register");
    return (<>
      <Center>
        <Center my={20}>
          <Text preset="h3">{translate("title.hello")}</Text>
          <Text>{translate("title.lets_get_started")}</Text>
        </Center>
      </Center>
      <Stack space={8} width={{
            sm: "100%",
            lg: 300,
        }}>
        <Button onPress={showLoginView} label={translate("button.log_in")} accessibilityLabel="landing-login-btn"/>
        <Button onPress={showRegisterView} mt={8} variant="outline" label={translate("button.create_account")} accessibilityLabel="register-btn"/>
      </Stack>
    </>);
};
export default LandingView;
