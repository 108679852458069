import React from "react";
import { getStyles } from "./styles";
import { useThemedStyles } from "@huum/hooks/useThemedStyles";
import { BaseHeader } from "./base-header";
import { Box, Row } from "native-base";
import { Text } from "@huum/components/atoms/text";
import BrandLogo from "../../organisms/brand-logo";
/**
 * Header that appears on many screens. Will hold navigation buttons and screen title.
 */
export function MainHeader(props) {
    const { title, info, children } = props;
    const style = useThemedStyles(getStyles);
    return (<BaseHeader {...props}>
      <Row flex={1} justifyContent="space-between" alignItems="center">
        <BrandLogo size="xsmall"/>
        <Box style={style.textContainer}>
          <Text lineHeight="md" fontSize="sm" preset="subtitle1" fontWeight="bold">
            {title}
          </Text>
          {info && <Text lineHeight="xs" fontSize="xs" preset="subtitle2">{info}</Text>}
        </Box>
        {children && <Row space={4}>{children}</Row>}
      </Row>
    </BaseHeader>);
}
