import dayjs from "@huum/utils/date";
import { forEach } from 'lodash';
export const getAverageDailyTemperatures = (readings) => {
    const dailyAverages = new Array(7).fill(0);
    const dailyDurations = new Array(7).fill(0);
    let currentDayIndex = (new Date(readings[0].changeTime * 1000).getDay() + 6) % 7;
    let currentDaySum = 0;
    let currentDayDuration = 0;
    // run through all the readings of the week to get the temperature for each day
    forEach(readings, (reading, i) => {
        const readingDayIndex = (new Date(reading.changeTime * 1000).getDay() + 6) % 7;
        if (currentDayIndex === readingDayIndex && i !== readings.length - 1) {
            const nextReading = readings[i + 1] || readings[i];
            const { temperature, changeTime: currentTime } = reading;
            const { changeTime: nextTime } = nextReading;
            const duration = nextTime - currentTime;
            currentDaySum += temperature * duration;
            currentDayDuration += duration;
        }
        else {
            dailyAverages[currentDayIndex] = currentDaySum / currentDayDuration;
            dailyDurations[currentDayIndex] = currentDayDuration;
            currentDayIndex = readingDayIndex;
            currentDaySum = 0;
            currentDayDuration = 0;
        }
    });
    return dailyAverages;
};
const mapSessionsIntoBars = (sessionsData) => {
    const defaultDay = {
        averageTemp: 0,
        averageTarget: 0,
        sessionsCount: 0,
        totalDuration: 0,
        day: '',
    };
    const barGraphData = Array.from({ length: 7 }, () => ({ ...defaultDay }));
    forEach(sessionsData, (session) => {
        const indexOfWeekday = (dayjs(session.from).isoWeekday() + 6) % 7;
        const { durationMillSec, averageTemperature, targetTemperature } = session;
        const barData = barGraphData[indexOfWeekday];
        barData.totalDuration += durationMillSec;
        barData.averageTemp += averageTemperature * durationMillSec;
        barData.averageTarget += targetTemperature * durationMillSec;
        if (targetTemperature > 0) {
            barData.sessionsCount += 1;
        }
    });
    // get averages for each day
    barGraphData.forEach((day, key) => {
        day.day = dayjs().isoWeekday((key + 1) % 7).format('ddd');
        day.averageTemp = (day.averageTemp / day.totalDuration) || day.averageTemp;
        day.averageTarget = (day.averageTarget / day.totalDuration) || day.averageTarget;
    });
    return barGraphData;
};
const getBarGraphValues = (sessionsData) => {
    const barsData = mapSessionsIntoBars(sessionsData);
    return {
        barsData,
    };
};
export default getBarGraphValues;
