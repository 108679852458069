import { translate } from "@huum/i18n";
import SaunaStatusCode from "@huum/utils/sauna-status-code";
import { get } from "lodash";
import dayjs from "@huum/utils/date";
import { useTheme } from "native-base";
export const useSaunaInfoSections = (props) => {
    const { saunaStatus = {}, } = props;
    const { statusCode, startDate, endDate, duration, user, } = saunaStatus;
    const { colors } = useTheme();
    const saunaIsNotHeating = duration === 0 && startDate === endDate;
    // sauna is heating codes
    if (!(statusCode === SaunaStatusCode.SAUNA_ACTIVE
        || statusCode === SaunaStatusCode.SAUNA_NOT_ACTIVE
        || statusCode === SaunaStatusCode.SAUNA_ACTIVE_BLOCKED)
        || saunaIsNotHeating) {
        return [];
    }
    const startTime = dayjs.unix(startDate);
    if (statusCode === SaunaStatusCode.SAUNA_ACTIVE_BLOCKED) {
        return [
            {
                label: translate("text.hosted_by"),
                // labelColor: Colors.greyDark,
                value: get(user, 'name'),
                color: colors.primaryDark,
            },
        ];
    }
    if (!(startDate || endDate))
        return [];
    if (startTime.isAfter(dayjs())) {
        const timeUntilStart = dayjs.duration(startTime.diff(dayjs(), 'millisecond'), 'millisecond');
        return [
            {
                label: translate("label.heating_starts_in"),
                value: `${timeUntilStart.get('hours')}h ${timeUntilStart.get('minutes')}min`,
                color: colors.primaryMedium,
            },
            {
                label: translate('label.heating_starts_at'),
                value: startTime.format('HH:mm'),
                color: colors.primaryMedium,
            },
        ];
    }
    const endTime = dayjs.unix(endDate);
    const timeUntilEnd = dayjs.duration(endTime.diff(dayjs(), 'millisecond'), 'millisecond');
    return [
        {
            label: translate('label.heating_ends_in'),
            value: `${timeUntilEnd.get('hours')}h ${timeUntilEnd.get('minutes')}min`,
            color: colors.error[500],
        },
        {
            label: translate('label.heating_ends_at'),
            value: endTime.format('HH:mm'),
            color: colors.error[500],
        },
    ];
};
