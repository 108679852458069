import React, { useCallback, useState } from "react";
import { Input } from "./input";
export const SecureInput = (props) => {
    const [textIsVisible, setTextVisible] = useState(false);
    const inputIcon = textIsVisible ? 'eye-closed' : 'eye-open';
    const handleIconPress = useCallback(() => {
        setTextVisible(!textIsVisible);
    }, [textIsVisible]);
    return (<Input secureTextEntry={!textIsVisible} iconRight={inputIcon} onIconRightPress={handleIconPress} type={textIsVisible ? 'text' : 'password'} {...props}/>);
};
