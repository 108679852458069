import { useCallback, useMemo } from "react";
import { Input } from "@huum/components/atoms/input/input";
import { Touchable } from "@huum/components/atoms/touchable/touchable";
import { useStores } from "@huum/store";
import { get, find } from "lodash";
import { languages } from "@huum/i18n";
import { observer } from "mobx-react-lite";
const LanguageSelect = observer((props) => {
    const { modal, auth } = useStores();
    const { language, setLanguage } = auth;
    const onConfirm = useCallback((value) => {
        setLanguage(value);
    }, [setLanguage]);
    const onPress = useCallback(() => {
        modal.openModal("languageSelect", {
            options: languages,
            onConfirm,
        });
    }, [languages]);
    const displayValue = useMemo(() => {
        return get(find(languages, (item) => item.value === language), "label", "");
    }, [language]);
    return (<Touchable flex={1} onPress={onPress}>
      <Input onPressIn={onPress} value={displayValue} editable={false} onIconRightPress={onPress} iconRight="chevron-down" iconSize={6}/>
    </Touchable>);
});
export default LanguageSelect;
