const iconsSet = {
    "arrow-left": require('./arrow-left.png'),
    "chevron-down": require('./chevron-down.png'),
    "eye-open": require('./eye-open.png'),
    "eye-closed": require('./eye-closed.png'),
    "support": require('./support.png'),
    "bookings": require('./bookings.png'),
    "add": require('./add.png'),
    "right": require('./right.png'),
    "left": require('./left.png'),
    "large-left": require('./large-left.png'),
    "large-right": require('./large-right.png'),
    "up": require('./up.png'),
    "down": require('./down.png'),
    "heat": require('./heat.png'),
    "heater": require('./heater_icon.png'),
    "close": require('./icon_close.png'),
    "bluetooth": require('./bt_icon.png'),
    "door-open": require('./door-open_icon.png'),
    "calendar": require('./calendar.png'),
    "menu": require('./menu_icon.png'),
    "lamp": require('./lamp_icon.png'),
    "lights": require('./lights_icon.png'),
    "heating2": require('./heating_2_icon.png'),
    "drop-outline": require('./drop_outline_icon.png'),
    "timer": require('./Timer.png'),
    "check": require('./checkmark.png'),
    "info": require('./tooltip.png'),
    "success": require('./success.png'),
    "stopped": require('./stopped_icon.png'),
    "lights-new": require('./icon-lights.png'),
    "heating-new": require('./icon-heating.png'),
    "humidity-new": require('./icon-humidity.png'),
    "led-new": require('./icon-led.png'),
    "booking-all": require('./booking-icon.png'),
    "person": require('./person-icon.png'),
    "settings": require('./settings.png'),
    "remove": require('./remove.png'),
    "plus": require('./plus.png'),
    "wifi-settings": require('./wifi-settings.png'),
    "power-off": require('./power-off.png'),
    "pause": require('./pause.png'),
    "play": require('./play.png'),
    "forward": require('./forward.png'),
    "rewind": require('./rewind.png'),
    "audio": require('./audio.png'),
    "audio-round": require('./audio-round.png'),
    "light-outline": require('./light-outline.png'),
};
export default iconsSet;
