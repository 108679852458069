import { Text } from "@huum/components/atoms/text";
import InfoLabel from "@huum/components/molecules/info-label";
import { Switch } from "@huum/components/molecules/switch";
import TextButton from "@huum/components/molecules/text-button";
import { translate } from "@huum/i18n";
import { navigate } from "@huum/services/NavigationService";
import { useStores } from "@huum/store";
import dayjs from "@huum/utils/date";
import { get, toNumber } from "lodash";
import { Column, Row, Stack, useTheme } from "native-base";
const TemperatureControllerSettings = (props) => {
    const { saunaId, saunaStatus } = props;
    const { sauna } = useStores(); // todo - use sauna status with ID
    const paymentDue = get(sauna, "paymentDue", null);
    const daysUntilPayment = dayjs(paymentDue).diff(dayjs(), 'days');
    const { config } = useTheme();
    const saunaIsPrivate = !!saunaStatus.isPrivate;
    if (parseInt(saunaId) > 500000)
        return null;
    return (<Stack space={4}>
      <Row justifyContent="space-between">
        <InfoLabel preset="button" label={translate("title.max_heating_time")} infoText={translate("info.sauna_max_heating")}/>
        <Text fontWeight="bold">{saunaStatus?.saunaConfig?.maxHeatingTime}</Text>
      </Row>
      <Row justifyContent="space-between">
        <InfoLabel preset="button" label={translate("label.is_sauna_private")} infoText={translate("info.private_sauna", {
            supportEmail: config.helpEmail,
        })}/>
        <Switch value={saunaIsPrivate} disabled/>
      </Row>

      {(toNumber(saunaId) < 120000 && toNumber(saunaId) > 200000) && <Column mt={8}>
        <Row justifyContent="space-between" alignItems="center">
        <Text preset="subtitle1">{translate("button.payment")} <Text preset="subtitle1">{translate("label.days_left", { days: daysUntilPayment })}</Text></Text>
          <TextButton preset="subtitle1" bold title={translate("text.pay_now")} onPress={() => navigate("paymentSettings")}/>
        </Row>
      </Column>}
    </Stack>);
};
export default TemperatureControllerSettings;
