import React, { useCallback, useState } from "react";
import Button from "@huum/components/atoms/button";
import { translate } from "@huum/i18n";
import { useStores } from "@huum/store";
import { Row, Stack } from "native-base";
import TimeSelect from ".";
import { Text } from "@huum/components/atoms/text";
import { ModalContainer } from "@huum/components/views/modals/modal-container";
import { Pressable } from "react-native";
import { map } from "lodash";
import dayjs from "@huum/utils/date";
import { getTimerValues } from "@huum/utils/helpers/timer-helpers";
import { getHitSlop } from "@huum/utils/hitSlop";
var TimeSelectTypes;
(function (TimeSelectTypes) {
    TimeSelectTypes["start"] = "start";
    TimeSelectTypes["duration"] = "duration";
    TimeSelectTypes["end"] = "end";
})(TimeSelectTypes || (TimeSelectTypes = {}));
const TimeSelectModal = (props) => {
    const { modal } = useStores();
    const { closeModal } = modal;
    const { title, maxHour = 3, startTime, endTime, onValueSelect, buttonLabel } = props;
    const initialStart = dayjs(startTime || dayjs());
    const initialEnd = dayjs(endTime || dayjs().add(maxHour, "hours"));
    const initialDuration = dayjs.duration(initialEnd.diff(initialStart));
    const initialTimeValues = {
        [TimeSelectTypes.start]: { hour: initialStart.hour(), minute: initialStart.minute() },
        [TimeSelectTypes.end]: { hour: initialEnd.hour(), minute: initialEnd.minute() },
        [TimeSelectTypes.duration]: { hour: initialDuration.hours(), minute: initialDuration.minutes() },
    };
    const [timeValues, setTimeValues] = useState(initialTimeValues);
    const [timeSelectType, setTimeSelectType] = useState(TimeSelectTypes.start);
    const handleTimeValuesChange = useCallback((type) => (values) => {
        setTimeValues(current => getTimerValues({
            ...current,
            date: initialStart.format(),
            maxDurationHours: maxHour,
        })(type, values));
    }, [initialStart]);
    const handleTimeSave = useCallback(() => {
        closeModal();
        onValueSelect(timeValues);
    }, [timeValues, onValueSelect]);
    const TimeSelectTabs = useCallback(() => {
        return (<Row justifyContent="center" space={8}>
        {Object.values(TimeSelectTypes).map((type) => {
                return (<Pressable key={type} hitSlop={getHitSlop(8)} onPress={() => setTimeSelectType(type)}>
              <Text bold={timeSelectType === type}>{translate(`button.${type}`)}</Text>
            </Pressable>);
            })}
      </Row>);
    }, [timeSelectType]);
    return (<ModalContainer title={title} closeModal={closeModal}>
      <Stack flexGrow={1} justifyContent="space-between" space={8}>
        <TimeSelectTabs />
          <Row>
            {map(Object.values(TimeSelectTypes), (type) => (<TimeSelect key={type} hourValue={timeValues[type].hour} minuteValue={timeValues[type].minute} durationSelect={type === TimeSelectTypes.duration} onValueSelect={handleTimeValuesChange(type)} visible={timeSelectType === type} maxHour={maxHour + 1} animated={false}/>))}
          </Row>
        <Button mt={8} onPress={handleTimeSave} label={buttonLabel || translate("button.start_timer")}/>
      </Stack>
    </ModalContainer>);
};
export default TimeSelectModal;
