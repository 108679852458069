import React, { useCallback, useMemo } from 'react';
import { get, isEmpty, map, times } from 'lodash';
import { BarChart, Grid, YAxis, XAxis, } from 'react-native-svg-charts';
import { selectWeeklySessions } from "@huum/utils/helpers/statistics-selectors";
import getBarGraphValues, { getAverageDailyTemperatures } from '@huum/utils/helpers/bar-graph-selectors';
import { svgRedConfig, svgGreenConfig, yAxisSVG, xAxisSVG, getStatisticsBarGraphStyles, } from './styles';
import Labels from './labels';
import HorizontalLine from './horizontalLine';
import { Box } from 'native-base';
import formatDegreeStatistic from '@huum/utils/helpers/degree-statistics-selectors';
import { useThemedStyles } from '@huum/hooks/useThemedStyles';
import dayjs from '@huum/utils/date';
const StatisticsBarGraph = (props) => {
    const { selectedDate, statistics, temperatureUnit } = props;
    const styles = useThemedStyles(getStatisticsBarGraphStyles);
    const sessionsData = useMemo(() => selectWeeklySessions(statistics, selectedDate), [statistics, selectedDate]);
    const sessions = get(sessionsData, "weeklySessions", []);
    const readings = get(sessionsData, "weeklyReadings", []);
    const defaultXAxis = times(7, (i) => dayjs(selectedDate).isoWeekday(i + 1).format("ddd"));
    const createBars = useCallback((barsData) => {
        let averageDailyTemps = new Array(7).fill(0);
        // don't bother calculating average daily temperatures if every day has a session
        for (let i = 0; i < barsData.length; i += 1) {
            if (!(barsData[i].sessionsCount > 0)) {
                averageDailyTemps = getAverageDailyTemperatures(readings);
                break;
            }
        }
        const formattedBars = map(barsData, (day, index) => ({
            ...day,
            svg: day.sessionsCount > 0 ? svgRedConfig : svgGreenConfig,
            averageTemp: day.sessionsCount > 0
                ? formatDegreeStatistic(day.averageTemp, temperatureUnit)
                : formatDegreeStatistic(averageDailyTemps[index], temperatureUnit),
        }));
        return formattedBars;
    }, [readings, temperatureUnit]);
    const formattedBars = useMemo(() => {
        if (sessions.length > 0) {
            const { barsData } = getBarGraphValues(sessions);
            const barGraphs = createBars(barsData);
            return barGraphs;
        }
        return [];
    }, [sessions]);
    const xAxisDays = map(formattedBars, (bar) => bar.day);
    if (isEmpty(formattedBars)) {
        return (<Box style={styles.graphContainer}>
        <YAxis data={temperatureUnit === "celsius"
                ? map([...Array(11).keys()], (key) => key * 10)
                : map([...Array(12).keys()], (key) => key * 20)} yAccessor={({ item }) => item} style={styles.yAxis} min={0} contentInset={{ top: 20, bottom: 20 }} svg={yAxisSVG}/>
        <Box flexDir="column">
          <BarChart numberOfTicks={11} data={Array(11).fill(null)} style={styles.barChart} contentInset={{
                top: 20,
                bottom: 20,
                left: 8,
                right: 8,
            }} yMin={0} yMax={temperatureUnit === "celsius" ? 100 : 220}>
            <Grid />
          </BarChart>
          <XAxis data={defaultXAxis} xAccessor={({ index }) => index} formatLabel={(value, index) => defaultXAxis[index]} svg={xAxisSVG} contentInset={{ left: 20, right: 20 }}/>
        </Box>
      </Box>);
    }
    return (<Box style={styles.graphContainer}>
      <YAxis data={temperatureUnit === "celsius"
            ? map([...Array(11).keys()], (key) => key * 10)
            : map([...Array(12).keys()], (key) => key * 20)} yAccessor={({ item }) => item} style={styles.yAxis} min={0} contentInset={{ top: 20, bottom: 20 }} svg={yAxisSVG}/>
      <Box style={{ flexDirection: "column" }}>
        <BarChart style={styles.barChart} spacingInner={0.7} contentInset={{
            top: 20,
            bottom: 20,
            left: 8,
            right: 8,
        }} yMin={0} yMax={temperatureUnit === "celsius" ? 100 : 220} yAccessor={({ item }) => Math.round(item.averageTemp) || 0} data={formattedBars}>
          <Grid />
          <Labels />
          {map(formattedBars, (day, i) => {
            const averageTarget = formatDegreeStatistic(day.averageTarget, temperatureUnit);
            return (<>
                <HorizontalLine target={averageTarget} index={i} temperatureUnit={temperatureUnit || ""}/>
              </>);
        })}
        </BarChart>
        <XAxis data={xAxisDays} xAccessor={({ index }) => index} formatLabel={(value, index) => xAxisDays[index]} svg={xAxisSVG} contentInset={{ left: 20, right: 20 }}/>
      </Box>
    </Box>);
};
export default StatisticsBarGraph;
