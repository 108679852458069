import { Icon } from "@huum/components/atoms/icon";
import { Text } from "@huum/components/atoms/text";
import { Touchable } from "@huum/components/atoms/touchable/touchable";
import { hitSlop } from "@huum/theme/helpers/styles";
import { formatDate } from "@huum/utils/date";
import dayjs from "@huum/utils/date";
import { Box, Row } from "native-base";
import React from "react";
const CalendarHeader = (props) => {
    const { currentMonth, pastDatesLimit, futureDatesLimit, onPreviousPress, onNextPress, } = props;
    const monthValue = currentMonth ? dayjs(currentMonth) : dayjs();
    const previousButtonDisabled = pastDatesLimit && pastDatesLimit >= 0
        && dayjs().subtract(pastDatesLimit, "months").isSame(monthValue, "month");
    const nextButtonDisabled = futureDatesLimit && futureDatesLimit >= 0
        && dayjs().endOf("month").add(futureDatesLimit, "months").isSame(monthValue, "week");
    return (<Row paddingX={100} minHeight={5} w="100%" justifyContent="space-between" alignItems="center" flex={1} mb={2}>
      <Touchable disabled={!!previousButtonDisabled} onPress={onPreviousPress} hitSlop={hitSlop}>
        <Box justifyContent="center">
          {!previousButtonDisabled ? <Icon size={6} icon="left"/> : <Box width={6}/>}
        </Box>
      </Touchable>
      <Text preset="subtitle2" fontWeight="bold" lineHeight={20}>
        {monthValue?.isValid() && formatDate(monthValue, "MMMM")}
      </Text>
      <Touchable disabled={!!nextButtonDisabled} onPress={onNextPress} hitSlop={hitSlop}>
        <Box justifyContent="center">
          {!nextButtonDisabled ? <Icon size={6} icon="right"/> : <Box width={6}/>}
        </Box>
      </Touchable>
    </Row>);
};
export default CalendarHeader;
