//@ts-nocheck
import { useState } from "react";
import { useStores } from "@huum/store";
import { useMutation, useQuery } from "@tanstack/react-query";
import { getClientToken, getCustomerInfo, getPaymentAmount, savePaymentNonce } from "@huum/api/requests/user";
export const usePaymentFlow = (type) => {
    const { auth, toast, sauna } = useStores();
    const [isLoading, setIsLoading] = useState(false);
    const { data: customerInfo, isSuccess: customerInfoSuccess } = useQuery({ queryKey: ["customerInfo"], initialData: {} }, () => getCustomerInfo({ session: auth.sessionHash, saunaId: sauna.saunaId }), {
        enabled: type === "info"
    });
    const { data: clientToken, isSuccess: hasToken } = useQuery(["clientToken"], () => getClientToken({ session: auth.sessionHash, saunaId: sauna.saunaId }), {
        enabled: type === "payment"
    });
    const { data: paymentAmount, isSuccess: hasAmount } = useQuery(["paymentAmount"], () => getPaymentAmount({
        session: auth.sessionHash,
        saunaId: sauna.saunaId,
    }), {
        enabled: type === "payment"
    });
    const { mutate: savePaymentNonceMutation } = useMutation(savePaymentNonce);
    const connectPayment = async (values) => {
    };
    return {
        isLoading,
        customerInfo,
        customerInfoSuccess,
        connectPayment,
    };
};
