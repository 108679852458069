import { fcmTokenInitiation, register } from "@huum/api/requests/auth";
import { navigate } from "@huum/services/NavigationService";
import { useStores } from "@huum/store";
import { useMutation } from "@tanstack/react-query";
import { useCallback } from "react";
export const useRegister = () => {
    const { mutate, isError, isSuccess } = useMutation({
        mutationFn: register,
    });
    const { auth } = useStores();
    const handleSuccess = useCallback((data) => {
        auth.startSession({
            settings: data.settings,
            sessionHash: data.session_hash,
            language: auth?.language,
        });
        fcmTokenInitiation(data.session_hash);
        return navigate("saunaConnect");
    }, []);
    const registerUser = useCallback((values) => {
        mutate({ ...values }, {
            onSuccess: handleSuccess
        });
    }, []);
    return { registerUser, isError, isSuccess };
};
