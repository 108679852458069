// Web mock for react-native-avoid-softinput
const AvoidSoftInput = {
    setEnabled: () => { },
    setAdjustPan: () => { },
    setShowAnimationDelay: () => { },
    setHideAnimationDelay: () => { },
    setAvoidOffset: () => { },
    setEasing: () => { },
    setDefaultAppearance: () => { },
    on: () => ({ remove: () => { } }),
    isEnabled: () => false,
    isAvoidingView: () => false,
};
// Make sure we export both default and named exports
export const useAvoidSoftInput = () => ({
    avoidsoftinput: false,
    avoidOffset: 0,
    isSoftInputShown: false,
});
export const AvoidSoftInputView = ({ children, ...props }) => children;
export default AvoidSoftInput;
