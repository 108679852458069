import Button from "@huum/components/atoms/button";
import { FormInput } from "@huum/components/atoms/form-input";
import { useLogin } from "@huum/hooks/api/useLogin";
import { translate } from "@huum/i18n";
import { Stack } from "native-base";
import { useCallback } from "react";
const LoginView = (props) => {
    const { control, handleSubmit, setViewComponent } = props;
    const { login, isLoading } = useLogin();
    const handleLogin = useCallback((values) => {
        login({ username: values?.username, password: values?.password });
    }, [login]);
    const onPasswordResetPress = () => setViewComponent('passwordReset');
    return (<>
      <Stack mt={20} mb={4} space={4} width={{
            sm: "100%",
            md: "420px"
        }} alignSelf={{
            sm: "flex-start",
            md: "center"
        }}>
        <FormInput placeholder={translate("label.email")} name="username" control={control} autoCapitalize="none" autoCorrect={false} keyboardType="email-address" accessibilityLabel="login-email-field"/>
        <FormInput placeholder={translate("label.password")} name="password" control={control} isSecure accessibilityLabel="login-password-field"/>
      </Stack>
      <Stack space={4}>
        <Button onPress={onPasswordResetPress} variant="text" label={translate("button.forgot_password")} accessibilityLabel="login-forgot-password-btn"/>
        <Button onPress={handleSubmit(handleLogin)} label={translate("button.log_in")} accessibilityLabel="login-btn"/>
      </Stack>
    </>);
};
export default LoginView;
