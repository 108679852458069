import React, { useEffect, useMemo, useState } from "react";
import { Animated, Easing, View } from "react-native";
import { palette } from "@huum/theme/helpers";
import { Touchable } from "@huum/components/atoms/touchable/touchable";
import { Text } from "@huum/components/atoms/text";
import { translate } from "@huum/i18n";
import { toUpper } from "lodash";
const HITSLOP = {
    top: 25,
    left: 25,
    right: 25,
    bottom: 25,
};
// dimensions
const THUMB_SIZE = 32;
const WIDTH = 64;
const OFF_POSITION = 1.5;
const ON_POSITION = WIDTH - THUMB_SIZE;
const BORDER_RADIUS = THUMB_SIZE / 2;
// animation
const DURATION = 400;
const CONTAINER = {
    height: THUMB_SIZE,
    justifyContent: "center",
};
const TRACK = {
    height: THUMB_SIZE,
    width: WIDTH,
    borderRadius: BORDER_RADIUS,
    backgroundColor: palette.toggleButtonBg,
};
const THUMB = {
    position: "absolute",
    width: THUMB_SIZE,
    height: THUMB_SIZE,
    borderRadius: THUMB_SIZE / 2,
    backgroundColor: palette.primaryDark,
    alignItems: "center",
    justifyContent: "center",
};
const TEXT = {
    color: palette.white[100],
    fontSize: 9
};
const makeAnimatedValue = (switchOn) => new Animated.Value(switchOn ? 1 : 0);
export function Switch(props) {
    const [timer] = useState(makeAnimatedValue(props.value));
    const startAnimation = useMemo(() => (newValue) => {
        const toValue = newValue ? 1 : 0;
        const easing = Easing.out(Easing.circle);
        Animated.timing(timer, {
            toValue,
            duration: DURATION,
            easing,
            useNativeDriver: true,
        }).start();
    }, [timer]);
    const [previousValue, setPreviousValue] = React.useState(props.value);
    useEffect(() => {
        if (props.value !== previousValue) {
            startAnimation(props.value);
            setPreviousValue(props.value);
        }
    }, [props.value]);
    const handlePress = React.useMemo(() => () => props.onToggle && props.onToggle(!props.value), [props.onToggle, props.value]);
    if (!timer) {
        return null;
    }
    const translateX = timer.interpolate({
        inputRange: [0, 1],
        outputRange: [OFF_POSITION, ON_POSITION],
    });
    const trackStyle = [
        TRACK,
        props.value ? props.trackOnStyle : props.trackOffStyle,
    ];
    const thumbStyle = [
        THUMB,
        {
            transform: [{ translateX }],
            borderWidth: props.value ? 0 : 1,
        },
        props.value ? props.thumbOnStyle : props.thumbOffStyle,
    ];
    const displayText = props.value ? translate("label.yes") : translate("label.no");
    return (<Touchable onPress={handlePress} hitSlop={HITSLOP} disabled={!!props.disabled} _disabled={{ opacity: 0.7 }}>
      <View style={CONTAINER}>
        <Animated.View style={trackStyle}/>
        <Animated.View style={thumbStyle}>
          <Text fontWeight="900" style={TEXT}>{toUpper(displayText)}</Text>
        </Animated.View>
      </View>
    </Touchable>);
}
