import { Image } from 'native-base';
import React, { useEffect } from 'react';
const SIZES = {
    xsmall: { width: "30px", height: "30px" },
    small: { width: 43, height: 46 },
    medium: { width: "60px", height: "64px" },
    large: { width: 90, height: 96 },
};
const BrandLogo = (props) => {
    const { isAnimated, size = "small" } = props;
    useEffect(() => {
        if (isAnimated) {
            // animate
        }
    }, [isAnimated]);
    const sizeStyle = SIZES[size];
    return (<Image resizeMode="contain" src={require('@huum/assets/brand/huum-logo.png')} {...sizeStyle}/>);
};
export default BrandLogo;
