import { Row, Stack, Box } from 'native-base';
import { find, map } from 'lodash';
import { Pressable } from 'react-native';
import { Text } from '@huum/components/atoms/text';
const effects = [
    {
        slot: 0,
        data: [{ "c": "F00" }, { "c": "0F0" }, { "c": "00F" }],
        effectKey: "a",
        color: "#61b8a9",
        name: "Aurora",
        gradient: ["#90D0C5", "#38A391"]
    },
    // {
    //   slot: 1,
    //   data: [{"c":"F00"},{"c":"0F0"},{"c":"00F"}],
    //   effectKey: "p",
    //   color: "#dc9e00",
    //   name: "Random",
    // },
    {
        slot: 2,
        data: [{ "c": "F00" }, { "c": "0F0" }, { "c": "00F" }],
        effectKey: "f",
        color: "#8cc5ef",
        name: "Random",
        gradient: ["#3C73DB", "#3D1D95"]
    },
    {
        slot: 3,
        data: [{ "c": ["F00", "35F", "0CB"] }, { "c": ["000"] }],
        effectKey: "r",
        color: "#3d45b6",
        name: "Candle",
        gradient: ["#E3DA00", "#D35100"]
    },
    {
        slot: 4,
        data: [{ "c": "F00" }, { "c": "0F0" }, { "c": "00F" }],
        effectKey: "o",
        color: "#b40000",
        name: "Ocean",
        gradient: ["#A0D5FC", "#6EADDB"]
    },
    {
        slot: 5,
        data: [{ "c": "F00" }, { "c": "0F0" }, { "c": "00F" }],
        effectKey: "s",
        color: "#b40000",
        name: "Sunset",
        gradient: ["#FDB813", "#E8630C"]
    },
];
export const EffectButton = (props) => {
    const { gradient, name, onPress, icon, slot, isSelected, onLongPress } = props;
    const handleOnPress = () => onPress(slot);
    const handleOnLongPress = () => onLongPress(slot);
    return (<Pressable onPress={handleOnPress} onLongPress={handleOnLongPress}>
      <Stack alignItems="center" maxWidth={16}>
        <Box width={8} height={8} backgroundColor={{
            linearGradient: {
                colors: gradient,
                start: [0, 0],
                end: [1, 0],
                locations: [0, 0.6]
            }
        }} borderRadius={16} borderWidth={2} borderColor={isSelected ? "primaryDark" : "transparent"}/>
        {name && <Text mt={2} textAlign="center" bold={isSelected} lineHeight="sm" fontSize="sm">{name}</Text>}
      </Stack>
    </Pressable>);
};
const EffectsSelect = (props) => {
    const { selected, onSelect } = props;
    const onEffectPress = (slot) => {
        const effect = find(effects, { slot }) || { slot: -1 };
        onSelect(effect);
    };
    return (<>
      <Row justifyContent="space-between" mb={4}>
        {map(effects, (effect) => (<EffectButton {...effect} isSelected={selected === effect.slot} onPress={onEffectPress}/>))}
      </Row>
    </>);
};
export default EffectsSelect;
