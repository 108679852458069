import { DEFAULT_CONTROLLER_WIDTH, convertTemperatureToCoordinates, } from "./helpers";
import React, { useCallback, useMemo } from "react";
import { Box, Row, Stack, useTheme } from "native-base";
import SaunaStatusCode from "@huum/utils/sauna-status-code";
import { Text } from "@huum/components/atoms/text";
import SaunaController from ".";
import { Icon } from "@huum/components/atoms/icon";
import { map, times } from "lodash";
import dayjs from "dayjs";
const getStepsCoordinates = (width, count = 11) => {
    return times(count + 1, (index) => {
        const indexAsTemperature = index * 10;
        const indexPosition = convertTemperatureToCoordinates(width, 15.5)(indexAsTemperature);
        return {
            position: indexPosition,
            index,
            value: index + 1,
        };
    });
};
const PowerLevelController = (props) => {
    const { width: customWidth, temperature, topOffset = 0, isHeating, statusCode, targetTemperature, startDate, temperatureEditIsDisabled, handleSaunaStopPress, handleSaunaStartPress, onTemperatureChange, } = props;
    const width = customWidth || DEFAULT_CONTROLLER_WIDTH;
    const { colors } = useTheme();
    const saunaIsHeating = useMemo(() => {
        // sauna is heating codes
        if (statusCode === SaunaStatusCode.SAUNA_ACTIVE ||
            statusCode === SaunaStatusCode.SAUNA_ACTIVE_BLOCKED)
            return true;
        return false;
    }, [statusCode]);
    const heatingTemperature = useMemo(() => {
        if (saunaIsHeating && startDate) {
            const heatingStartTime = dayjs.unix(startDate);
            const heatingTimeDiff = dayjs().diff(heatingStartTime, "minute");
            if (heatingTimeDiff < 0)
                return 0;
            // power level controller temperature should be calculated from target temperature and heating time.
            // Target temp 1 = 30 min, target temp 11 = 90 min;
            if (heatingTimeDiff < 30)
                return 1 / 30 * heatingTimeDiff * 10;
            const maxHeatingMinutes = 60 / 10 * targetTemperature;
            if (heatingTimeDiff > maxHeatingMinutes)
                return targetTemperature * 10;
            return (1 + (heatingTimeDiff - 30) / maxHeatingMinutes * (targetTemperature - 1)) * 10;
        }
        return targetTemperature;
    }, [saunaIsHeating, startDate, targetTemperature]);
    const getTemperatureColor = (temperature) => saunaIsHeating ? colors.red[600] : colors.primaryLight;
    const handleControllerPress = () => {
        if (saunaIsHeating) {
            handleSaunaStopPress && handleSaunaStopPress();
        }
        else {
            handleSaunaStartPress && handleSaunaStartPress();
        }
    };
    const renderControllerSteps = () => {
        const steps = getStepsCoordinates(width);
        return <>
     {map(steps, (step) => (<Box zIndex={3} position="absolute" left={step.position.x} top={step.position.y} width={"15px"} height={"15px"} borderRadius={16} background="#D3E2ED"/>))}
    </>;
    };
    const renderInnerCircleContent = useCallback((props) => {
        const visiblePowerValue = Math.round(props.temperature / 10);
        const levelFontSize = width < 200 ? "3xl" : "6xl";
        const indicatorSize = width < 200 ? "md" : undefined;
        const iconSize = width < 200 ? 12 : undefined;
        const heatingColor = saunaIsHeating ? "#CA4E69" : undefined;
        return (<Stack space={2} alignItems="center" justifyContent="space-between">
        <Row space={1} mt={2} alignItems="baseline">
          <Text fontSize={levelFontSize} lineHeight="xs" fontWeight="semibold" color={isHeating ? "#CA4E69" : undefined}>
            {visiblePowerValue}
          </Text>
          <Text fontSize={indicatorSize} color={saunaIsHeating ? "#CA4E69" : undefined}>/</Text>
          <Text lineHeight={indicatorSize} fontSize={indicatorSize} color={saunaIsHeating ? "#CA4E69" : undefined}>
            11
          </Text>
        </Row>
        {!temperatureEditIsDisabled && <Text color={saunaIsHeating ? "#CA4E69" : undefined} fontWeight="semibold" fontSize="xs">{"Power"}</Text>}
        <Icon size={iconSize} icon="heating2" tint={heatingColor}/>
      </Stack>);
    }, [saunaIsHeating]);
    return (<SaunaController topOffset={topOffset} width={width} isHeating={saunaIsHeating} heatingTemperature={heatingTemperature} InnerCircleContent={renderInnerCircleContent} ExtraContent={renderControllerSteps} temperatureColor={getTemperatureColor} mode="power" onPress={handleControllerPress} controllerCircleText={(props) => Math.round(props.temperature / 10).toString()} onTemperatureChange={onTemperatureChange} temperatureEditIsDisabled={temperatureEditIsDisabled}/>);
};
export default PowerLevelController;
