import { Text } from "@huum/components/atoms/text";
import TextButton from "@huum/components/molecules/text-button";
import CalendarBookingItem from "@huum/components/organisms/calendar-booking-item";
import Loader from "@huum/components/organisms/loader";
import { translate } from "@huum/i18n";
import { useStores } from "@huum/store";
import { Stack, Center } from "native-base";
import { useCallback, useState } from "react";
import { FlatList } from "react-native";
const Bookings = ({ data, showDate, highlightOwnItems }) => {
    const { modal } = useStores();
    const showBookingDetailsModal = useCallback((item) => () => {
        modal.openModal("bookingDetail", {
            ...item,
            onContactHostPress: () => null,
            onEdit: () => null,
            onDelete: () => null,
        });
    }, [modal]);
    const CalendarItem = ({ item }) => {
        return (<CalendarBookingItem onPress={showBookingDetailsModal(item)} showDate={showDate} highlightOwnItem={highlightOwnItems} {...item}/>);
    };
    if (data.length === 0) {
        return (<Stack flexGrow={1}>
        <Text mt={4} textAlign="center" fontSize={14}>
            {translate('placeholder.empty_booking_list')}
        </Text>
      </Stack>);
    }
    return (<FlatList data={data} keyExtractor={(item) => `${item.id}-${item.startTime}`} renderItem={CalendarItem} contentContainerStyle={{ paddingHorizontal: 16, paddingVertical: 4 }}/>);
};
const BookingListView = (props) => {
    const { data, onViewModeChange, isMyBookingList, isLoading } = props;
    const [viewMode, setViewMode] = useState("calendar");
    const handleChangeViewMode = useCallback((viewMode) => {
        onViewModeChange(viewMode);
        setViewMode(viewMode);
    }, [onViewModeChange]);
    return (<Stack flex={1} backgroundColor="white.100">
      <Bookings showDate={viewMode === "list" || isMyBookingList} highlightOwnItems={!isMyBookingList} data={data}/>
      {!isMyBookingList && (<Center p={4}>
         <TextButton title={viewMode === "calendar" ? translate('button.view_all_bookings') : translate('button.calendar_view')} onPress={() => handleChangeViewMode(viewMode === "calendar" ? "list" : "calendar")}/>
       </Center>)}
      {isLoading && <Loader isLoading={isLoading} minimumDuration={500}/>}
    </Stack>);
};
export default BookingListView;
