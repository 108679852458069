import React from 'react';
import { Box, Center, Column, Pressable, Row, Text, VStack, useBreakpointValue, } from 'native-base';
import { Icon } from '@huum/components/atoms/icon';
import Card from '@huum/components/atoms/card';
import { getHitSlop } from '@huum/utils/hitSlop';
import PlayEllipse from './PlayEllipse';
import SliderSelect from '@huum/components/molecules/slider-select';
import { Platform } from 'react-native';
const Player = ({ currentSong, isPlaying, volume, onPlayPause, onNext, onPrevious, onVolumeChange, }) => {
    const isMobile = useBreakpointValue({
        base: true,
        lg: false,
    });
    const [showMobileAudioControls, setShowMobileAudioControls] = React.useState(false);
    const renderControls = (mobile = false) => (<Row space={4} alignItems="center">
      <Pressable onPress={onPrevious} hitSlop={getHitSlop(mobile ? 4 : 20)}>
        <Box bg="white" p={3} borderRadius="full" shadow={Platform.OS === "android" ? "none" : 2}>
          <Icon icon="rewind" size={8}/>
        </Box>
      </Pressable>
      <Pressable onPress={onPlayPause} hitSlop={getHitSlop(mobile ? 4 : 20)}>
        <Center>
          <PlayEllipse width={mobile ? 45 : 180} height={mobile ? 45 : 180}/>
          <Box position="absolute">
            <Icon icon={isPlaying ? 'pause' : 'play'} size={mobile ? 6 : 14} color="darkText"/>
          </Box>
        </Center>
      </Pressable>

      <Pressable onPress={onNext} hitSlop={getHitSlop(mobile ? 4 : 20)}>
        <Box bg="white" p={3} borderRadius="full" shadow={Platform.OS === "android" ? "none" : 2}>
          <Icon icon="forward" size={8}/>
        </Box>
      </Pressable>
    </Row>);
    const renderVolumeSlider = (isMobile) => {
        return (<Row w={isMobile ? "70%" : "80%"} alignItems="center" mb={isMobile ? 0 : 16}>
        <Icon icon="audio" size={isMobile ? 8 : 10}/>
        <SliderSelect defaultValue={volume} onChange={onVolumeChange} unit="%"/>
      </Row>);
    };
    // Mobile version (bottom card)
    if (isMobile) {
        return (<Box safeAreaBottom pb={8} px={4}>
        <Card bg="white" borderWidth={1} borderColor="gray.200" shadow={Platform.OS === "android" ? "none" : 1} h="80px">
          <Row flex={1} h="100%" alignItems="center">
              <Column flex={1} alignItems="center">
                {showMobileAudioControls ? renderVolumeSlider(true) : renderControls(true)}
                {!showMobileAudioControls && <VStack flex={1}>
                  <Text fontSize="md" fontWeight="600" color="darkText" numberOfLines={1}>
                    {currentSong?.title}
                  </Text>
                </VStack>}
              </Column>
            <Pressable onPress={() => setShowMobileAudioControls(prev => !prev)} position="absolute" hitSlop={getHitSlop(8)} right={0}>
              <Icon size={6} tint="green.500" icon={showMobileAudioControls ? 'close' : 'audio'}/>
            </Pressable>
          </Row>
        </Card>
      </Box>);
    }
    // Desktop/tablet version (full size)
    return (<Column alignItems="center" w="50%" px={16} mt="24px">
      {renderVolumeSlider(false)}
      {renderControls()}
    </Column>);
};
export default Player;
