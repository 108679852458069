import { ScrollView, } from "native-base";
import { AvoidSoftInputView } from "react-native-avoid-softinput";
export const Screen = (props) => {
    const { children, padding = 16, ...rest } = props;
    return (<AvoidSoftInputView style={{ flex: 1 }}>
      <ScrollView showsVerticalScrollIndicator={false} contentContainerStyle={{ flexGrow: 1, padding, paddingBottom: 32 }} keyboardShouldPersistTaps="handled" keyboardDismissMode="on-drag" {...rest}>
        {children}
      </ScrollView>
    </AvoidSoftInputView>);
};
