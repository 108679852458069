import { StyleSheet } from "react-native";
export const getStyles = (theme) => {
    const { colors, space } = theme;
    const BASE_CONTAINER = {
        position: "absolute",
        left: 0,
        right: 0,
        paddingHorizontal: space[2]
    };
    const BASE_MESSAGE = {
        width: "100%",
        borderRadius: 8,
        marginTop: space[4],
        flexDirection: 'row',
        paddingHorizontal: space[4],
        paddingVertical: space[4],
    };
    return StyleSheet.create({
        MESSAGE_ICON: {
            height: 24,
            width: 24,
        },
        MESSAGE_CONTAINER: {
            paddingHorizontal: space[3],
            justifyContent: 'center'
        },
        CONTAINER_BOTTOM: {
            ...BASE_CONTAINER,
            bottom: 0,
        },
        SUCCESS_MESSAGE: {
            ...BASE_MESSAGE,
            backgroundColor: colors.primaryDark,
        },
        ERROR_MESSAGE: {
            ...BASE_MESSAGE,
            backgroundColor: colors.error[700],
        },
        INFO_MESSAGE: {
            ...BASE_MESSAGE,
            backgroundColor: colors.primaryLight,
        }
    });
};
