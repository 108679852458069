import React, { useEffect, useMemo, useState } from "react";
import { SafeAreaProvider } from "react-native-safe-area-context";
import { StatusBar, useColorScheme, Text, TextInput } from "react-native";
import { RootStoreProvider, setupRootStore } from "@huum/store";
import { NativeBaseProvider } from "native-base";
import SplashScreen from 'react-native-splash-screen';
import AppNavigator from "@huum/navigation";
import NetInfo from "@react-native-community/netinfo";
import { QueryClientProvider } from "@tanstack/react-query";
import { theme, themeConfig } from "./theme";
import { ModalRoot } from "./components/organisms/modal-root";
import { getQueryClient } from "./services/QueryService";
import { ToastRoot } from "./components/organisms/toast";
Text.defaultProps = Text.defaultProps || {};
Text.defaultProps.allowFontScaling = false;
TextInput.defaultProps = TextInput.defaultProps || {};
TextInput.defaultProps.allowFontScaling = false;
// if(__DEV__) {
//   import('./services/reactotron/index').then(() => console.log('Reactotron Configured'))
// }
const App = () => {
    const [rootStore, setRootStore] = useState(undefined);
    const isDarkMode = useColorScheme() === "dark";
    // Kick off initial async loading actions, like loading fonts and RootStore
    useEffect(() => {
        (async () => {
            setupRootStore().then(setRootStore);
        })();
        NetInfo.configure({
            shouldFetchWiFiSSID: true, // met iOS requirements to get SSID. Will leak memory if set to true without meeting requirements.
        });
        SplashScreen.hide();
    }, []);
    const queryClient = useMemo(() => getQueryClient(rootStore), [rootStore]);
    if (!rootStore) {
        return null;
    }
    ;
    return (<RootStoreProvider value={rootStore}>
      <QueryClientProvider client={queryClient}>
        <SafeAreaProvider>
          <NativeBaseProvider theme={theme} config={themeConfig}>
              <StatusBar barStyle="dark-content"/>
              <AppNavigator isDarkMode={isDarkMode}/>
              <ModalRoot />
              <ToastRoot />
          </NativeBaseProvider>
        </SafeAreaProvider>
      </QueryClientProvider>
    </RootStoreProvider>);
};
export default App;
