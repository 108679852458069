import React, { useEffect, useRef } from 'react';
import { Animated } from 'react-native';
import { Pressable } from 'native-base';
const PulsatingShadowCircle = ({ isHeating, innerCircleSize, gradientColor, onPress, InnerCircleContent, temperature, }) => {
    const pulseAnim = useRef(new Animated.Value(0)).current;
    useEffect(() => {
        const pulse = Animated.loop(Animated.sequence([
            Animated.timing(pulseAnim, { toValue: 1, duration: 3000, useNativeDriver: false }),
            Animated.delay(3000),
            Animated.timing(pulseAnim, { toValue: 0, duration: 3000, useNativeDriver: false }),
        ]));
        if (isHeating) {
            pulse.start();
        }
        else {
            pulse.stop();
            pulseAnim.setValue(0);
        }
        return () => pulse.stop();
    }, [isHeating, pulseAnim]);
    const animatedStyle = {
        shadowColor: isHeating ? gradientColor : 'black',
        shadowOffset: { width: 0, height: isHeating ? 8 : 2 },
        shadowOpacity: pulseAnim.interpolate({
            inputRange: [0, 1],
            outputRange: [0.2, 0.5],
        }),
        shadowRadius: isHeating ? 9 : 3,
        elevation: isHeating ? 9 : 3,
    };
    const animatedBorderStyle = {
        borderColor: isHeating
            ? pulseAnim.interpolate({
                inputRange: [0, 1],
                outputRange: ['rgba(0,0,0,0)', gradientColor],
            })
            : 'transparent',
        borderWidth: isHeating ? 1 : 0,
    };
    return (<Animated.View style={[animatedStyle, {
                position: 'absolute',
                height: innerCircleSize,
                width: innerCircleSize,
                borderRadius: innerCircleSize / 2,
            }]}>
      <Animated.View style={[animatedBorderStyle, {
                height: '100%',
                width: '100%',
                borderRadius: innerCircleSize / 2,
            }]}>
        <Pressable onPress={onPress} height="100%" width="100%" borderRadius={innerCircleSize / 2} bg="white.100" alignItems="center" justifyContent="center" _pressed={{
            shadow: 0,
        }}>
          {InnerCircleContent && <InnerCircleContent temperature={temperature}/>}
        </Pressable>
      </Animated.View>
    </Animated.View>);
};
export default PulsatingShadowCircle;
