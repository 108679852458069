import React, { useCallback } from "react";
import { Input as BaseInput } from "../input/input";
import { useController } from "react-hook-form";
import { SecureInput } from "../input/secure-input";
const FormInput = (props) => {
    const { isSecure, name, control, rules, onBlur, defaultValue, transform, ...rest } = props;
    const { field: { onChange, onBlur: formItemOnBlur, value, ref }, fieldState: { error, isTouched, isDirty }, } = useController({
        name,
        control,
        rules,
        defaultValue,
    });
    const handleInputBlur = useCallback((e) => {
        formItemOnBlur();
        onBlur && onBlur(e);
    }, [formItemOnBlur, onBlur]);
    const InputComponent = isSecure ? SecureInput : BaseInput;
    const displayValue = transform?.input && transform.input(value) || value;
    const handleInputChange = useCallback((text) => {
        if (transform?.output) {
            onChange(transform.output(text));
        }
        else {
            onChange(text);
        }
    }, []);
    return (<InputComponent innerRef={ref} value={displayValue || (!isDirty ? defaultValue : undefined)} onChangeText={handleInputChange} helperTextType={error ? "error" : "info"} helperText={error?.message} onBlur={handleInputBlur} borderColor={error ? "red.600" : "primaryDark"} {...rest}/>);
};
export { FormInput };
