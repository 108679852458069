import React, { useState, useEffect, useCallback } from 'react';
import RNPickerSelect from 'react-native-picker-select';
import { getPickerStyles } from "./styles";
import { useThemedStyles } from '@huum/hooks/useThemedStyles';
import { Icon } from '@huum/components/atoms/icon';
const ScrollPicker = (props) => {
    const { items, placeholder, error, selectedValue, disabled } = props;
    const [value, setValue] = useState(selectedValue);
    const styles = useThemedStyles(getPickerStyles);
    useEffect(() => {
        props.onValueChange(value);
    }, [value]);
    const setSelectedValue = (value) => setValue(value);
    const displayIcon = useCallback(() => {
        return <Icon icon="chevron-down" size={8}/>;
    }, []);
    return (<RNPickerSelect disabled={!!disabled} onValueChange={setSelectedValue} items={items} value={value || selectedValue} placeholder={placeholder} useNativeAndroidPickerStyle={false} style={{
            iconContainer: {
                ...styles.iconContainer,
            },
            viewContainer: {
                ...styles.inputContainer,
            },
            placeholder: {
                ...styles.placeholder,
            },
            inputIOS: {
                ...styles.input,
            },
            inputAndroid: {
                ...styles.input,
            },
            inputWeb: {
                ...styles.input,
            },
        }} Icon={displayIcon()}/>);
};
export default ScrollPicker;
