import { StyleSheet } from 'react-native';
import { palette } from "@huum/theme/helpers";
export const getPickerStyles = (theme) => {
    const { colors, space, shadows } = theme;
    return StyleSheet.create({
        iconContainer: {
            justifyContent: "center",
            height: "100%",
        },
        inputContainer: {
            borderRadius: 24,
            borderColor: palette.primaryDark,
            borderWidth: 1,
            minHeight: 48,
            justifyContent: "center",
            backgroundColor: colors.white[100],
            paddingVertical: space[2],
            paddingHorizontal: space[4],
        },
        placeholder: {
            color: palette.dimmedText,
            fontSize: 14,
        },
        input: {
            color: palette.primaryDark,
            fontSize: 14
        },
    });
};
