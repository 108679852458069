// @ts-nocheck
import React from 'react';
import { Pressable, Row, Stack } from 'native-base';
import { Text } from '@huum/components/atoms/text';
import dayjs from "@huum/utils/date";
;
const CalendarBookingItem = (props) => {
    const { isMine, isPrivate, day, month, startTime, endTime, title, ownerName, showDate, highlightOwnItem, ...rest } = props;
    return (<Pressable borderRadius={16} padding={4} minHeight="80px" justifyContent="center" backgroundColor="white.100" shadow={highlightOwnItem && isMine ? 2 : undefined} mb={4} {...rest}>
      <Row flex={1} space={4} justifyContent="space-between">
       {showDate && (<Stack width={12}>
          <Text fontWeight="bold" fontSize="lg">
            {dayjs.unix(startTime).format('DD')}
          </Text>
          <Text fontWeight="bold" fontSize="sm" numberOfLines={1} ellipsizeMode="clip">
            {dayjs.unix(startTime).format('MMM')}
          </Text>
        </Stack>)}
        <Stack>
          <Text fontSize="sm">
            {dayjs.unix(startTime).format('HH:mm')}
            -
            {dayjs.unix(endTime).format('HH:mm')}
          </Text>
          {/* <Text
          fontSize="sm"
          bold
          color={isPrivate ? "warning.600" : "primaryDark"}
        >
          {translate(isPrivate ? 'label.private' : 'label.public')}
        </Text> */}
        </Stack>
        <Stack flex={1}>
          <Text fontSize={16} fontWeight="bold" textBreakStrategy="simple" numberOfLines={2}>
            {title}
          </Text>
          <Text fontSize="sm">{ownerName}</Text>
        </Stack>
      </Row>
      
    </Pressable>);
};
export default CalendarBookingItem;
