import React from "react";
import { Touchable } from "@huum/components/atoms/touchable/touchable";
import { Text } from "@huum/components/atoms/text";
import { getSupprtIconButtonStyles } from "./styles";
import { useThemedStyles } from "@huum/hooks/useThemedStyles";
import { Center } from "native-base";
import { translate } from "@huum/i18n";
import { Icon } from "@huum/components/atoms/icon";
const SupportIconButton = (props) => {
    const { onPress, onIconPress } = props;
    const styles = useThemedStyles(getSupprtIconButtonStyles);
    return (<Center>
      <Touchable onPress={onIconPress}>
        <Icon icon="support" containerStyle={styles.iconContainer}/>
      </Touchable>
      <Touchable onPress={onPress}>
        <Text preset="subtitle1" fontWeight="bold">{translate("button.support_uppercase")}</Text>
      </Touchable>
    </Center>);
};
export default SupportIconButton;
