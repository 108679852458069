import { useStores } from "@huum/store";
import { useEffect } from "react";
import ControllerListItem from "../components/controller-list-item";
import { Row, ScrollView, Stack } from "native-base";
import ControllerHeader from "@huum/components/organisms/controller-header";
import { Text } from "@huum/components/atoms/text";
import { translate } from "@huum/i18n";
import { navigate, replaceStack } from "@huum/services/NavigationService";
import { useSaunasList } from "@huum/hooks/api/useSaunasList";
import { isObject, map, values } from "lodash";
const SaunaControllersListScreen = () => {
    const { sauna } = useStores();
    const { saunas, isSuccess, refetch, } = useSaunasList();
    const selectSauna = (saunaId) => {
        sauna.setSaunaId(saunaId);
        navigate("controller");
    };
    const handleStatusRefetch = () => {
        setTimeout(() => { refetch(); }, 500);
    };
    useEffect(() => {
        if (values(saunas)?.length === 0 && isSuccess) {
            replaceStack("saunaConnect", { mode: "change" });
        }
    }, [saunas, isSuccess]);
    return (<>
      <ControllerHeader />
      <ScrollView>
        <Stack p={4} space={4}>
          <Text fontSize="xl" bold>
            {translate("title.my_saunas")}
          </Text>
          <Row flexWrap="wrap" justifyContent="space-between" alignItems="center">
            {map(saunas, (status, saunaId) => (<ControllerListItem onPress={() => selectSauna(saunaId)} controllerType={(parseInt(saunaId)) > 500000 ? "power" : "temperature"} saunaId={saunaId} saunaName={status?.saunaName || saunaId} onStartStopPress={handleStatusRefetch} {...(isObject(status) ? status : {})}/>))}
            <ControllerListItem controllerType="add"/>
          </Row>
        </Stack>
      </ScrollView>
    </>);
};
export default SaunaControllersListScreen;
