import React, { useCallback, useMemo } from 'react';
import dayjs from "@huum/utils/date";
import { selectMonthlySessions } from '@huum/utils/helpers/statistics-selectors';
import MonthCalendar from '../calendar/month-calendar';
import { Box } from 'native-base';
import { formatDate } from '@huum/utils/date';
const StatisticsCalendarGraph = (props) => {
    const { goToDayView, selectedDate, statistics } = props;
    const montlySessionsData = useMemo(() => {
        const sessions = selectMonthlySessions(statistics, selectedDate);
        return sessions;
    }, [statistics, selectedDate]);
    const { dailySessionCount } = montlySessionsData;
    const setStatisticsDate = useCallback((day) => {
        goToDayView(day);
    }, []);
    return (<Box mt={10}>
      <MonthCalendar visible hideHeader={true} limitPastDates={0} limitFutureDates={0} activeMonth={formatDate(dayjs(selectedDate), "YYYY-MM-DD")} sessionCount={dailySessionCount} selectedDate={selectedDate} onDateSelect={setStatisticsDate}/>
    </Box>);
};
export default StatisticsCalendarGraph;
