import { MainHeader } from "@huum/components/molecules/header/main-header";
import { IconButton } from "@huum/components/molecules/icon-button";
import { navigate } from "@huum/services/NavigationService";
import { useStores } from "@huum/store";
import { toNumber } from "lodash";
const ControllerHeader = (props) => {
    const { sauna } = useStores();
    const { hideBookingButton, ...rest } = props;
    const bookingButtonHidden = toNumber(sauna.saunaId) < 110000 || hideBookingButton;
    return (<MainHeader {...rest}>
        <IconButton onPress={() => navigate("bookings")} icon={"calendar"} visible={!bookingButtonHidden} size={12}/>
        <IconButton onPress={() => navigate("menu")} icon={"menu"} testID={"main-menu-btn"} accessibilityLabel={"main-menu-btn"} size={12}/>
      </MainHeader>);
};
export default ControllerHeader;
