import Button from "@huum/components/atoms/button";
import Checkbox from "@huum/components/atoms/checkbox";
import { FormInput } from "@huum/components/atoms/form-input";
import { Text } from "@huum/components/atoms/text";
import LanguageSelect from "@huum/components/molecules/language-select";
import TextButton from "@huum/components/molecules/text-button";
import { useRegister } from "@huum/hooks/api/useRegister";
import { translate } from "@huum/i18n";
import { useStores } from "@huum/store";
import { validateEmail, validateLengthAtLeastNChars, validateNumber } from "@huum/utils/validator";
import { observer } from "mobx-react-lite";
import { Box, Row, Stack } from "native-base";
import { useCallback, useEffect, useState } from "react";
import { Controller } from "react-hook-form";
const RegisterView = observer((props) => {
    const { control, handleSubmit, watch } = props;
    const [errorMessage, setErrorMessage] = useState("");
    const { modal, toast, auth } = useStores();
    const { registerUser, isError } = useRegister();
    useEffect(() => {
        if (errorMessage) {
            toast.showToast({
                message: translate(`error.${errorMessage}`),
                type: "error",
                duration: 3000,
            });
            setTimeout(() => {
                setErrorMessage("");
            }, 3000);
            ;
        }
    }, [errorMessage]);
    const handleOpenTerms = useCallback(() => {
        modal.openModal("htmlModal", {
            textName: "terms"
        });
    }, []);
    const handleRegister = useCallback((values) => {
        const { name, phone, email, password, password_confirm, terms_accept } = values?.register;
        if (!name) {
            setErrorMessage("name");
        }
        else if (!validateNumber(phone)) {
            setErrorMessage("phone");
        }
        else if (!validateEmail(email)) {
            setErrorMessage("email");
        }
        else if (!password) {
            setErrorMessage("password");
        }
        else if (!validateLengthAtLeastNChars(password, 8)) {
            setErrorMessage("psw_error");
        }
        else if (password !== password_confirm) {
            setErrorMessage("password_match");
        }
        else if (!terms_accept) {
            setErrorMessage("error_create_account");
        }
        else {
            registerUser({ ...(values.register) });
        }
        if (isError) {
            setErrorMessage("error_create_account");
        }
    }, []);
    return (<>
      <Stack mt={16} mb={6} space={4} width="600px">
        <Text mb={6} textAlign="center" preset="h4">
          {translate("title.create_account")}
        </Text>
        <FormInput placeholder={translate("label.name")} name="register.name" defaultValue="" control={control}/>
        <Row space={4} width="full">
          <FormInput placeholder={translate("label.phone")} name="register.phone" defaultValue="" control={control} keyboardType="phone-pad"/>
          <FormInput placeholder={translate("label.email")} name="register.email" defaultValue="" control={control} keyboardType="email-address"/>
        </Row>
        <Row space={4}>
          <FormInput placeholder={translate("label.password")} name="register.password" defaultValue="" isSecure control={control} type="password"/>
          <FormInput placeholder={translate("label.confirm_password")} name="register.password_confirm" defaultValue="" isSecure control={control} type="password"/>
        </Row>
        <Row alignItems="center">
          <Box flex={1}>
            <LanguageSelect />
          </Box>
          <Box pl={4} flex={1}>
            <Controller control={control} name="register.terms_accept" render={({ field: { value, onChange } }) => (<Checkbox value={value} onChange={onChange} label={<Row alignItems="center">
                      <Text fontSize="sm">
                        {translate("text.i_accept")}{" "}
                      </Text>
                      <TextButton fontSize="sm" fontWeight="bold" title={translate("button.terms_conditions")} onPress={handleOpenTerms}/>
                    </Row>}/>)}/>
          </Box>
        </Row>
      </Stack>
      <Stack mt={4} safeAreaBottom space={4} width="420px">
        <Button label={translate("button.create_account")} onPress={handleSubmit(handleRegister)}/>
      </Stack>
    </>);
});
export default RegisterView;
