import { replace, size, toNumber } from "lodash";
export const validateNumber = (value) => {
    const phoneRegex = new RegExp(/^\+?\d+$/);
    return phoneRegex.test(value);
};
export const cleanNumberValue = (value) => toNumber(replace(value, /[^0-9]/g, ''));
export const emailIsCorrect = (email) => /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(email);
export const validateEmail = (email) => emailIsCorrect(email);
export const validateLengthAtLeastNChars = (value, n) => size(value) >= n;
export const cleanPhoneNumber = (value) => replace(value, /[^0-9+]/g, '');
