import { I18n } from "i18n-js";
import { keys } from "lodash";
const et = require('./locales/et.json');
const en = require('./locales/en.json');
const de = require('./locales/de.json');
const fi = require('./locales/fi.json');
const fr = require('./locales/fr.json');
const pl = require('./locales/pl.json');
const ru = require('./locales/ru.json');
const it = require('./locales/it.json');
const se = require('./locales/se.json');
import dayjs from "@huum/utils/date";
const locales = { et, en, de, fi, fr, it, pl, ru, se };
export const i18n = new I18n();
i18n.enableFallback = true;
i18n.translations = locales;
i18n.locale = 'en';
export const languageCodes = keys(locales);
export const changeLanguage = (languageCode) => {
    i18n.locale = languageCode;
    dayjs.locale(languageCode);
    dayjs.updateLocale(languageCode, {
        weekStart: 1,
    });
};
