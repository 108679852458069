import { deleteAccount } from "@huum/api/requests/auth";
import { translate } from "@huum/i18n";
import { useStores } from "@huum/store";
import { useMutation } from "@tanstack/react-query";
import { get } from "lodash";
import { useCallback } from "react";
export const useAccountDelete = () => {
    const { auth, modal } = useStores();
    const session = get(auth, "sessionHash", "");
    const { mutate } = useMutation({
        mutationFn: deleteAccount,
        onSuccess: async () => {
            auth.logout();
        }
    });
    const handleDeleteAccount = useCallback(() => {
        modal.openModal("confirmModal", {
            onConfirm: () => mutate({ session }),
            modalTitle: translate("text.delete_account_modal_title"),
            contentText: translate("text.delete_account_confirm"),
            confirmButtonLabel: translate("button.delete_account"),
        });
    }, []);
    return { handleDeleteAccount };
};
