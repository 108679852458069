import { Platform } from "react-native";
export const getHitSlop = (size) => {
    const value = size * (Platform.OS === "web" ? 4 : 2);
    return ({
        top: value,
        bottom: value,
        left: value,
        right: value,
    });
};
