import { fcmTokenInitiation, loginUserWithEmail } from "@huum/api/requests/auth";
import { navigate } from "@huum/services/NavigationService";
import { useStores } from "@huum/store";
import { useMutation } from "@tanstack/react-query";
import { isObject, keys, last, map } from "lodash";
import { useTheme } from "native-base";
import { useCallback } from "react";
export const useLogin = () => {
    const { auth, sauna } = useStores();
    const { mutate, data, isPending: isLoading } = useMutation({
        mutationFn: loginUserWithEmail,
    });
    const { config } = useTheme();
    const handleSuccess = useCallback((data) => {
        const saunIdIsList = isObject(data.sauna_id);
        const saunaId = saunIdIsList ? last(keys(data.sauna_id)) : data.sauna_id;
        auth.startSession({
            accountId: data.user_id,
            saunaId,
            settings: data.settings,
            sessionHash: data.session_hash,
            language: data.language,
            paymentDue: data.payment_end_date,
            saunaIsPrivate: data.isPrivate,
        });
        fcmTokenInitiation(data.session_hash);
        if (!saunaId) {
            return navigate('saunaConnect');
        }
        sauna.setSaunaIsPrivate(data.isPrivate);
        sauna.setPaymentEndDate(data.payment_end_date);
        sauna.setSaunaId(saunaId);
        const saunaList = saunIdIsList ? map(data.sauna_id, (value, key) => ({ saunaId: key, status: value })) : [{ saunaId, status: {} }];
        sauna.setSaunasList(saunaList);
        return navigate('controller');
    }, []);
    const login = useCallback((values) => {
        mutate({
            version: 3,
            config: config.name,
            ...values,
        }, {
            onSuccess: handleSuccess,
        });
    }, [mutate]);
    return { login, isLoading };
};
