import { fetchSaunaName, modifySaunaName } from "@huum/api/requests/sauna";
import { useStores } from "@huum/store";
import { useMutation, useQuery } from "@tanstack/react-query";
export const useSaunaName = (saunaId) => {
    const { sauna, auth } = useStores();
    const saunaIdToUse = saunaId || sauna.saunaId || "";
    const { mutate, isPending, isSuccess } = useMutation({
        mutationFn: modifySaunaName,
    });
    const { data: saunaName, isLoading, isError, error, } = useQuery({
        queryKey: ["saunaName", { saunaId: saunaIdToUse }],
        queryFn: () => fetchSaunaName({ saunaId: saunaIdToUse, session: auth.sessionHash }),
        enabled: !!saunaId,
    });
    const updateSaunaName = (name) => {
        mutate({
            saunaId: saunaIdToUse,
            session: auth.sessionHash,
            name,
        });
    };
    return {
        saunaName: saunaName?.name,
        isLoading,
        isError,
        error,
        updateSaunaName,
    };
};
