import Button from "@huum/components/atoms/button";
import { Text } from "@huum/components/atoms/text";
import { Stack } from "native-base";
import { request, PERMISSIONS, check, RESULTS } from "react-native-permissions";
import { useNetInfo } from "@react-native-community/netinfo";
import { NavigationHeader } from "@huum/components/molecules/header/navigation-header";
import { Platform } from "react-native";
import TextButton from "@huum/components/molecules/text-button";
import { translate } from "@huum/i18n";
import { useStores } from "@huum/store";
import { navigate } from "@huum/services/NavigationService";
import { Screen } from "@huum/components/organisms/screen";
import BulletText from "../components/BulletText";
const StartSmartConnectScreen = () => {
    const { toast } = useStores();
    const networkState = useNetInfo();
    const handleCancel = () => {
        navigate("controller");
    };
    const candleContinue = async () => {
        const locationPermission = Platform.OS === "ios"
            ? PERMISSIONS.IOS.LOCATION_WHEN_IN_USE
            : PERMISSIONS.ANDROID.ACCESS_FINE_LOCATION;
        let result = await check(locationPermission);
        if (result === RESULTS.DENIED) {
            result = await request(locationPermission);
        }
        if (result === RESULTS.GRANTED) {
            navigate("wifiConfigConnect");
            return;
        }
        toast.showToast({
            message: "Permission denied",
            type: "error",
        });
    };
    return (<>
      <NavigationHeader title={translate("title.connect_with_sauna")}/>
      <Screen>
        <Stack space={4}>
          <Text fontSize="lg" fontWeight="bold">
            {translate("text.solo_connect_instructions_title")}
          </Text>
          <Stack space={4} pl={2} my={4}>
            <BulletText text={translate("text.solo_connect_instruction_1")}/>
            <BulletText text={translate("text.solo_connect_instruction_2")}/>
            <BulletText text={translate("text.solo_connect_instruction_3")}/>
          </Stack>
          <Text fontSize="md" lineHeight="md" italic>{translate("text.solo_connect_disclaimer")}</Text>
        </Stack>
      </Screen>
      <Stack mt={8} space={4} px={4} pb={4} safeAreaBottom>
        <Button onPress={candleContinue} label={translate("button.next")}/>
        <TextButton alignSelf="center" title={translate("button.cancel")} onPress={handleCancel}/>
      </Stack>
    </>);
};
export default StartSmartConnectScreen;
