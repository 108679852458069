import { last } from "lodash";
import { types } from "mobx-state-tree";
/**
 * Model description here for TypeScript hints.
 */
export const ModalStoreModel = types
    .model("ModalStore")
    .props({
    modalShouldClose: types.optional(types.boolean, false),
    modalType: types.maybeNull(types.string),
    modalProps: types.maybeNull(types.frozen()),
    modalStash: types.optional(types.array(types.frozen()), []),
})
    .views((self) => ({})) // eslint-disable-line @typescript-eslint/no-unused-vars
    .actions((self) => ({
    openModal: (modalType, props) => {
        if (self.modalType) {
            self.modalStash.push({
                modalType: self.modalType,
                modalProps: self.modalProps,
            });
        }
        else if (props?.clearStash) {
            self.modalStash.clear();
        }
        self.modalShouldClose = false;
        self.modalType = modalType;
        self.modalProps = props;
    },
    setModalProps: (props) => {
        self.modalProps = {
            ...(self.modalProps || {}),
            ...props,
        };
    },
    popModal: () => {
        const previousModal = last(self.modalStash);
        self.modalType = previousModal?.modalType || null;
        self.modalProps = previousModal?.modalProps || null;
        ;
        return self.modalStash.pop();
    },
    closeModal: () => {
        self.modalShouldClose = true;
    },
    cleanModalState: (clearStash = false) => {
        self.modalType = null;
        self.modalProps = null;
        if (clearStash) {
            self.modalStash.clear();
        }
        self.modalShouldClose = false;
    }
})); // eslint-disable-line @typescript-eslint/no-unused-vars
export const createModalStoreDefaultModel = () => types.optional(ModalStoreModel, {});
