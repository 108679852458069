// Web implementation of AsyncStorage using localStorage
const AsyncStorage = {
    setItem: async (key, value) => {
        try {
            localStorage.setItem(key, value);
            return Promise.resolve();
        }
        catch (error) {
            return Promise.reject(error);
        }
    },
    getItem: async (key) => {
        try {
            const value = localStorage.getItem(key);
            return Promise.resolve(value);
        }
        catch (error) {
            return Promise.reject(error);
        }
    },
    removeItem: async (key) => {
        try {
            localStorage.removeItem(key);
            return Promise.resolve();
        }
        catch (error) {
            return Promise.reject(error);
        }
    },
    clear: async () => {
        try {
            localStorage.clear();
            return Promise.resolve();
        }
        catch (error) {
            return Promise.reject(error);
        }
    },
    getAllKeys: async () => {
        try {
            const keys = [];
            for (let i = 0; i < localStorage.length; i++) {
                const key = localStorage.key(i);
                if (key) {
                    keys.push(key);
                }
            }
            return Promise.resolve(keys);
        }
        catch (error) {
            return Promise.reject(error);
        }
    },
    multiGet: async (keys) => {
        try {
            const result = [];
            for (const key of keys) {
                const value = localStorage.getItem(key);
                result.push([key, value]);
            }
            return Promise.resolve(result);
        }
        catch (error) {
            return Promise.reject(error);
        }
    },
    multiSet: async (keyValuePairs) => {
        try {
            for (const [key, value] of keyValuePairs) {
                localStorage.setItem(key, value);
            }
            return Promise.resolve();
        }
        catch (error) {
            return Promise.reject(error);
        }
    },
    multiRemove: async (keys) => {
        try {
            for (const key of keys) {
                localStorage.removeItem(key);
            }
            return Promise.resolve();
        }
        catch (error) {
            return Promise.reject(error);
        }
    },
};
export { AsyncStorage };
