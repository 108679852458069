import React from 'react';
import { Text } from 'react-native-svg';
import { palette } from '@huum/theme/helpers';
const Labels = ({ x, bandwidth, data }) => data.map((value, index) => {
    const { sessionsCount } = value;
    return (<Text 
    // eslint-disable-next-line react/no-array-index-key
    key={`Weekly session count #${index}`} x={x(index) + bandwidth / 2} y={270} fontSize={14} fill={palette.primaryDark} alignmentBaseline="middle" textAnchor="middle" fontWeight="400">
        {Math.round(sessionsCount) || 0}
      </Text>);
});
export default Labels;
