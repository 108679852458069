import Button from "@huum/components/atoms/button";
import { Text } from "@huum/components/atoms/text";
import { translate } from "@huum/i18n";
import { useStores } from "@huum/store";
import { Center, Spinner, Stack } from "native-base";
import { useState } from "react";
const DeviceInactiveView = (props) => {
    const { iot } = useStores();
    const [isReconnecting, setIsReconnecting] = useState(false);
    const handleReconnect = async () => {
        setIsReconnecting(true);
        if (props.onReconnect) {
            const result = await props.onReconnect();
        }
        setIsReconnecting(false);
    };
    return (<Center flex={1} p={4}>
      <Stack mt={4} space={2}>
        {iot.connectedDeviceName && (<Text textAlign="center" preset="caption">{`Name ${iot.connectedDeviceName}`}</Text>)}
        <Text textAlign="center" preset="caption">{`ID ${iot.connectedDeviceId}`}</Text>
        {isReconnecting && <Spinner color="primaryDark"/>}
      </Stack>
      <Button onPress={handleReconnect} mt={8} label={translate("label.connect")}/>
    </Center>);
};
export default DeviceInactiveView;
