import { changePassword } from "@huum/api/requests/profile";
import { useStores } from "@huum/store";
import { useMutation } from "@tanstack/react-query";
import { get } from "lodash";
import { useCallback } from "react";
export const useChangePassword = () => {
    const { mutate, isError, isSuccess } = useMutation({
        mutationFn: changePassword,
    });
    const { auth } = useStores();
    const session = get(auth, "sessionHash", null);
    const getNewPassword = useCallback((values) => {
        mutate({ ...values, old_password: values.oldPassword, session });
    }, []);
    return { getNewPassword, isError, isSuccess };
};
