import React, { useCallback } from "react";
import { MainHeader } from "@huum/components/molecules/header/main-header";
import MenuListItem from "@huum/components/molecules/menu-list-item";
import { i18n, translate } from "@huum/i18n";
import { get, map, toNumber } from "lodash";
import SupportIconButton from "@huum/components/organisms/support-icon-button";
import { Box, Stack, useTheme } from "native-base";
import { Screen } from "@huum/components/organisms/screen";
import TextButton from "@huum/components/molecules/text-button";
import { useThemedStyles } from "@huum/hooks/useThemedStyles";
import { getMenuViewStyles } from "./styles";
import { useStores } from "@huum/store";
import { navigate } from "@huum/services/NavigationService";
import dayjs from "@huum/utils/date";
import { observer } from "mobx-react-lite";
import { openLinkingUrl } from "@huum/utils/linking";
const MenuView = observer(() => {
    const { auth, modal, sauna } = useStores();
    const saunaId = get(sauna, "saunaId", "");
    const paymentDue = get(sauna, "paymentDue", null);
    const daysUntilPayment = dayjs(paymentDue).diff(dayjs(), 'days');
    const { config } = useTheme();
    const SettingsConfig = [
        {
            key: "profile_and_id",
            title: translate("button.profile_and_id"),
            onPress: () => navigate("profile"),
        },
        {
            key: "statistics",
            title: translate("button.statistics"),
            onPress: () => navigate("statistics"),
            hidden: toNumber(saunaId) > 500000,
        },
        {
            key: "safety",
            title: translate("button.safety"),
            onPress: () => navigate("safetySettings"),
        },
        {
            key: "payment",
            title: `${translate("button.payment")}`,
            extraTitle: paymentDue && `${translate("label.days_left", { days: daysUntilPayment })}`,
            onPress: () => navigate("paymentSettings"),
            hidden: toNumber(saunaId) < 120000 || toNumber(saunaId) > 200000
        },
        {
            key: "payment",
            title: `${translate("text.pay_now")}`,
            onPress: () => navigate("paymentSettings"),
            hidden: toNumber(saunaId) < 120000 || toNumber(saunaId) > 200000
        },
    ];
    const MenuList = useCallback(() => {
        return map(SettingsConfig, (item) => {
            if (item.hidden)
                return null;
            return (<MenuListItem key={`${item?.key}-${auth.language}`} title={item?.title} extraTitle={item?.extraTitle || ""} onPress={item?.onPress}/>);
        });
    }, [auth.language]);
    const styles = useThemedStyles(getMenuViewStyles);
    const handleOpenTerms = useCallback(() => {
        modal.openModal("htmlModal", {
            textName: "terms"
        });
    }, []);
    const handleOpenPrivacy = useCallback(() => {
        modal.openModal("htmlModal", {
            textName: "privacy"
        });
    }, []);
    return (<>
      <MainHeader withBackNavigation title={`ID ${saunaId}`}/>
      <Screen key={`settings-${auth.language}`}>
        <Box mt={12}/>
        {MenuList()}
        <Box mt={6}/>
        <SupportIconButton onPress={() => openLinkingUrl(config?.support?.[auth.language || i18n.locale || "en"])} onIconPress={() => openLinkingUrl(config?.contact?.[auth.language || i18n.locale || "en"])}/>
        <Stack space={8} alignItems="center" mt={8}>
          <TextButton preset="subtitle1" fontWeight="bold" title={translate("button.terms_conditions")} style={styles.dimmedText} onPress={handleOpenTerms}/>
          <TextButton preset="subtitle1" fontWeight="bold" title={translate("button.privacy_policy")} style={styles.dimmedText} onPress={handleOpenPrivacy}/>
          <TextButton preset="subtitle1" fontWeight="bold" title={translate("button.log_out")} style={styles.dimmedText} onPress={() => auth.logout()}/>


          <TextButton preset="subtitle2" title={translate("button.toggle_fullscreen")} style={styles.dimmedText} onPress={() => {
            var el = document.documentElement, rfs = el.requestFullscreen
                || el.webkitRequestFullScreen
                || el.mozRequestFullScreen
                || el.msRequestFullscreen;
            rfs.call(el);
        }}/>
        </Stack>
      </Screen>
    </>);
});
export default MenuView;
