import { reduce, toNumber, omit, values, clone, union, range, } from 'lodash';
import dayjs from "@huum/utils/date";
export const toggleRepeatingDayHelper = (indexOfDay, weekdays) => {
    const stateOfDay = weekdays[indexOfDay];
    const output = clone(weekdays);
    output[indexOfDay] = stateOfDay === 0 ? 1 : 0;
    return output;
};
export const formatCalendarEvent = (currentUserId) => (event) => {
    const base = omit(event, ['isRecurring', 'private', 'occurrences']);
    return {
        ...base,
        isPrivate: toNumber(event.private) === 1,
        isRepeating: toNumber(event.isRecurring) === 1,
        isMine: event.user?.user_id === currentUserId,
        ownerName: event.user.name,
        ownerEmail: event.user.email,
    };
};
const getOccurrenceTimes = (event, occurrence) => {
    const ocStartTime = dayjs.unix(occurrence.startTime);
    const eventStartTime = dayjs.unix(event.startTime);
    // check if event time is before dailight saving time
    const timeDifference = eventStartTime.diff(dayjs(eventStartTime).hour(ocStartTime.hour()).minute(ocStartTime.minute()), 'hour');
    if (timeDifference !== 0) {
        return {
            startTime: dayjs.unix(occurrence.startTime).add(timeDifference, 'hour').unix(),
            endTime: dayjs.unix(occurrence.endTime).add(timeDifference, 'hour').unix(),
        };
    }
    return {
        startTime: occurrence.startTime,
        endTime: occurrence.endTime,
    };
};
export const selectIntoBookingListItems = (events, accountId) => {
    const formatEvent = formatCalendarEvent(accountId);
    const raw = reduce(events, (result, value) => {
        const formattedEvent = formatEvent(value);
        if (!toNumber(value.isRecurring)) {
            return {
                ...result,
                [`${value.id}-${value.startTime}-${value.endTime}`]: formattedEvent,
            };
        }
        const formattedOccurrences = reduce(value.occurrences, (result, oc, key) => ({
            ...result,
            [`${value.id}-${oc.startTime}-${oc.endTime}`]: formatEvent({
                ...value,
                ...oc,
                ...(getOccurrenceTimes(value, oc))
            }),
        }), {});
        const formattedExceptions = reduce(value.bookingExceptions, (result, ex, key) => {
            if (toNumber(ex.isCancelled) === 1) {
                return result;
            }
            return {
                ...result,
                [`${value.id}-${ex.startTime}-${ex.endTime}`]: formatEvent({
                    ...value,
                    originalTargetTemperature: value.targetTemperature,
                    ...ex,
                }),
            };
        }, {});
        return {
            ...result,
            ...formattedOccurrences,
            ...formattedExceptions,
        };
    }, {});
    const bookings = values(raw).sort((a, b) => a.startTime - b.startTime);
    return bookings;
};
export const getUnAvailableHours = (bookingList, maxHeatingTime) => {
    return bookingList.reduce((acc, booking) => {
        const startDate = dayjs.unix(booking.startTime);
        const endDate = dayjs.unix(booking.endTime);
        const eventDurationInHours = endDate.diff(startDate, 'hours');
        const date = startDate.format('YYYY-MM-DD');
        let hours = range(startDate.hour(), endDate.hour());
        if (maxHeatingTime === 12 && eventDurationInHours >= 12) {
            const bufferHours = range(endDate.hour(), endDate.hour() + 6);
            hours = [...hours, ...bufferHours];
        }
        if (!acc[date]) {
            acc[date] = hours;
        }
        else {
            acc[date] = union(acc[date], hours);
        }
        return acc;
    }, {});
};
