/* eslint-disable no-param-reassign */
import { values, map, forEach, compact, size, reduce, get, find, round } from "lodash";
import { translate } from "@huum/i18n";
import { formatDate } from "../date";
import dayjs from "@huum/utils/date";
import { getTemperatureUnit, getTemperatureValue } from "./sauna-temperature-helpers";
export const selectTemperatures = (state) => state.statistics.temperatures;
// export const getLoading = (state) => state.statistics.loading;
/*
 General data structure from API:

[
  { changeTime: 1525859200,
  temperature: '56', // celsius
  targetTemperature: '80', // celsius
  isHeating: '0' },

  ...
]
 */
export const timeDiff = (diff) => {
    const hours = parseInt(dayjs.duration(diff).asHours(), 10);
    const minutes = parseInt(dayjs.duration(diff % (1000 * 60 * 60)).asMinutes(), 10);
    let timeString = "";
    if (hours) {
        timeString += `${hours}h`;
    }
    if (hours && minutes) {
        timeString += " ";
    }
    if (minutes) {
        timeString += `${minutes}min`;
    }
    return diff > 0 ? timeString : "0min";
};
const findSessionStatistics = (sensorReadings) => {
    // assume data is sorted
    let sessions = [];
    const defaultSession = {
        from: null,
        to: null,
        targetTemperature: 0,
        durationMillSec: 0,
        timeHeatingMillSec: 0,
        tempIncreaseDegrees: 0,
        duration: "",
        timeHeating: "",
        tempIncreasePerTenMinutes: 0,
        averageTemperature: 0,
    };
    let currentlyMeasuredSession = { ...defaultSession };
    let heatingMillSec = 0;
    let tempIncrease = 0;
    let totalDegreesTimesTime = 0;
    const getTime = (reading) => {
        if (reading) {
            return new Date(reading.changeTime * 1000);
        }
        return null;
    };
    const resetSession = () => {
        tempIncrease = 0;
        heatingMillSec = 0;
        totalDegreesTimesTime = 0;
        currentlyMeasuredSession = { ...defaultSession };
    };
    const addSession = (session, times, temperatures) => {
        const { previous: previousTime, current: currentTime } = times;
        const { previous: previousTemp, current: currentTemp } = temperatures;
        // add our end time for the session
        session.to = previousTime;
        // take off the last heating time added, last temp added and last degrees added
        heatingMillSec -= currentTime - previousTime;
        tempIncrease -= currentTemp - previousTemp;
        totalDegreesTimesTime -= previousTemp * (currentTime - previousTime);
        // get the duration
        session.durationMillSec = session.to - session.from;
        session.duration = timeDiff(session.durationMillSec);
        // add heating time
        session.timeHeatingMillSec =
            session.durationMillSec > 0 ? heatingMillSec : 0;
        session.timeHeating = timeDiff(heatingMillSec);
        // add temp increase per 10 minutes
        // (1000 mille per second, 60 second per minute, per 10 minutes)
        session.tempIncreaseDegrees = tempIncrease;
        session.tempIncreasePerTenMinutes =
            tempIncrease / (heatingMillSec / (1000 * 60 * 10));
        // add average temperature ( = (total degrees * total time) / total time)
        if (session.durationMillSec > 0) {
            session.averageTemperature =
                totalDegreesTimesTime / (session.to - session.from);
        }
        else {
            session.averageTemperature = totalDegreesTimesTime;
        }
        // push to array of sessions
        sessions?.push(session);
    };
    forEach(sensorReadings, (currReading, i) => {
        const prevReading = i > 0 ? sensorReadings[i - 1] : {};
        const nextReading = sensorReadings[i + 1];
        const { isHeating } = i < sensorReadings.length ? currReading : {};
        const times = {
            current: getTime(currReading),
            previous: getTime(prevReading),
            next: getTime(nextReading),
        };
        const temperatures = {
            current: currReading.temperature,
            previous: prevReading.temperature,
            next: nextReading && nextReading.temperature
                ? nextReading.temperature
                : null,
        };
        switch (isHeating) {
            case "1":
                if (!currentlyMeasuredSession.from) {
                    currentlyMeasuredSession.from = times.current;
                    const { targetTemperature } = currReading;
                    currentlyMeasuredSession.targetTemperature = parseInt(targetTemperature, 10);
                }
                if (currReading.temperature < currReading.targetTemperature) {
                    heatingMillSec += times.next - times.current;
                    tempIncrease += temperatures.next - temperatures.current;
                }
                totalDegreesTimesTime +=
                    temperatures.current *
                        ((get(nextReading, "changeTime") - currReading.changeTime) * 1000);
                // treat a new target as a new session
                if (prevReading.targetTemperature !== currReading.targetTemperature &&
                    prevReading.isHeating === "1") {
                    addSession(currentlyMeasuredSession, times, temperatures);
                    resetSession();
                    currentlyMeasuredSession.from = times.previous;
                    const { targetTemperature } = currReading;
                    currentlyMeasuredSession.targetTemperature = parseInt(targetTemperature, 10);
                    if (prevReading.temperature < prevReading.targetTemperature) {
                        heatingMillSec += times.current - times.previous;
                        tempIncrease += temperatures.current - temperatures.previous;
                    }
                }
                break;
            default:
                // '0' or null case
                if (currentlyMeasuredSession.from) {
                    addSession(currentlyMeasuredSession, times, temperatures);
                    resetSession();
                }
                // if the sauna is off and we haven't yet
                // started a session, then do nothing
                break;
        }
    });
    // finally, return our sessions for the month
    return sessions;
};
export const filterSessionsByDate = (sessions, fromDate, toDate) => {
    const filteredSessions = sessions.filter((session) => dayjs(session.from).isSameOrAfter(fromDate) &&
        dayjs(session.to).isSameOrBefore(toDate));
    return filteredSessions;
};
export const findFigures = (sessionsData) => {
    // filter out sessions shorter than 10 minutes, to prevent silly averages;
    const sessionData = sessionsData.filter((session) => !(session.durationMillSec < 10 * 60 * 1000));
    const length = size(sessionData); // every JS array object comes with a length property;
    let totalUsage = 0; // milliseconds
    let totalHeating = 0; // milliseconds
    let totalTargetTemp = 0;
    let totalTempRise = 0;
    map(sessionData, (dataItem) => {
        const { targetTemperature, durationMillSec, timeHeatingMillSec, tempIncreasePerTenMinutes, } = dataItem;
        if (durationMillSec)
            totalUsage += durationMillSec;
        if (timeHeatingMillSec)
            totalHeating += timeHeatingMillSec;
        if (targetTemperature)
            totalTargetTemp += parseInt(targetTemperature, 10);
        if (tempIncreasePerTenMinutes)
            totalTempRise += tempIncreasePerTenMinutes;
    });
    // milliseconds version
    const averageUsage = round(totalUsage / length); // milliseconds;
    const averageHeating = round(totalHeating / length); // milliseconds;
    // string version
    const totalUsageTime = timeDiff(totalUsage);
    const averageUsageTime = timeDiff(totalUsage / length);
    const totalHeatingTime = timeDiff(totalHeating);
    const averageHeatingTime = timeDiff(totalHeating / length);
    // degrees
    const averageTargetTemp = round(totalTargetTemp / length, 1);
    const averageTempRise = round(totalTempRise / length, 1);
    return {
        // milliseconds (float)
        totalUsage,
        averageUsage,
        totalHeating,
        averageHeating,
        // time (string)
        totalUsageTime,
        averageUsageTime,
        totalHeatingTime,
        averageHeatingTime,
        // degrees celsius (float)
        averageTargetTemp,
        averageTempRise,
    };
};
export const getSessionsCount = (statistics, selectedDate) => {
    const sessionStatistics = findSessionStatistics(statistics);
    const today = dayjs(selectedDate);
    const endOfToday = dayjs(today).endOf("day");
    const thisMonday = dayjs(today).startOf("week");
    const thisSunday = dayjs(today).endOf("week");
    const startOfMonth = dayjs(today).startOf("month");
    const endOfMonth = dayjs(today).endOf("month");
    const month = size(filterSessionsByDate(sessionStatistics, startOfMonth, endOfMonth));
    const week = size(filterSessionsByDate(sessionStatistics, thisMonday, thisSunday));
    const day = size(filterSessionsByDate(sessionStatistics, today, endOfToday));
    return { month, week, day };
};
const selectFiguresData = (statistics, selectedDate, periodKey) => {
    const today = dayjs(selectedDate);
    switch (periodKey) {
        case "day":
            const endOfToday = dayjs(today).endOf("day");
            return findFigures(filterSessionsByDate(statistics, today, endOfToday));
        case "week":
            const thisMonday = dayjs(today).startOf("week");
            const thisSunday = dayjs(today).endOf("week");
            return findFigures(filterSessionsByDate(statistics, thisMonday, thisSunday));
        case "month":
            const startOfMonth = dayjs(today).startOf("month");
            const endOfMonth = dayjs(today).endOf("month");
            return findFigures(filterSessionsByDate(statistics, startOfMonth, endOfMonth));
        default:
            return findFigures(statistics);
    }
};
export const selectStatisticsData = (statistics, selectedDate, periodKey, temperatureUnit) => {
    const sessionStatistics = findSessionStatistics(statistics);
    const figuresData = selectFiguresData(sessionStatistics, selectedDate, periodKey);
    const statisticsData = {
        averageTemperatureRise: {
            measure: translate("text.average_temperature_rise"),
            unit: "degrees",
            icon: require('@huum/assets/legacy/heating_icon.png'),
            value: `${getTemperatureValue(figuresData.averageTempRise || 0, temperatureUnit)} ${getTemperatureUnit(temperatureUnit)}`,
        },
        averageTarget: {
            measure: translate("text.average_target"),
            unit: "degrees",
            icon: require('@huum/assets/legacy/heating_icon.png'),
            value: `${getTemperatureValue(figuresData.averageTargetTemp || 0, temperatureUnit)} ${getTemperatureUnit(temperatureUnit)}`,
        },
        // averageSessionTime: {
        //   measure: I18n.t("label.average_session_time"),
        //   results: [
        //     dailyFigures.averageUsageTime,
        //     weeklyFigures.averageUsageTime,
        //     monthlyFigures.averageUsageTime,
        //   ],
        // },
        // averageHeatingTime: {
        //   measure: I18n.t("label.average_heating_time"),
        //   results: [
        //     dailyFigures.averageHeatingTime,
        //     weeklyFigures.averageHeatingTime,
        //     monthlyFigures.averageHeatingTime,
        //   ],
        // },
        totalSessionTime: {
            measure: translate("text.total_session_time"),
            value: figuresData.totalUsageTime,
        },
        totalHeatingTime: {
            measure: translate("text.total_heating_time"),
            value: figuresData.totalHeatingTime,
        },
    };
    return values(statisticsData);
};
export const selectStatisticsTemperatureMonths = (selectedDate) => {
    const includeLastMonth = dayjs(selectedDate)
        .startOf("week")
        .isBefore(dayjs(selectedDate), "month");
    const includeNextMonth = dayjs(selectedDate)
        .endOf("week")
        .isAfter(dayjs(selectedDate), "month");
    return compact([
        includeLastMonth &&
            formatDate(dayjs(selectedDate).subtract(1, "month"), "YYYY-MM"),
        formatDate(dayjs(selectedDate), "YYYY-MM"),
        includeNextMonth && formatDate(dayjs(selectedDate).add(1, "month"), "YYYY-MM"),
    ]);
};
export const selectDailyReadings = (sensorReadings, selectedDate) => {
    const today = dayjs(selectedDate);
    const endOfToday = dayjs(today).endOf("day");
    const dailyReadings = sensorReadings?.filter((reading) => {
        const { changeTime } = reading;
        const time = new Date(changeTime * 1000);
        return (dayjs(time).isSameOrAfter(today) &&
            dayjs(time).isSameOrBefore(endOfToday));
    });
    return dailyReadings;
};
export const selectWeeklySessions = (statistics, selectedDate) => {
    const sessionStatistics = findSessionStatistics(statistics);
    const today = dayjs(selectedDate);
    const thisMonday = dayjs(today).startOf("week");
    const thisSunday = dayjs(today).endOf("week");
    const weeklySessions = filterSessionsByDate(sessionStatistics, thisMonday, thisSunday);
    const weeklyReadings = statistics?.filter((reading) => {
        const { changeTime } = reading;
        const time = new Date(changeTime * 1000);
        return (dayjs(time).isSameOrAfter(thisMonday) &&
            dayjs(time).isSameOrBefore(thisSunday));
    });
    return { weeklySessions, weeklyReadings };
};
export const selectMonthlySessions = (statistics, selectedDate) => {
    const sessionStatistics = findSessionStatistics(statistics);
    const today = dayjs(selectedDate);
    const firstDate = dayjs(today).startOf("month");
    const lastDate = dayjs(today).endOf("month");
    const monthlySessions = filterSessionsByDate(sessionStatistics, firstDate, lastDate);
    // const sessionsCount = size(monthlySessions);
    // const dailyDurationTotals = reduce(
    //   monthlySessions,
    //   (result, value) => {
    //     const dayKey = formatDate(dayjs(value.from), "YYYY-MM-DD");
    //     const dayResult = result[dayKey] || 0;
    //     return {
    //       ...result,
    //       [dayKey]: dayResult + value.durationMillSec,
    //     };
    //   },
    //   {}
    // );
    const dailyCountTotals = reduce(monthlySessions, (result, value) => {
        const dayKey = formatDate(dayjs(value.from), "YYYY-MM-DD");
        const existingSession = find(result, r => r.date === dayKey);
        if (existingSession) {
            existingSession.sessionCount += 1;
            return map(result, item => item.date === existingSession.date ? existingSession : item);
        }
        return [...result, { date: dayKey, sessionCount: 1 }];
    }, []);
    return {
        // count: sessionsCount,
        dailySessionCount: dailyCountTotals,
    };
};
