import { fetchSaunaConfig, modifySaunaConfig } from "@huum/api/requests/sauna";
import { useStores } from "@huum/store";
import { useMutation, useQuery } from "@tanstack/react-query";
export const useSaunaConfig = (params) => {
    const { saunaId } = params;
    const { auth } = useStores();
    const { mutate, isPending, isSuccess } = useMutation({
        mutationFn: modifySaunaConfig,
    });
    const { data: saunaConfig, isLoading, isError, error, } = useQuery({
        queryKey: ["saunaConfig", { saunaId }],
        queryFn: () => fetchSaunaConfig({ saunaId, session: auth.sessionHash }),
        enabled: !!saunaId,
    });
    const updateSaunaConfig = (data) => {
        mutate({
            ...data,
            saunaId: saunaId,
            session: auth.sessionHash,
        });
    };
    return {
        saunaConfig,
        isLoading,
        isError,
        error,
        updateSaunaConfig,
    };
};
