import { round, toNumber } from "lodash";
import { useCallback, useEffect, useState } from "react";
import { useStores } from "@huum/store";
import { translate } from "@huum/i18n";
export const WHITE_COLOR = "#FFFFFF";
const HUUM_SERVICE_UUID = 0xffe0;
const HUUM_CHARACTERISTIC_UUID = 0xffe1;
export const useLedController = () => {
    const { iot } = useStores();
    const [devices, setDevices] = useState([]);
    const [isScanningDevices, setIsScanningDevices] = useState(false);
    const [isPairingRemote, setIsPairingRemote] = useState(false);
    const [dataMessage, setDataMessage] = useState();
    const [connectedDeviceGatt, setConnectedDeviceGatt] = useState(null);
    const [connectedCharacteristic, setConnectedCharacteristic] = useState(null);
    const [selectedColor, setSelectedColor] = useState(WHITE_COLOR);
    const [activeBrightness, setActiveBrightness] = useState(100);
    const [activeSpeed, setActiveSpeed] = useState(1);
    const [selectedEffect, setSelectedEffect] = useState(0);
    const isRgbwEnabled = iot.useRgbw;
    const activeChannelSuffix = iot.activeChannel ? `_ch${iot.activeChannel - 1}` : "";
    const [messageBuffer, setMessageBuffer] = useState('');
    useEffect(() => {
        if (dataMessage) {
            setMessageBuffer(prevBuffer => {
                const newBuffer = prevBuffer + dataMessage;
                return parseAndProcessMessages(newBuffer);
            });
        }
    }, [dataMessage]);
    const parseAndProcessMessages = (buffer) => {
        let remainingBuffer = buffer;
        let parsedAny = false;
        while (remainingBuffer.includes('}')) {
            const endIndex = remainingBuffer.indexOf('}') + 1;
            const possibleMessage = remainingBuffer.slice(0, endIndex);
            try {
                const message = JSON.parse(possibleMessage);
                processMessage(message);
                remainingBuffer = remainingBuffer.slice(endIndex);
                parsedAny = true;
            }
            catch (e) {
                // If parsing fails, it might be an incomplete message. Break the loop.
                break;
            }
        }
        return remainingBuffer;
    };
    const processMessage = useCallback((message) => {
        if (message?.c) {
            setSelectedColor(message.c);
        }
        if (message?.b) {
            const calculatedBrightness = round(toNumber(message.b) * 100 / 255);
            setActiveBrightness(calculatedBrightness);
        }
        if (message?.s) {
            const calculatedSpeed = round(toNumber(message.s) / 100, 1);
            setActiveSpeed(calculatedSpeed);
        }
        if (message?.e) {
            setSelectedEffect(message.e);
        }
        if (message?.name) {
            iot.setConnectedDeviceName(message.name);
        }
        if (message.pair === "stop" || message.pair === "success") {
            setIsPairingRemote(false);
        }
    }, [iot, setSelectedColor, setActiveBrightness, setActiveSpeed, setSelectedEffect, setIsPairingRemote]);
    // Replace the auto-connection useEffect with this implementation
    useEffect(() => {
        // Don't attempt auto-connection on web - it's not allowed by the Web Bluetooth API
        // Instead, store the last connected device info and provide a reconnect button
        // Clean up function for disconnecting when component unmounts
        return () => {
            if (connectedDeviceGatt) {
                disconnectDevice();
            }
        };
    }, []);
    const handleCharacteristicValueChanged = useCallback((event) => {
        const target = event.target;
        const value = target.value;
        if (value) {
            const decodedValue = new TextDecoder().decode(value).replace(/[{}]/g, "");
            setDataMessage((current) => (current ? current + decodedValue : decodedValue));
        }
    }, []);
    const writeToDevice = useCallback(async (json) => {
        if (!connectedCharacteristic)
            return;
        const encoder = new TextEncoder();
        const value = encoder.encode(json);
        return connectedCharacteristic.writeValue(value);
    }, [connectedCharacteristic]);
    const scanDevices = async () => {
        setIsScanningDevices(true);
        try {
            const device = await navigator.bluetooth.requestDevice({
                filters: [
                    { services: [HUUM_SERVICE_UUID] },
                    { namePrefix: 'HUUM' }
                ],
                optionalServices: [HUUM_SERVICE_UUID]
            });
            console.log('Selected device:', device);
            setDevices([device]);
            const connected = await connectDevice(device);
            if (connected) {
                iot.setConnectedDeviceId(device.id, device.name);
            }
        }
        catch (error) {
            console.error('Error scanning for devices:', error);
        }
        finally {
            setIsScanningDevices(false);
        }
    };
    const connectDevice = async (device) => {
        try {
            if (!device.gatt) {
                console.error('Device GATT not available');
                return false;
            }
            const server = await device.gatt.connect();
            if (server) {
                setConnectedDeviceGatt(server);
                // Set up the GATT connection
                const service = await server.getPrimaryService(HUUM_SERVICE_UUID);
                const characteristic = await service.getCharacteristic(HUUM_CHARACTERISTIC_UUID);
                setConnectedCharacteristic(characteristic);
                await characteristic.startNotifications();
                characteristic.addEventListener('characteristicvaluechanged', handleCharacteristicValueChanged);
                // Get initial state
                getLedState();
                return true;
            }
        }
        catch (error) {
            console.error('Error connecting to device:', error);
            if (device.name) {
                alert(translate("error.ble_device_connecton_error", { name: device.name }));
            }
        }
        return false;
    };
    const disconnectDevice = async () => {
        if (connectedCharacteristic) {
            try {
                await connectedCharacteristic.stopNotifications();
                connectedCharacteristic.removeEventListener('characteristicvaluechanged', handleCharacteristicValueChanged);
            }
            catch (error) {
                console.error('Error stopping notifications:', error);
            }
        }
        if (connectedDeviceGatt) {
            connectedDeviceGatt.disconnect();
        }
        setConnectedDeviceGatt(null);
        setConnectedCharacteristic(null);
        iot.setConnectedDeviceId(undefined);
    };
    const getLedState = async () => {
        await writeToDevice(`{"get": ["s", "c", "b", "e", "name"]}\n`);
    };
    const setLedColor = useCallback((hex, ignoreDevice) => {
        setSelectedColor(hex);
        if (ignoreDevice)
            return;
        console.log(hex, activeChannelSuffix);
        writeToDevice(`{"c${activeChannelSuffix}": "${hex}", "e": -1}\n`);
    }, [writeToDevice, activeChannelSuffix]);
    const setBrightness = (brightness) => {
        const calculatedBrightness = 255 * brightness / 100;
        writeToDevice(`{"b${activeChannelSuffix}": ${calculatedBrightness}}\n`);
    };
    const setEffect = async (name, slot, effect) => {
        setSelectedEffect(slot);
        writeToDevice(`{"e${activeChannelSuffix}": ${slot}}\n`);
    };
    const setSpeed = (speed) => {
        const speedValue = speed * 100;
        writeToDevice(`{"speed${activeChannelSuffix}": ${speedValue}}\n`);
    };
    const setRgbwType = (isRgbw) => {
        iot.setRgbwType(isRgbw);
        writeToDevice(`{"rgbw": ${isRgbw ? 1 : 0}}\n`);
    };
    const setRemotePairing = (isPairing) => {
        setIsPairingRemote(isPairing);
        writeToDevice(`{"cmd": "${!isPairing ? "stop_pair" : "start_pair"}"}\n`);
    };
    const setLedDeviceName = (name) => {
        writeToDevice(`{"name": "${name}"}\n`);
    };
    const resetDeviceToFactorySettings = () => {
        writeToDevice(`{"factory_reset": 314159265}\n`);
    };
    const reconnectToLastDevice = useCallback(async () => {
        // This must be called from a user gesture (button click)
        const lastDeviceId = iot.connectedDeviceId;
        const lastDeviceName = iot.connectedDeviceName;
        if (!lastDeviceId || !lastDeviceName) {
            console.log('No previously connected device found');
            return false;
        }
        try {
            setIsScanningDevices(true);
            // We need to request the device again - can't connect directly by ID due to security restrictions
            const device = await navigator.bluetooth.requestDevice({
                filters: [
                    { services: [HUUM_SERVICE_UUID] },
                    { namePrefix: 'HUUM' }
                ],
                optionalServices: [HUUM_SERVICE_UUID]
            });
            // Check if this is the same device we were trying to reconnect to
            if (device.id === lastDeviceId) {
                const connected = await connectDevice(device);
                return connected;
            }
            else {
                console.log('Connected to a different device than the last one');
                return await connectDevice(device);
            }
        }
        catch (error) {
            console.error('Error reconnecting to device:', error);
            return false;
        }
        finally {
            setIsScanningDevices(false);
        }
    }, [iot.connectedDeviceId, iot.connectedDeviceName, connectDevice]);
    const initiateManualScan = () => {
        // Clear any previous connection state
        if (connectedDeviceGatt) {
            disconnectDevice();
        }
        // Start scanning for new devices
        scanDevices();
    };
    const setAudioTrack = useCallback((trackId) => {
        writeToDevice(`{"audio_track": ${trackId}}\n`);
    }, [writeToDevice]);
    const setAudioVolume = useCallback((volume) => {
        writeToDevice(`{"volume": ${Math.min(100, volume)}}\n`);
    }, [writeToDevice]);
    return {
        isScanningDevices,
        isPairingRemote,
        connectedDevice: connectedDeviceGatt?.device,
        devices,
        connectionIsActive: !!connectedDeviceGatt,
        isRgbwEnabled,
        selectedColor,
        selectedEffect,
        activeBrightness,
        activeSpeed,
        rgbeChannelsCount: iot.rgbwChannelsCount,
        disconnectDevice,
        scanDevices,
        connectDevice,
        setLedColor,
        getLedState,
        setBrightness,
        setSpeed,
        setEffect,
        setRgbwType,
        setRemotePairing,
        setLedDeviceName,
        resetDeviceToFactorySettings,
        reconnectToLastDevice,
        setAudioTrack,
        setAudioVolume,
    };
};
