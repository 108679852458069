import { palette } from '@huum/theme/helpers/palette';
import { StyleSheet } from "react-native";
export const getMenuViewStyles = (theme) => {
    const { colors, space } = theme;
    return StyleSheet.create({
        dimmedText: {
            color: palette.dimmedText,
        },
    });
};
