import FlowContainer from "@huum/components/organisms/flow-container";
import { useFocusEffect, useRoute } from "@react-navigation/native";
import { AvoidSoftInput } from 'react-native-avoid-softinput';
import { useCallback } from "react";
import ConnectSaunaView from "./views/connect-sauna";
import SaunaActivatedView from "./views/sauna-activated";
import { useStores } from "@huum/store";
import { get } from "lodash";
const SaunaConnectingScreen = () => {
    const { sauna } = useStores();
    const { params } = useRoute();
    const mode = get(params, 'mode');
    const onFocusEffect = useCallback(() => {
        AvoidSoftInput.setShouldMimicIOSBehavior(true);
        AvoidSoftInput.setEnabled(true);
        return () => {
            AvoidSoftInput.setEnabled(false);
            AvoidSoftInput.setShouldMimicIOSBehavior(false);
        };
    }, []);
    useFocusEffect(onFocusEffect);
    const components = {
        connectSauna: ConnectSaunaView,
        saunaActivated: SaunaActivatedView,
    };
    return (<FlowContainer canGoBack={!!sauna.saunaId && mode === "change"} initialView="connectSauna" components={components}/>);
};
export default SaunaConnectingScreen;
