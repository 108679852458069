import api from "@huum/services/api";
import { selectStatisticsTemperatureMonths } from "@huum/utils/helpers/statistics-selectors";
import { flatten, map } from "lodash";
export async function getTemperatures(data) {
    const { session, monthData, saunaId } = data;
    const selectedMonths = selectStatisticsTemperatureMonths(monthData);
    const response = [];
    for (let i = 0; i < selectedMonths.length; i++) {
        const month = selectedMonths[i];
        const result = await api.huum.get("/action/get_temperatures", {
            version: 3,
            session,
            month,
            saunaId,
        });
        response.push(result);
    }
    const listitems = flatten(map(response, (item) => item.data));
    return listitems;
}
