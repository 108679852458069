import FlowContainer from "@huum/components/organisms/flow-container";
import LandingView from "./views/landing";
import LoginView from "./views/login";
import RegisterView from "./views/register";
import PasswordResetView from "./views/password-reset";
const components = {
    landing: LandingView,
    login: LoginView,
    register: RegisterView,
    passwordReset: PasswordResetView,
};
const OnboardingScreen = () => {
    return (<FlowContainer initialView="landing" components={components}/>);
};
export default OnboardingScreen;
