import api from "@huum/services/api";
import { getGeneralApiProblem } from "@huum/services/api/api-problem";
import dayjs from "@huum/utils/date";
export async function getBookingsList(props) {
    const { session, date, saunaId } = props;
    const response = await api.huum.get("/booking/get_bookings", {
        version: 3,
        session,
        month: dayjs(date).format('YYYY-MM'),
        saunaId,
    });
    if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem)
            throw problem;
    }
    return response.data;
}
export async function saveBooking(body) {
    const response = await api.huum.post("/booking/save_booking", {
        version: 3,
        ...body
    });
    if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem)
            throw problem;
    }
    return response.data;
}
export async function saveBookingException(body) {
    const response = await api.huum.post("/booking/save_booking_exception", {
        version: 3,
        ...body
    });
    if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem)
            throw problem;
    }
    return response.data;
}
