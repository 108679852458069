import React from "react";
import { Box, Center } from "native-base";
import TextButton from "../text-button";
const hitSlop = { top: 20, bottom: 20, left: 30, right: 30 };
const MenuListItem = (props) => {
    const { title, onPress, extraTitle } = props;
    return (<Center>
      <Box marginY={4}>
        <TextButton preset="subtitle1" fontWeight="bold" title={title} extraTitle={extraTitle} onPress={onPress} hitSlop={hitSlop}/>
      </Box>
    </Center>);
};
export default MenuListItem;
