import * as React from "react";
import Svg, { Path } from "react-native-svg";
const LedBulbIcon = ({ strokeColor = "currentColor", strokeWidth = 1.25, ...props }) => {
    return (<Svg width={20} height={30} viewBox="0 0 20 30" fill="none" {...props}>
      <Path d="M10.011 1C11.1946 1 12.3666 1.23313 13.4601 1.68607C14.5536 2.13902 15.5472 2.80291 16.3842 3.63985C17.2211 4.47678 17.885 5.47037 18.3379 6.56387C18.7909 7.65738 19.024 8.8294 19.024 10.013C19.0216 10.8896 18.8988 11.7618 18.659 12.605C18.4641 13.3478 18.1559 14.0562 17.745 14.705C17.324 15.34 16.765 15.869 16.333 16.497C15.6631 17.5998 15.2919 18.8581 15.256 20.148C15.2159 20.6889 14.9731 21.1946 14.5762 21.5642C14.1792 21.9337 13.6573 22.1397 13.115 22.141H6.91C6.36766 22.1397 5.8458 21.9337 5.44884 21.5642C5.05187 21.1946 4.80907 20.6889 4.769 20.148C4.73261 18.8587 4.36109 17.6011 3.691 16.499C3.259 15.871 2.701 15.342 2.279 14.707C1.8682 14.0581 1.55991 13.3498 1.365 12.607C1.12518 11.7638 1.00236 10.8916 1 10.015C1 7.6246 1.94958 5.33211 3.63985 3.64185C5.33011 1.95158 7.6226 1.002 10.013 1.002" stroke={strokeColor} strokeWidth={strokeWidth}/>
      <Path d="M6.74701 24.282H13.274" stroke={strokeColor} strokeWidth={strokeWidth} strokeLinecap="round"/>
      <Path d="M6.74701 26.48H13.274" stroke={strokeColor} strokeWidth={strokeWidth} strokeLinecap="round"/>
      <Path d="M7.961 28.678H12.06" stroke={strokeColor} strokeWidth={strokeWidth} strokeLinecap="round"/>
    </Svg>);
};
export default LedBulbIcon;
