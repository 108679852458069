import { TEMPERATUREUNITS } from "@huum/components/organisms/temperature-unit-select";
import { cast, types } from "mobx-state-tree";
import { withEnvironment, withRootStore } from "..";
export const SaunaStoreModel = types
    .model("SaunaStore")
    .props({
    saunaName: types.maybeNull(types.string),
    saunaId: types.maybeNull(types.string),
    paymentSaunaId: types.maybe(types.string),
    saunaIsPrivate: types.maybe(types.boolean),
    temperatureUnit: types.optional(types.string, TEMPERATUREUNITS.celsius),
    isFirstUser: types.optional(types.boolean, false),
    paymentEndDate: types.maybeNull(types.string),
    latestDuration: types.optional(types.number, 180),
    latestTargetTemp: types.optional(types.number, 90),
    ledDevice: types.maybeNull(types.frozen()),
    saunasList: types.optional(types.array(types.frozen()), []),
})
    .views((self) => ({
    saunas: () => self.saunasList,
}))
    .actions((self) => ({
    updateSaunaTemperatureUnit: (temperatureUnit) => {
        self.temperatureUnit = temperatureUnit;
    },
    changeSaunaProfile: ({ saunaIsPrivate, saunaId, temperatureUnit, }) => {
        if (saunaId) {
            self.saunaId = saunaId;
        }
        self.saunaIsPrivate = saunaIsPrivate;
        if (temperatureUnit) {
            self.temperatureUnit = temperatureUnit;
        }
    },
    setSaunaIsPrivate: (isPrivate) => {
        self.saunaIsPrivate = isPrivate;
    },
    setSaunaId: (saunaId) => {
        self.saunaId = saunaId;
    },
    setPaymentSaunaId: (saunaId) => {
        self.paymentSaunaId = saunaId;
    },
    setSaunasList: (saunas) => {
        self.saunasList = cast(saunas);
    },
    setFirstUser: (isFirstUser) => {
        self.isFirstUser = isFirstUser;
    },
    setLatestDuration: (duration) => {
        self.latestDuration = duration;
    },
    setLatestTargetTemp: (temp) => {
        self.latestTargetTemp = temp;
    },
    setPaymentEndDate: (paymentEndDate) => {
        self.paymentEndDate = paymentEndDate;
    },
    setLedDevice: (device) => {
        self.ledDevice = device;
    },
    clearSaunaState: () => {
        self.saunaId = null;
        self.saunaIsPrivate = undefined;
        self.isFirstUser = false;
        self.paymentEndDate = null;
        self.saunasList = cast([]);
    }
}))
    .extend(withEnvironment)
    .extend(withRootStore);
export const createSaunaStoreDefaultModel = () => types.optional(SaunaStoreModel, {});
