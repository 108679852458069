
import { StyleSheet } from 'react-native';

export default StyleSheet.create({
  infoContainer: {
    justifyContent: 'center',
    alignItems: 'center',
    alignSelf: 'center',
  },
  label: {
    fontSize: 12,
  },
});
