import api from "@huum/services/api";
import { getGeneralApiProblem } from "@huum/services/api/api-problem";
export async function getProfile(session, saunaId) {
    const response = await api.huum.get("/action/profile", {
        version: 3,
        session,
        saunaId,
    });
    if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem)
            throw problem;
    }
    return response.data;
}
export async function saveProfile(props) {
    const { session, email, name } = props;
    const profileData = {
        version: 3,
        session,
        email,
        name,
    };
    const response = await api.huum.post("/action/save_profile", profileData);
    if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem)
            throw problem;
    }
    return response.data;
}
export async function changePassword(props) {
    const { old_password, password1, password2, session } = props;
    const passwordParams = {
        version: 3,
        session,
        old_password,
        password1,
        password2,
    };
    const response = await api.huum.get("/action/passwordchange", passwordParams);
    if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem)
            throw problem;
    }
    return response.data;
}
export async function verifyEmail(session) {
    const response = await api.huum.post("/action/verifyemail", { session });
    if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem)
            throw problem;
    }
    return response.data;
}
export async function languageChange(data) {
    const response = await api.huum.post("/action/save_language", {
        ...data,
        version: 3,
    });
    if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem)
            throw problem;
    }
    return response.data;
}
export async function userSafetyNotificationChange(data) {
    const response = await api.huum.post("action/save-settings", {
        ...data,
        version: 3,
    });
    if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem)
            throw problem;
    }
    return response.data;
}
export async function userTemperatureUnitChange(data) {
    const response = await api.huum.post("user/temperature", {
        ...data,
        version: 3,
    });
    if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem)
            throw problem;
    }
    return response.data;
}
