//@ts-nocheck
import React, { useCallback, useEffect, useRef } from 'react';
import { View, Text, } from 'react-native';
import { get, findIndex, first } from 'lodash';
import { useScrollSelectorStyles } from './styles';
import { FlatList, Stack, Box } from "native-base";
const viewabilityConfig = {
    minimumViewTime: 500,
    viewAreaCoveragePercentThreshold: 90,
};
export const ScrollSelector = (props) => {
    const scrollSelector = useRef()?.current;
    const styles = useScrollSelectorStyles();
    const { value, selectorValues, disabled, onValueSelect, } = props;
    const getActiveIndex = () => {
        return Math.max(findIndex(selectorValues, { value }), 0);
    };
    // useEffect(() => {
    //   if (visible) {
    //     const valueIndex = getActiveIndex();
    //     animateComponent();
    //     scrollSelector?.snapToItem(valueIndex, false);
    //   }
    // }, [visible])
    useEffect(() => {
        if (value) {
            const valueIndex = getActiveIndex();
            scrollSelector?.scrollToIndex(valueIndex, false);
        }
    }, [value]);
    const onItemSnap = useCallback((callback) => {
        // const value = get(selectorValues, activeIndex);
        const { viewableItems } = callback;
        const valueIndex = first(viewableItems)?.index;
        const value = get(selectorValues, valueIndex);
        if (value) {
            onValueSelect(get(value, 'value'));
        }
    }, []);
    const renderItem = ({ item, index }) => (<View key={index} style={[styles.pickerItem, {}]}>
      <Text style={[styles.valueText, props.disabled && styles.disabled]}>
        {item.displayValue}
      </Text>
    </View>);
    const getItemLayout = (_, index) => ({
        length: 40,
        offset: 40 * index,
        index,
    });
    return (<Stack>
      <Box height={42}>
        <FlatList ref={scrollSelector} data={selectorValues} renderItem={renderItem} vertical showsVerticalScrollIndicator={false} itemHeight={40} pagingEnabled initialScrollIndex={getActiveIndex()} sliderHeight={100} itemWidth={40} removeClippedSubviews={false} getItemLayout={getItemLayout} onViewableItemsChanged={onItemSnap} scrollEnabled={!disabled} viewabilityConfig={viewabilityConfig}/>
      </Box>
    </Stack>);
};
