import Button from "@huum/components/atoms/button";
import { Text } from "@huum/components/atoms/text";
import { Box, Stack } from "native-base";
import { useCallback } from "react";
import { NetInfoStateType, useNetInfo } from "@react-native-community/netinfo";
import { NavigationHeader } from "@huum/components/molecules/header/navigation-header";
import { useForm } from "react-hook-form";
import { FormInput } from "@huum/components/atoms/form-input";
import TextButton from "@huum/components/molecules/text-button";
import { translate } from "@huum/i18n";
import { Screen } from "@huum/components/organisms/screen";
import { useSmartConfig } from "../hooks/useSmartConfig";
import { Input } from "@huum/components/atoms/input/input";
import { navigate } from "@huum/services/NavigationService";
import { useStores } from "@huum/store";
const WifiConfigConnectScreen = () => {
    const { toast } = useStores();
    const networkState = useNetInfo();
    const { control, handleSubmit, reset } = useForm({
        defaultValues: {
            password: "",
        },
    });
    const { connectedDevice, isLoading, handleStartConnection, handleCancelConnection, } = useSmartConfig();
    const handleConnect = (values) => {
        const { password } = values;
        if (!networkState.details?.ssid || !password) {
            const message = !password ? translate("error.password") : translate("error.wifi_not_enabled");
            toast.showToast({
                message,
                type: "error"
            });
            return;
        }
        navigate("smartConfigConnect", {
            wifiConfig: {
                ssid: networkState.details?.ssid,
                bssid: networkState.details?.bssid,
                password,
            },
        });
    };
    const WifiDisclaimer = useCallback(() => {
        if (!networkState.isConnected &&
            networkState.type !== NetInfoStateType.wifi) {
            return <Text color="red.500">{translate("error.wifi_not_enabled")}</Text>;
        }
        return null;
    }, [networkState]);
    return (<>
      <NavigationHeader title={translate("title.connect_with_sauna")}/>
      <Screen>
        <Stack space={4}>
          <WifiDisclaimer />
          <Box>
            <Text mb={1}>{translate("label.wifi_name")}</Text>
            <Input defaultValue={networkState.details?.ssid} isDisabled/>
          </Box>
          <Box>
            <Text mb={1}>{translate("label.wifi_password")}</Text>
            <FormInput name="password" control={control} isSecure/>
          </Box>
        </Stack>
      </Screen>
      <Stack safeAreaBottom pb={2} px={4} space={4}>
        <Button mt={4} label={translate("button.continue")} onPress={handleSubmit(handleConnect)}/>
        <TextButton alignSelf="center" title={translate("button.cancel")} onPress={handleCancelConnection}/>
      </Stack>
    </>);
};
export default WifiConfigConnectScreen;
