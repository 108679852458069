import React, { useCallback } from "react";
import { Box, Row } from "native-base";
import { Text } from "@huum/components/atoms/text";
import { Touchable } from "@huum/components/atoms/touchable/touchable";
import { useThemedStyles } from "@huum/hooks/useThemedStyles";
import { getStyles } from "./styles";
import { map } from "lodash";
import { isTablet } from "react-native-device-info";
import { getHitSlop } from "@huum/theme/helpers/styles";
export const Tabs = (props) => {
    const { tabs, plain } = props;
    const style = useThemedStyles(getStyles);
    const TabButton = useCallback((props) => {
        const { title, index, onPress, isFocused } = props;
        return (<Touchable hitSlop={getHitSlop(20, 35)} key={index} onPress={onPress}>
          <Row flexDir={"row"} alignItems={"center"} justifyContent={"center"}>
            <Text fontSize="14px" fontWeight={700}>
              {title}
            </Text>
          </Row>
          {isFocused && (<Box mt={1} height={1} backgroundColor="green.200" borderRadius={2}/>)}
        </Touchable>);
    }, []);
    const Tabs = () => (<Row justifyContent={isTablet() ? "space-evenly" : "space-between"}>
      {map(tabs, (tab, index) => (<TabButton key={index} {...tab} index={index}/>))}
    </Row>);
    if (plain)
        return <Tabs />;
    return (<>
      <Box pt={4} pb={2} paddingX={60} backgroundColor="white.100" borderTopRadius={20} safeAreaBottom style={style.container}>
        <Tabs />
      </Box>
    </>);
};
