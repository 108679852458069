import { useStores } from "@huum/store";
import { useQuery } from "@tanstack/react-query";
import { get, toNumber } from "lodash";
import { useEffect, useState } from "react";
import { fetchSaunaStatus } from "@huum/api/requests/sauna";
import SaunaStatusCode from "@huum/utils/sauna-status-code";
import { useControllerSoftwareUpdate } from "./useSoftwareUpdate";
export const useSaunaStatus = (ignoreErrors, saunaId) => {
    const { auth, sauna } = useStores();
    const [steamerError, setSteamerError] = useState(!!ignoreErrors);
    const querySaunaId = saunaId || sauna.saunaId;
    const session = get(auth, "sessionHash", null);
    const { data, isError, isSuccess, refetch } = useQuery({
        queryKey: [`saunastatus`, querySaunaId],
        queryFn: () => fetchSaunaStatus({ session, saunaId: querySaunaId }),
        refetchInterval: 30000,
        enabled: !!querySaunaId,
    });
    const { handleUpdateControllerSoftware } = useControllerSoftwareUpdate();
    useEffect(() => {
        if (data?.steamerError && !steamerError) {
            setSteamerError(true);
            // showSteamerErrorModal()
        }
        if (data?.showModal) {
            handleUpdateControllerSoftware();
        }
        if (data?.targetTemperature) {
            sauna.setLatestTargetTemp(toNumber(data.targetTemperature));
        }
    }, [data]);
    const targetTemperature = (data?.statusCode === SaunaStatusCode.SAUNA_ACTIVE || data?.statusCode === SaunaStatusCode.SAUNA_ACTIVE_BLOCKED)
        ? data?.targetTemperature
        : sauna.latestTargetTemp;
    return {
        targetTemperature,
        maxHeatingTime: data?.saunaConfig?.maxHeatingTime || 3,
        ...(data || {}),
        isError,
        isSuccess,
        refetch
    };
};
