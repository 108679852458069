import React, { useCallback, useState } from "react";
import { translate } from "@huum/i18n";
import { Stack } from "native-base";
import { Screen } from "@huum/components/organisms/screen";
import { useStores } from "@huum/store";
import { Text } from "@huum/components/atoms/text";
import { observer } from "mobx-react-lite";
import { useSaunaStatus } from "@huum/hooks/api/useSaunaStatus";
import { useRoute } from "@react-navigation/native";
import { NavigationHeader } from "@huum/components/molecules/header/navigation-header";
import TemperatureControllerSettings from "./temperature-controller-settings";
import SoloControllerSettings from "./solo-controller-settings";
import { IconButton } from "@huum/components/molecules/icon-button";
import { navigate } from "@huum/services/NavigationService";
import { useSaunaName } from "@huum/hooks/api/useSaunaName";
import { Input } from "@huum/components/atoms/input/input";
import { useSaunaRemove } from "@huum/hooks/api/useSaunaRemove";
import TextButton from "@huum/components/molecules/text-button";
// all values are to be controlled with control
const SaunaSettingsView = observer(() => {
    const { auth, sauna, modal, toast } = useStores();
    const { params } = useRoute();
    const saunaId = params.saunaId || sauna.saunaId || "";
    const { ...status } = useSaunaStatus(true, saunaId);
    const [saunaNameValue, setSaunaNameValue] = useState(status?.saunaName || "");
    const { updateSaunaName } = useSaunaName(saunaId);
    const { handleRemoveUserSauna } = useSaunaRemove();
    const isSoloController = parseInt(saunaId) >= 500000;
    const WifiSettingsButton = () => {
        if (!isSoloController)
            return null;
        return (<IconButton icon="wifi-settings" 
        // size={8}
        onPress={() => {
                navigate("startSmartConnect");
            }}/>);
    };
    const handleSaunaNameUpdate = async () => {
        await updateSaunaName(saunaNameValue);
    };
    const updateSaunaNameValue = (value) => {
        setSaunaNameValue(value);
    };
    const handleRemoveSaunaPress = useCallback(() => {
        modal.openModal("confirmModal", {
            modalTitle: translate("label.remove_sauna"),
            contentText: translate("text.remove_sauna_disclaimer"),
            confirmButtonLabel: translate('button.continue'),
            onConfirm: () => {
                handleRemoveUserSauna(saunaId);
            },
        });
    }, [saunaId]);
    if (!saunaId)
        return null;
    return (<>
      <NavigationHeader withBackNavigation title={translate("label.sauna_settings")} RightElement={<WifiSettingsButton />}/>
      <Screen key={`profile-${auth.language}`} keyboardDismissMode="on-drag">
        <Stack space={4}> 
          <Input defaultValue={status?.saunaName} placeholder={translate("label.sauna_name")} onChangeText={updateSaunaNameValue} onBlur={handleSaunaNameUpdate} maxLength={10} InputRightElement={<Text mr={2} fontSize="sm">{10 - saunaNameValue.length}</Text>}/>
          <TemperatureControllerSettings saunaStatus={status} saunaId={saunaId}/>
          <SoloControllerSettings saunaStatus={status} saunaId={saunaId}/>

          <Stack mt={4}>
            <TextButton color="red.600" title={translate("label.remove_sauna")} onPress={handleRemoveSaunaPress}/>
          </Stack>
        </Stack>
      </Screen>
    </>);
});
export default SaunaSettingsView;
