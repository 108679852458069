import React, { useCallback, useMemo } from 'react';
import { range } from 'lodash';
import { Row } from 'native-base';
import { ScrollSelector } from '../scroll-selector';
;
const NumberSelect = (props) => {
    const { minValue = 1, maxValue, value, visible, onValueSelect, } = props;
    const handleValueSelect = useCallback((value) => {
        onValueSelect(value);
    }, [onValueSelect]);
    const selectorValues = useMemo(() => {
        return range(minValue, maxValue + 1).map((value) => ({
            value,
            displayValue: value.toString(),
        }));
    }, [minValue, maxValue]);
    return (<Row width="full" justifyContent="center">
      <ScrollSelector visible={visible} onValueSelect={handleValueSelect} selectorValues={selectorValues} value={value}/>
    </Row>);
};
export default NumberSelect;
