/**
 * Application colors.
 *
 * Colors from here will be used in the theme files for specific colors
 */
export const palette = {
    black: {
        100: "rgba(0, 0, 0, 1)",
        50: "rgba(0, 0, 0, 0.5)",
        30: "rgba(0, 0, 0, 0.3)",
        10: "rgba(0, 0, 0, 0.1)",
    },
    white: {
        100: "rgba(255, 255, 255, 1)",
        50: "rgba(255, 255, 255, 0.5)",
        30: "rgba(255, 255, 255, 0.3)",
        10: "rgba(255, 255, 255, 0.1)",
    },
    green: {
        100: "#a1b7bb",
        200: "#9AD6CC",
        300: '#5b8287',
        400: '#2c5e65',
        500: "#154C54",
        600: "#113d43",
        700: "#0d2e32",
        800: "#061719",
        900: "#020808",
    },
    grey: {
        90: "rgba(17, 24, 31, 1)",
        80: "rgba(28, 38, 49, 1)",
        70: "rgba(39, 54, 69, 1)",
        60: "rgba(48, 66, 84, 1)",
        50: "rgba(95, 109, 122, 1)",
        40: "rgba(148, 157, 166, 1)",
        30: "rgba(201, 202, 209, 1)",
        20: "rgba(215, 217, 224, 1)",
        10: "rgba(240, 241, 245, 1)",
        5: "rgba(249, 249, 251, 1)",
    },
    blue: {
        50: "#e6e9ff",
        100: "#b8c0fc",
        200: "#8995f5",
        300: "#5c6bf0",
        400: "#2f41ea",
        500: "#1727d1",
        600: "#0f1ea3",
        700: "#091676",
        800: "#040c49",
        900: "#00031d",
    },
    yellow: {
        50: "#fff7de",
        100: "#fee6b1",
        200: "#fcd582",
        300: "#fbc553",
        400: "#f9b425",
        500: "#df9b0f",
        600: "#ae7808",
        700: "#7d5603",
        800: "#4c3400",
        900: "#1c1000",
    },
    purple: {
        50: "#f1e6ff",
        100: "#cfb9fb",
        200: "#ad8df4",
        300: "#8c5fee",
        400: "#6b31e7",
        500: "#5218ce",
        600: "#4011a1",
        700: "#2d0c74",
        800: "#1a0748",
        900: "#0a011d",
    },
    violet: {
        50: "#f1e6ff",
        100: "#cfb9fb",
        200: "#ad8df4",
        300: "#8c5fee",
        400: "#6b31e7",
        500: "#5218ce",
        600: "#4011a1",
        700: "#2d0c74",
        800: "#1a0748",
        900: "#0a011d",
    },
    purpleLight: {
        90: "rgba(154, 80, 194, 1)",
        80: "rgba(165, 99, 201, 1)",
        70: "rgba(176, 118, 207, 1)",
        60: "rgba(187, 137, 214, 1)",
        50: "rgba(198, 156, 221, 1)",
        20: "rgba(234, 206, 249, 1)",
    },
    red: {
        50: "#ffe2e2",
        100: "#ffb1b2",
        200: "#ff7f7f",
        300: "#ff4d4d",
        400: "#fe1d1b",
        500: "#e50501",
        600: "#DB376C",
        700: "#810000",
        800: "#4f0000",
        900: "#200000",
    },
    orange: {
        50: "#ffeddf",
        100: "#fccfb5",
        200: "#f6b088",
        300: "#f1915a",
        400: "#ec712c",
        500: "#d35914",
        600: "#a5440e",
        700: "#773109",
        800: "#491c02",
        900: "#1e0700",
    },
    transparent: "rgba(0, 0, 0, 0)",
    background: "rgba(231, 240, 249, 1)",
    dimmedText: "rgba(21, 76, 84, 0.6)",
    invalid: "rgba(219, 55, 108, 1)",
    primaryDark: "rgba(21, 76, 84, 1)",
    primaryLight: "rgba(154, 214, 204, 1)",
    toggleButtonBg: "rgba(228, 239, 249, 1)",
    line: "rgba(154, 214, 204, 0.6)",
    sliderTrack: "rgba(211, 226, 237, 1)",
};
export const gradients = {
    green: {
        linearGradient: {
            colors: ['#74AC3C', '#22A45E'],
            start: [0, 0],
            end: [1, 0],
            locations: [0, 0.6]
        }
    }
};
