import React, { useCallback, useMemo, useState } from 'react';
import { Dimensions } from 'react-native';
import { scaleTime } from 'd3-scale';
import Slider from '@react-native-community/slider';
import dayjs from "@huum/utils/date";
import { filter, forEach, get, isEmpty, map, size, } from 'lodash';
import { LineChart, Grid, YAxis, XAxis, } from 'react-native-svg-charts';
// import * as shape from 'd3-shape';
import { selectDailyReadings } from '@huum/utils/helpers/statistics-selectors';
import { svgRedConfig, svgGreenConfig, yAxisSVG, xAxisSVG, getStatisticsLineGraphStyles, } from './styles';
import HorizontalLine from './horizontalLine';
import Tooltip from './tooltip';
import TooltipBox from './tooltipBox';
import { Box } from 'native-base';
import { useThemedStyles } from '@huum/hooks/useThemedStyles';
import { palette } from '@huum/theme/helpers/palette';
import { Touchable } from '@huum/components/atoms/touchable/touchable';
import formatDegreeStatistic from '@huum/utils/helpers/degree-statistics-selectors';
import getLineGraphValues from '@huum/utils/helpers/line-graph-selectors';
import { formatDate } from '@huum/utils/date';
const screenWidth = Dimensions.get('window').width;
const MINUTES_PER_PIXEL = (24 * 60) / (screenWidth - 60);
const minutesBeforeMidnight = 40 * MINUTES_PER_PIXEL;
const minutesAfterMidnight = 20 * MINUTES_PER_PIXEL;
const SLIDER_PIXEL_RANGE = 5;
const StatisticsLineGraph = (props) => {
    const { temperatureUnit, statistics, selectedDate } = props;
    const [tooltip, setTooltip] = useState({ openTooltip: -1 });
    const styles = useThemedStyles(getStatisticsLineGraphStyles);
    const formatData = useCallback((data) => {
        if (data) {
            const chartData = map(data, (item) => ({
                time: new Date(item.changeTime * 1000),
                temperature: formatDegreeStatistic(parseInt(item.temperature, 10), temperatureUnit),
                isHeating: item.isHeating,
                target: formatDegreeStatistic(item.targetTemperature, temperatureUnit),
            }));
            return chartData;
        }
        return [];
    }, []);
    const createLines = useCallback((sessionsData, readingsData) => {
        const linesData = map(sessionsData, (session) => ({
            data: session.readings,
            svg: session.heating ? svgRedConfig : svgGreenConfig,
            heating: session.heating,
            target: session.target,
            heatingTime: session.heatingTime,
        }));
        // prevent React rendering from getting confused if we only have one line;
        const length = size(linesData);
        if (length === 1)
            return linesData;
        return [
            /* put original readings underneath so that:
            - the lines would be connected
            - the dashed target temperature lines and session
              tooltips could have normalized coordinate inputs
            */
            {
                data: readingsData,
                svg: svgGreenConfig,
                heating: false,
                target: 0,
                heatingTime: "",
            },
            ...linesData,
        ];
    }, []);
    const onSliderMoved = useCallback((value, props) => {
        const { linesData, scaleToPixel } = props;
        let indexOfClosestTooltipToSlider = -1;
        let distanceToClosestTooltip = screenWidth;
        let sliderInsideSession = false;
        const pixelValue = value * screenWidth;
        forEach(linesData, (line, index) => {
            const firstItem = line.data[0];
            const lastItem = line.data[line.data.length - 1];
            // if our slider value is close to the time, set that line's index as the open index
            const distance = Math.min(Math.abs(pixelValue - scaleToPixel(dayjs(firstItem.time))), Math.abs(pixelValue - scaleToPixel(dayjs(lastItem.time))));
            const sliderBetweenTooltips = pixelValue > scaleToPixel(dayjs(firstItem.time)) &&
                pixelValue < scaleToPixel(dayjs(lastItem.time));
            if (sliderBetweenTooltips) {
                sliderInsideSession = true;
            }
            if (distance < distanceToClosestTooltip) {
                indexOfClosestTooltipToSlider = index;
                distanceToClosestTooltip = distance;
            }
        });
        // set the open tooltip index if we are close to a tooltip or if we are in between two sessions
        if (distanceToClosestTooltip < SLIDER_PIXEL_RANGE ||
            sliderInsideSession) {
            setTooltip({ openTooltip: indexOfClosestTooltipToSlider });
        }
        else {
            setTooltip({ openTooltip: -1 });
        }
    }, [props]);
    const getGraphicProps = useMemo(() => {
        const readings = selectDailyReadings(statistics, selectedDate);
        if (readings?.length > 0) {
            const selectedDay = formatDate(dayjs(selectedDate), "D MMMM");
            const readingsData = formatData(readings);
            const { startOfDay, endOfDay, xAxisTimes, sessionsData } = getLineGraphValues(readingsData);
            const linesData = createLines(sessionsData, readingsData);
            const numSessionsToday = linesData.filter((a) => a.heating).length;
            return {
                startOfDay,
                endOfDay,
                xAxisTimes,
                sessionsData,
                linesData,
                selectedDay,
                numSessionsToday,
            };
        }
        else
            return {};
    }, [statistics, selectedDate]);
    const { startOfDay, endOfDay, xAxisTimes, linesData } = getGraphicProps;
    if (isEmpty(linesData)) {
        return (<>
        <Box style={styles.graphContainer}>
          <YAxis data={temperatureUnit === "celsius"
                ? map([...Array(11).keys()], (key) => key * 10)
                : map([...Array(12).keys()], (key) => key * 20)} yAccessor={({ item }) => item} style={styles.yAxis} min={0} contentInset={{ top: 20, bottom: 20 }} svg={yAxisSVG}/>
          <Box flexDirection={"column"}>
            <LineChart numberOfTicks={11} data={Array(11).fill(null)} contentInset={{
                top: 20,
                bottom: 20,
                left: 16,
                right: 16,
            }} style={[styles.lineChart, { width: screenWidth - 60 }]} yMin={0} yMax={temperatureUnit === "celsius" ? 100 : 220}>
              <Grid />
            </LineChart>
            <XAxis data={map([...Array(5).keys()], (key) => new Date().setHours(key * 6, 0, 0))} xAccessor={({ item }) => item} formatLabel={(value) => formatDate(dayjs(value), "HH:mm")} svg={xAxisSVG} contentInset={{ left: 16, right: 16 }}/>
          </Box>
        </Box>
        <Box paddingX={8} mt={1}>
          <Slider disabled style={styles.slider} minimumValue={0} maximumValue={1} minimumTrackTintColor={palette.sliderTrack} maximumTrackTintColor={palette.sliderTrack} thumbTintColor={palette.primaryDark}/>
        </Box>
      </>);
    }
    const heatingLines = filter(linesData, (line) => line.heating);
    const sliderProps = {
        startOfDay,
        endOfDay,
        linesData: heatingLines,
        scaleToPixel: scaleTime()
            .domain([
            dayjs(startOfDay).subtract(minutesBeforeMidnight, "minutes"),
            dayjs(endOfDay).add(minutesAfterMidnight, "minutes"),
        ])
            .range([0, screenWidth]),
    };
    return (<>
      <Box style={[styles.graphContainer, { width: screenWidth }]}>
        <YAxis data={temperatureUnit === "celsius"
            ? map([...Array(11).keys()], (key) => key * 10)
            : map([...Array(12).keys()], (key) => key * 20)} yAccessor={({ item }) => item} style={styles.yAxis} min={0} contentInset={{ top: 20, bottom: 20 }} svg={yAxisSVG}/>
        <Touchable onPress={() => setTooltip({ openTooltip: -1 })}>
          <Box flexDirection={"column"}>
            <LineChart data={linesData} xAccessor={({ item }) => item.time} yAccessor={({ item }) => parseInt(item.temperature, 10)} contentInset={{
            top: 20,
            bottom: 20,
            left: 16,
            right: 16,
        }} style={[styles.lineChart, { width: screenWidth - 60 }]} xMin={startOfDay} xMax={endOfDay} yMin={0} yMax={temperatureUnit === "celsius" ? 100 : 220}>
              <Grid />
              {map(heatingLines, (line, index) => {
            if (line.heating && line.data.length >= 2) {
                const firstItem = line.data[0];
                const lastItem = line.data[line.data.length - 1];
                return (<>
                      <HorizontalLine key={`Line ${index}`} target={line.target} startOfDay={startOfDay} endOfDay={endOfDay} firstTime={firstItem.time} lastTime={lastItem.time} temperatureUnit={temperatureUnit}/>
                      <Tooltip key={`Tooltip ${index}`} startTime={firstItem.time} startTemperature={firstItem.temperature} endTime={lastItem.time} endTemperature={lastItem.temperature} startOfDay={startOfDay} endOfDay={endOfDay} tooltipNumber={index} openTooltip={tooltip} setOpenTooltip={(num) => setTooltip({ openTooltip: num })} temperatureUnit={temperatureUnit}/>
                    </>);
            }
            return null;
        })}

              {/* Boxes have to be mapped separately so they would
          have a higher z-Index than the circle tooltips */}
              {map(heatingLines, (line, index) => {
            if (line.heating) {
                const firstItem = line.data[0];
                const lastItem = line.data[line.data.length - 1];
                return (<>
                      <TooltipBox key={`TooltipBox ${index}`} target={line.target} startTime={firstItem.time} startTemperature={firstItem.temperature} endTime={lastItem.time} endTemperature={lastItem.temperature} startOfDay={startOfDay} endOfDay={endOfDay} tooltipNumber={index} openTooltip={get(tooltip, "openTooltip")} heatingTime={line.heatingTime} isStart temperatureUnit={temperatureUnit}/>
                      <TooltipBox key={`Tooltip ${index}.5`} target={line.target} startTime={firstItem.time} startTemperature={firstItem.temperature} endTime={lastItem.time} endTemperature={lastItem.temperature} startOfDay={startOfDay} endOfDay={endOfDay} tooltipNumber={index + 0.5} openTooltip={get(tooltip, "openTooltip")} heatingTime={line.heatingTime} temperatureUnit={temperatureUnit}/>
                    </>);
            }
            return null;
        })}
            </LineChart>
            <XAxis data={xAxisTimes} xAccessor={({ item }) => item} formatLabel={(value) => formatDate(dayjs(value), "HH:mm")} svg={xAxisSVG} contentInset={{ left: 16, right: 16 }}/>
          </Box>
        </Touchable>
      </Box>
      <Box paddingX={8}>
        <Slider style={styles.slider} minimumValue={0} maximumValue={1} step={0} minimumTrackTintColor={palette.sliderTrack} maximumTrackTintColor={palette.sliderTrack} thumbTintColor={palette.primaryDark} onValueChange={(value) => onSliderMoved(value, sliderProps)}/>
      </Box>
    </>);
};
export default StatisticsLineGraph;
