import { useStores } from "@huum/store";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { get, join, map, toNumber, pick } from "lodash";
import { useCallback, useEffect } from "react";
import { saveBooking as saveBookingRequest, saveBookingException as saveBookingExceptionRequest } from "@huum/api/requests/bookings";
import dayjs from "@huum/utils/date";
import { translate } from "@huum/i18n";
/**
 * Regular booking:
 * Doesn't have repeatDuration and repeatPattern
 *
 * Repeating booking:
 * Has repeatDuration and repeatPattern. Has occurrences and bookingExceptions keys in result. When modifying existing repeating booking
 * then only startTime, endTime, targetTemperature and isPrivate values can be edited. Repeating duration, pattern and date and name can't be changed.
 *
 * Booking exception:
 * Has bookingId that references to original booking. isRecurring, repeatPattern and repeatDuration are always present from original booking.
 * When deleting booking exception, isCancelled is set to 1. When deleting exception and all the following events, then original event id should be used.
 * When deleting exception and all the following events, then endDate should be set to beginning of exception event day. Backend will handle the rest
 *
 * Child booking:
 * Has parentId that references to original booking.
 *
 * When sending booking data to server, try to cleanup as much as possible but make sure that all the original unchanged fields are present.
 * Otherwise backend will modify the booking in unexpected way.
 *
 */
const arrayToStringPattern = (pattern) => join(pattern, '');
export const stringToArrayPattern = (pattern) => pattern ? map(pattern, toNumber) : [0, 0, 0, 0, 0, 0, 0];
export const useBookingModify = () => {
    const { auth, toast, modal, sauna } = useStores();
    const session = get(auth, "sessionHash", null);
    const queryClient = useQueryClient();
    const { mutate, data, isError, isSuccess, isPending: isLoading } = useMutation({
        mutationFn: saveBookingRequest,
        onSuccess: async () => {
            queryClient.invalidateQueries({ queryKey: ["bookingsList"] });
        }
    });
    const { mutate: mutateException, data: exceptionData, isError: exceptionIsError, isSuccess: exceptionIsSuccess, isPending: exceptionIsLoading } = useMutation({
        mutationFn: saveBookingExceptionRequest,
        onSuccess: async () => {
            queryClient.invalidateQueries({ queryKey: ["bookingsList"] });
        }
    });
    useEffect(() => {
        if (isSuccess || exceptionIsSuccess) {
            toast.showToast({
                message: translate('text.success'),
                type: "success"
            });
        }
    }, [isSuccess, exceptionIsSuccess]);
    const saveBooking = useCallback(async (data) => {
        const patternString = arrayToStringPattern(data.weeklyPattern);
        const body = {
            id: null,
            parentId: null,
            session,
            ...data,
            isRecurring: toNumber(data.repeatDuration) > 0 ? 1 : 0,
            weeklyPattern: patternString,
            saunaId: sauna.saunaId
        };
        mutate(body);
    }, [session,]);
    const saveBookingException = useCallback(async (data) => {
        const { weeklyPattern } = data;
        const patternString = arrayToStringPattern(weeklyPattern);
        const input = {
            isCancelled: 0,
            isRescheduled: 1,
            session,
            ...data,
            isRecurring: 1,
            id: data.bookingId ? data.id : null,
            bookingId: data.bookingId ? data.bookingId : data.id,
            weeklyPattern: patternString,
            saunaId: sauna.saunaId,
        };
        mutateException(input);
    }, [session,]);
    const saveBookingAndUpcoming = useCallback(async (data) => {
        const { parentId, id, startTime, startDate } = data;
        let input = {
            ...data,
            saunaId: sauna.saunaId,
        };
        if (!parentId) {
            input.parentId = id;
            input.bookingId = undefined;
            input.id = undefined;
        }
        else if (!!startDate && !dayjs.unix(startTime).isSame(dayjs.unix(startDate))) {
            input.bookingId = data.id;
        }
        await saveBooking(input);
    }, [saveBooking]);
    const deleteBooking = useCallback(async (event) => {
        const body = {
            ...(pick(event, ["endTime", "startTime", "startDate", "id", "parentId", "title"])),
            endDate: 0,
            saunaId: sauna.saunaId,
        };
        await saveBooking(body);
        modal.cleanModalState();
    }, [saveBooking]);
    const deleteBookingException = useCallback(async (event) => {
        let input = {
            ...(pick(event, ["bookingId", "startDate", "startTime", "endTime"])),
            id: event.bookingId ? event.id : '',
            bookingId: event.bookingId ? event.bookingId : event.id,
            isCancelled: 1,
            isRescheduled: 0,
            saunaId: sauna.saunaId,
        };
        await saveBookingException(input);
        modal.cleanModalState();
    }, [saveBookingException]);
    const deleteBookingAndUpcoming = useCallback(async (event) => {
        const { parentId, id, startDate, startTime, endTime, bookingId, ...rest } = event;
        let input = {
            ...(pick(rest, ["title", "comments", "weeklyPattern", "repeatDuration", "isRecurring"])),
            id: bookingId || id,
            targetTemperature: event.originalTargetTemperature || event.targetTemperature || 0,
            parentId: parentId && parentId !== id ? parentId : null,
            saunaId: sauna.saunaId,
        };
        const unixStartTime = dayjs.unix(startTime).utc().unix();
        if (unixStartTime !== startDate) {
            input.endDate = unixStartTime;
        }
        else {
            input.endDate = 0;
        }
        await saveBooking(input);
        modal.cleanModalState();
    }, [saveBooking]);
    return {
        saveBooking,
        deleteBooking,
        isError,
        isSuccess,
        isLoading,
        saveBookingAndUpcoming,
        saveBookingException,
        deleteBookingException,
        deleteBookingAndUpcoming,
    };
};
