import { Text } from "@huum/components/atoms/text";
import { Row } from "native-base";
const BulletText = ({ text }) => {
    return (<Row alignItems="center" space={2}>
     <Text fontSize="2xl">
      {"\u2022"}
    </Text>
    <Text lineHeight="md">
      {text}
    </Text>
   </Row>);
};
export default BulletText;
