import { types } from "mobx-state-tree";
import { AuthStoreModel } from "../auth/auth-store";
import { ModalStoreModel } from "../modal/modal-store";
import { ToastStoreModel } from "../toast/toast-store";
import { SaunaStoreModel } from "../sauna/sauna-store";
import { LedControllerStoreModel } from "../iot/led-controller-store";
/**
 * A RootStore model.
 */
// prettier-ignore
export const RootStoreModel = types.model("RootStore").props({
    auth: types.optional(AuthStoreModel, {}),
    modal: types.optional(ModalStoreModel, {}),
    toast: types.optional(ToastStoreModel, {}),
    sauna: types.optional(SaunaStoreModel, {}),
    iot: types.optional(LedControllerStoreModel, {}),
});
