import { StyleSheet } from "react-native";
export const getStyles = (theme) => {
    const { colors, space, shadows } = theme;
    return StyleSheet.create({
        inputContainer: {
            borderRadius: 6,
            borderColor: colors.gray[200],
            borderWidth: 1,
            minHeight: 48,
            backgroundColor: colors.white[100],
            paddingHorizontal: space[1]
        },
        iconWrapper: {
            marginRight: space[4],
        },
        helperText: {
            marginTop: space[1],
            marginHorizontal: space[3],
        },
    });
};
