import React, { useState, useCallback, } from "react";
import { includes, toUpper, times, } from "lodash";
import dayjs from "@huum/utils/date";
import { getCalendarStyles } from "../styles";
import { useThemedStyles } from "@huum/hooks/useThemedStyles";
import { Text } from "@huum/components/atoms/text";
import { Box, Pressable, Row, Stack } from "native-base";
import CalendarHeader from "../calendar-header/index";
import { formatDate } from "@huum/utils/date";
const DayItem = (props) => {
    const { date, isSelected, isDisabled, hasIndicator, onDateSelect } = props;
    const handleDateSelect = useCallback((date) => () => {
        if (onDateSelect) {
            onDateSelect(date);
        }
    }, []);
    const styles = useThemedStyles(getCalendarStyles);
    if (!date)
        return null;
    if (isSelected) {
        return (<Pressable onPress={handleDateSelect(date)}>
        <Box style={styles.weekCalendarDaySelected}>
          <Text preset="subtitle1" fontWeight={700}>
            {dayjs(date).format("D")}
          </Text>
          <Text style={styles.selectedText}>
            {toUpper(formatDate(dayjs(date), "ddd"))}
          </Text>
        </Box>
      </Pressable>);
    }
    return (<Pressable onPress={handleDateSelect(date)} style={styles.weekCalendarDay} disabled={isDisabled}>
      <Text preset="subtitle1" fontWeight={700} color={isDisabled ? "gray.400" : "primaryDark"}>
        {dayjs(date).format("D")}
      </Text>
      <Text style={styles.selectedText}>
        {toUpper(formatDate(dayjs(date), "ddd"))}
      </Text>
      {hasIndicator && <Box height={1} width={2} bg="primaryLight"/>}
    </Pressable>);
};
const CalendarWeek = (props) => (<Row width="full" justifyContent="space-between" px={2}>
    {times(7, (index) => {
        const day = dayjs(props.selectedWeekDate)
            .startOf("week")
            .add(index, "day");
        const dayKey = day.format("YYYY-MM-DD");
        const hasIndicator = includes(props.indicatorsList, dayKey);
        return (<DayItem key={dayKey} date={dayKey} isSelected={dayKey === props.selectedDate} isDisabled={props.isDisabled} onDateSelect={props.onDateSelect} hasIndicator={hasIndicator}/>);
    })}
  </Row>);
const WeekCalendar = (props) => {
    const [selectedDate, setSelectedDate] = useState(props.selectedDate);
    const [visibleWeekDate, setVisibleDate] = useState(dayjs(props.selectedDate));
    const showNextWeek = () => {
        const nextVisibleWeek = dayjs(visibleWeekDate)
            .add(1, "week")
            .startOf("week");
        setVisibleDate(nextVisibleWeek);
    };
    const showPreviousWeek = () => {
        const previousVisibleWeek = dayjs(visibleWeekDate)
            .subtract(1, "week")
            .endOf("week");
        setVisibleDate(previousVisibleWeek);
    };
    const handleDateSelect = useCallback((date) => {
        if (props.disabled)
            return;
        if (typeof props.onDateSelect === "function") {
            props.onDateSelect(dayjs(date));
        }
        setSelectedDate(date);
    }, [props]);
    return (<Stack display={props.visible ? "flex" : "none"}>
      <Stack minHeight={125} justifyContent="space-between" pt={6} pb={2} space={2}>
        <CalendarHeader currentMonth={visibleWeekDate} futureDatesLimit={props?.limitFutureDates} pastDatesLimit={props?.limitPastDates} onPreviousPress={showPreviousWeek} onNextPress={showNextWeek}/>
        <CalendarWeek selectedDate={selectedDate} selectedWeekDate={visibleWeekDate.format()} onDateSelect={handleDateSelect} indicatorsList={props.activityIndicatorList} isDisabled={props.disabled}/>
      </Stack>
      {props.children}
    </Stack>);
};
export default WeekCalendar;
