import { StyleSheet, Dimensions } from 'react-native';
const { width } = Dimensions.get('screen');
export default StyleSheet.create({
    headerContainerStyle: {
        paddingTop: 25,
    },
    separatorStyle: {
        top: 88,
    },
    contentContainerStyle: {
        paddingBottom: 25,
    },
    labelBold16: {
        fontSize: 16,
        lineHeight: 24,
        fontWeight: "600",
        textAlign: "center",
    },
    label14: {
        fontSize: 14,
        lineHeight: 20,
        textAlign: "center",
    },
});
