const celciusToFahrenheit = (celsius) => {
    const celsiusToFahrenheit = ((celsius * 9) / 5) + 32;
    return Math.round(celsiusToFahrenheit);
};
export const fahrenheitToCelsius = (fahrenheit) => {
    const fahrenheitToCelsius = ((fahrenheit - 32) * 5) / 9;
    return Math.round(fahrenheitToCelsius);
};
export const getTemperatureUnit = (unit) => {
    return unit === 'fahrenheit' ? '°F' : '°C';
};
export const getTemperatureValue = (temperature, unit) => {
    if (unit === 'fahrenheit') {
        return celciusToFahrenheit(temperature);
    }
    return temperature;
};
