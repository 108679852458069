import BookingDetailModal from "@huum/components/views/modals/booking-detail-modal";
import SaunaModal from "@huum/components/views/modals/sauna-modal";
import TimerSelectModal from "@huum/components/molecules/time-select/modal";
import ConfirmModal from "@huum/components/views/modals/confirm-modal";
import IconModal from "@huum/components/views/modals/iconModal";
import LanguageSelectModal from "@huum/components/molecules/language-select/modal";
import HTMLModal from "@huum/components/views/modals/html-modal";
import EmergencyStopModal from "@huum/components/views/modals/emergency-stop-modal";
import SaunaHumidityController from "@huum/features/sauna-controller/components/humidity-controller";
import NumberSelectModal from "@huum/components/molecules/number-select/modal";
import LedController from "@huum/features/led-controller";
export const MODAL_TYPES = () => ({
    languageSelect: LanguageSelectModal,
    saunaModal: SaunaModal,
    timerSelect: TimerSelectModal,
    numberSelect: NumberSelectModal,
    bookingDetail: BookingDetailModal,
    confirmModal: ConfirmModal,
    iconModal: IconModal,
    htmlModal: HTMLModal,
    emergencyStop: EmergencyStopModal,
    humiditySelect: SaunaHumidityController,
    ledController: LedController,
});
export const MODAL_SIZES = {};
