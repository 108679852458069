import { get, replace } from "lodash";
import { translate } from "../i18n";
export const handleGenericError = (exeption, store) => {
    const { toast, auth, sauna } = store;
    const { kind, error } = exeption;
    const cleanRequestPath = replace(error?.path, '/', '');
    switch (kind) {
        case "forbidden":
        case "unauthorized": {
            if (auth.sessionHash) {
                auth.logout();
            }
            else {
                const errorMessageCode = `error.${error.msg}`;
                const errorMessage = translate(errorMessageCode);
                toast.showToast({
                    message: errorMessage,
                    type: "error",
                    location: "bottom",
                });
            }
            break;
        }
        case "rejected": {
            if (get(error, "message") === "validation.failed") {
                const errorCode = replace(get(error, "errors[0].msg"), /\./g, "_");
                const errorMessage = translate(`error.${errorCode}`);
                toast.showToast({
                    message: errorMessage,
                    type: "error",
                    location: "bottom",
                });
            }
            else {
                const errorCode = replace(get(error, "msg"), /\./g, "_");
                const errorMessage = translate(`error.${errorCode}`);
                if (!sauna.saunaId && errorCode === "NO_SAUNA_FOUND") {
                    break;
                }
                toast.showToast({
                    message: errorMessage,
                    type: "error",
                    location: "bottom",
                });
            }
            break;
        }
        default:
            toast.showToast({
                message: error?.details,
                type: "error",
                location: "bottom",
            });
    }
};
