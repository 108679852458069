import React, { useCallback, useEffect, useMemo } from "react";
import { MainHeader } from "@huum/components/molecules/header/main-header";
import { translate } from "@huum/i18n";
import { Box, Center, Row, Stack, useTheme } from "native-base";
import { useStores } from "@huum/store";
import { Text } from "@huum/components/atoms/text";
import { FormInput } from "@huum/components/atoms/form-input";
import { Controller, useForm } from "react-hook-form";
import Button from "@huum/components/atoms/button";
import { palette } from "@huum/theme/helpers";
import TemperatureUnitSelect from "@huum/components/organisms/temperature-unit-select";
import { Switch } from "@huum/components/molecules/switch";
import { goBack, navigate } from "@huum/services/NavigationService";
import { useMutation, useQuery } from "@tanstack/react-query";
import { getProfile, saveProfile } from "@huum/api/requests/profile";
import { get } from "lodash";
import { observer } from "mobx-react-lite";
import { Touchable } from "@huum/components/atoms/touchable/touchable";
import { useVerifyEmail } from "@huum/hooks/api/useVerifyEmail";
import LanguageSelect from "@huum/components/molecules/language-select";
import InfoLabel from "@huum/components/molecules/info-label";
import { useSaunaStatus } from "@huum/hooks/api/useSaunaStatus";
import TextButton from "@huum/components/molecules/text-button";
import { useAccountDelete } from "@huum/hooks/api/useAccountDelete";
import { useUserTemperatureUnitChange } from "@huum/hooks/api/useSaunaTemperatureUnit";
import { Platform } from "react-native";
// all values are to be controlled with control
const ProfileView = observer(() => {
    const { auth, sauna, modal, toast } = useStores();
    const { emailVerify, isSuccess } = useVerifyEmail();
    const { control, handleSubmit, watch } = useForm();
    const { config } = useTheme();
    const { handleDeleteAccount } = useAccountDelete();
    const { maxHeatingTime } = useSaunaStatus();
    const session = get(auth, "sessionHash", "");
    const { data: profile } = useQuery({
        queryKey: ["profile"],
        queryFn: () => getProfile(session || "", sauna.saunaId || ""),
        initialData: {}
    });
    const changeProfile = useMutation(saveProfile);
    const username = get(profile, "name", "");
    const email = get(profile, "email", "");
    const saunaId = get(sauna, "saunaId", "");
    const temperatureUnit = get(sauna, "temperatureUnit");
    const saunaIsPrivate = get(sauna, "saunaIsPrivate", true);
    const watchEmail = watch("email", email);
    const { changeTemperatureUnit } = useUserTemperatureUnitChange();
    useEffect(() => {
        if (isSuccess) {
            toast.showToast({
                message: `${translate("text.success")} ${translate("text.email_verified")}`,
                type: "success",
                duration: 3000,
            });
        }
    }, [isSuccess]);
    const handleChangePassword = useCallback(() => {
        navigate("changePassword");
    }, []);
    const handleFormSubmit = useCallback((values) => {
        if (username !== values.fullName || email !== values.email) {
            changeProfile.mutate({
                session,
                name: values.fullName || username,
                email: values.email || email,
            }, {
                onSuccess: () => {
                    toast.showToast({
                        message: `${translate("text.success")} ${translate("text.email_verified")}`,
                        type: "success",
                        duration: 3000,
                    });
                },
            });
            return;
        }
        if (values.temperatureUnit !== temperatureUnit) {
            changeTemperatureUnit(values.temperatureUnit);
        }
        goBack();
        toast.showToast({
            message: `${translate("text.success")}`,
            type: "success",
            duration: 3000,
        });
    }, [temperatureUnit]);
    const handleSaunaIdChange = useCallback(() => {
        if (Platform.OS === "web") {
            navigate("saunaConnect");
        }
        else {
            modal.openModal("saunaModal", {
                modalVariant: "center",
                modalTitle: translate("title.sauna_id"),
                buttonLabel: translate("button.save"),
                saunaId: saunaId,
            });
        }
    }, []);
    const handleEmailChange = useCallback(() => {
        emailVerify();
    }, []);
    const displayEmailChangeMessage = useMemo(() => {
        if (watchEmail !== email && watchEmail !== "") {
            return (<Box mb={4} alignItems={"center"}>
          <Touchable onPress={handleEmailChange}>
            <Text preset="subtitle1" fontWeight={"600"}>
              {translate("button.send_verification_email")}
            </Text>
          </Touchable>
        </Box>);
        }
        else
            return null;
    }, [email, watchEmail]);
    return (<Stack flex={1}>
      <MainHeader withBackNavigation title={translate("button.profile_and_id")}/>
      <Row space={8} p={8}>
        <Stack flex={1}>
          <Stack space={4}>
            <InfoLabel preset="subtitle1" fontWeight={"600"} label={translate("title.profile")} infoText={translate("info.phone_and_email")}/>
            
            <FormInput name="fullName" defaultValue={username} placeholder={translate("button.full_name")} control={control}/>
            <Row space={4}>
              <FormInput name="email" defaultValue={email} placeholder={translate("label.email")} control={control}/>
              <LanguageSelect />
            </Row>
            {displayEmailChangeMessage}
          </Stack>
          <Center mt={8}>
              <TextButton title={translate("button.change_password")} onPress={handleChangePassword}/>  
            <Stack mt={8} width={{
            base: "100%",
            md: "420px",
        }}>
              <Button label={translate("button.confirm")} onPress={handleSubmit(handleFormSubmit)}/>
            </Stack>
            <TextButton mt={8} onPress={handleDeleteAccount} color="gray.400" title={translate("button.delete_account")}/>
            </Center> 
        </Stack>
        <Stack flex={1} space={4}>
          <Row justifyContent={"space-between"} alignItems={"center"} space={8}>
            <Stack space="2">
              <Text preset="subtitle2" style={{ color: palette.dimmedText }}>
                {translate("title.sauna_id")}
              </Text>
              <Text preset="subtitle1" fontWeight={"bold"}>
                ID {saunaId}
              </Text>
            </Stack>
            <Box flexShrink={1}>
              <Button icon="add" label={translate("button.add_new_sauna")} onPress={handleSaunaIdChange}/>
            </Box>
          </Row>
          <Row justifyContent="space-between">
            <InfoLabel preset="button" label={translate("title.max_heating_time")} infoText={translate("info.sauna_max_heating")}/>
            <Text fontWeight="bold">{maxHeatingTime}</Text>
          </Row>
          <Text preset="subtitle2" style={{ color: palette.dimmedText }}>
            {translate("text.degrees")}
          </Text>
          <Controller control={control} name="temperatureUnit" defaultValue={temperatureUnit} render={({ field: { value, onChange } }) => (<TemperatureUnitSelect onValueChange={onChange} selectedUnit={value}/>)}/>
          <Row direction={"row"} justifyContent={"space-between"}>
            <InfoLabel label={translate("label.is_sauna_private")} infoText={translate("info.private_sauna", {
            supportEmail: config.helpEmail,
        })}/>
            <Controller control={control} name="saunaPrivate" defaultValue={saunaIsPrivate} render={({ field: { value, onChange } }) => (<Switch disabled value={value} onToggle={() => onChange(!value)}/>)}/>
          </Row>
        </Stack>
      </Row>
    </Stack>);
});
export default ProfileView;
