import React from 'react';
import { Dimensions } from 'react-native';
import * as scale from 'd3-scale';
import { Circle, G, } from 'react-native-svg';
import { palette } from '@huum/theme/helpers';
const screenWidth = Dimensions.get('window').width;
const Tooltip = (props) => {
    const { startTemperature, startTime, endTemperature, endTime, startOfDay, endOfDay, tooltipNumber, openTooltip, setOpenTooltip, temperatureUnit, } = props;
    const scaleTime = scale.scaleTime()
        .domain([startOfDay, endOfDay])
        .range([16, screenWidth - 80 - 16]);
    // function
    const scaleTemp = scale.scaleLinear()
        .domain([0, (temperatureUnit === 'celsius' ? 100 : 220)])
        .range([260, 20]);
    return (<>
      {/* Green start circle */}
      <G x={scaleTime(startTime)} key={`first-tooltip-${tooltipNumber}`}>
        <G>
          <Circle cy={scaleTemp(startTemperature)} r={6} stroke={palette.invalid} strokeWidth={2} fill="white" onPress={() => setOpenTooltip(openTooltip !== tooltipNumber ? tooltipNumber : -1)}/>
        </G>
      </G>

      {/* Red end circle */}
      <G x={scaleTime(endTime)} key={`second-tooltip-${tooltipNumber}`}>
        <G>
          <Circle cy={scaleTemp(endTemperature)} r={6} stroke={palette.invalid} strokeWidth={2} fill="white" onPress={() => setOpenTooltip(openTooltip !== tooltipNumber + 0.5 ? tooltipNumber + 0.5 : -1)}/>
        </G>
      </G>
    </>);
};
export default Tooltip;
