import React, { useState } from 'react';
import { View } from 'react-native';
import { getStyles } from './input.styles';
import { Touchable } from '../touchable/touchable';
import { FormControl, Input as BaseInput } from 'native-base';
import { useThemedStyles } from '@huum/hooks/useThemedStyles';
import { Icon } from '../icon';
import { hitSlop } from '@huum/theme/helpers/styles';
// currently we have no presets, but that changes quickly when you build your app.
const PRESETS = {
    default: {},
};
/**
 * A component which has a label and an input together.
 */
export function Input(props) {
    const [isFocused, setIsFocused] = useState(false);
    const { label, preset = 'default', style: styleOverride, iconLeft, iconRight, iconSize, onIconLeftPress, onIconRightPress, helperText, helperTextType, value, ...rest } = props;
    const styles = useThemedStyles(getStyles);
    const IconButton = (props) => {
        const { icon, action, isActive } = props;
        if (!icon) {
            return null;
        }
        return (<Touchable style={styles.iconWrapper} onPress={action}>
        <Icon size={iconSize || 8} 
        // tint={tintColor}
        icon={icon}/>
      </Touchable>);
    };
    return (<View>
      <FormControl isDisabled={rest.isDisabled}>
        <BaseInput value={value} variant="rounded" autoCorrect={false} InputRightElement={<IconButton isActive={isFocused} icon={iconRight} action={onIconRightPress} hitSlop={hitSlop}/>} {...rest}/>
        <FormControl.HelperText>{helperText}</FormControl.HelperText>
      </FormControl>
    </View>);
}
