import { View } from "native-base";
import { useDerivedValue } from "react-native-reanimated";
import { Canvas, LinearGradient, Path, vec } from "@shopify/react-native-skia";
import { Platform } from "react-native";
export function getCirclePath(cx, cy, r) {
    return 'M ' + cx + ' ' + cy + ' m -' + r + ', 0 a ' + r + ',' + r + ' 0 1,1 ' + (r * 2) + ',0 a ' + r + ',' + r + ' 0 1,1 -' + (r * 2) + ',0';
}
const ControllerGradientPath = (props) => {
    const { visible = true, temperaturePercentage, size, monotone, temperatureColor, } = props;
    if (!visible)
        return null;
    const start = 0.1;
    const end = 0.9;
    const colors = monotone
        ? [temperatureColor || "rgb(13, 80, 99)"]
        : [
            'rgb(13, 80, 99)',
            'rgb(213, 50, 98)',
        ];
    const circlePath = getCirclePath(size / 2, size / 2, size / 2 - 8);
    const animatedEnd = useDerivedValue(() => {
        const value = temperaturePercentage.value / 100;
        return start + value * (end - start);
    });
    // For web, we need to handle the Canvas differently
    if (Platform.OS === 'web') {
        return (<View position="absolute" width={size} height={size} zIndex={100} style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
            }}>
        {/* Web fallback implementation */}
        <View style={{
                width: size,
                height: size,
                borderRadius: size / 2,
                borderWidth: 16,
                borderColor: colors[colors.length - 1],
                transform: [{ rotate: "-90deg" }],
                opacity: temperaturePercentage.value / 100
            }}/>
      </View>);
    }
    return (<View position="absolute" width={size} height={size} zIndex={100}>
      <Canvas style={{ flex: 1, transform: [{ rotate: "-90deg" }] }}>
        <Path path={circlePath} style="stroke" strokeWidth={16} strokeJoin="round" strokeCap="round" color="lightblue" start={start} end={animatedEnd}>
          <LinearGradient start={vec(0, 0)} end={vec(0, size)} colors={colors}/>
        </Path>
      </Canvas>
    </View>);
};
export default ControllerGradientPath;
