//@ts-nocheck
import React, { useCallback, useMemo } from "react";
import { Text } from "@huum/components/atoms/text";
import { ModalContainer } from "./modal-container";
import { translate } from "@huum/i18n";
import { Box, Row, Spacer, Stack } from "native-base";
import { useStores } from "@huum/store";
import { getModalStyles } from "./styles";
import { useThemedStyles } from "@huum/hooks/useThemedStyles";
import dayjs from "@huum/utils/date";
import { isEmpty, map, toNumber, upperFirst } from "lodash";
import Button from "@huum/components/atoms/button";
import { Touchable } from "@huum/components/atoms/touchable/touchable";
import { navigate } from "@huum/services/NavigationService";
import { useBookingModify } from "@huum/hooks/api/useBookingModify";
import { getTemperatureUnit, getTemperatureValue } from "@huum/utils/helpers/sauna-temperature-helpers";
import { formatDate } from "@huum/utils/date";
import { openLinkingUrl } from "@huum/utils/linking";
import { useSaunaStatus } from "@huum/hooks/api/useSaunaStatus";
// some headers do not have trnaslations 
const BookingDetailModal = (props) => {
    const { isMine, startTime, endTime, title, ownerName, userPhone, userEmail, quests, comments, repeatDuration, parentId, targetTemperature, } = props;
    const { modal, sauna } = useStores();
    const { deleteBookingAndUpcoming, deleteBookingException, deleteBooking } = useBookingModify();
    const { isPrivate } = useSaunaStatus(true);
    const styles = useThemedStyles(getModalStyles);
    const handleEditPress = useCallback(() => {
        navigate("createBooking", { event: props });
        modal.closeModal();
    }, [props]);
    const handleDeletePress = useCallback(() => {
        if (repeatDuration > 0) {
            modal.openModal("confirmModal", {
                onConfirm: () => deleteBookingException(props),
                onCancel: () => deleteBookingAndUpcoming(props),
                modalTitle: translate("text.you_are_deleting_a_repeating_booking"),
                contentText: translate("text.delete_single_event_or_all"),
                confirmButtonLabel: translate("label.single"),
                cancelButtonLabel: translate("label.all"),
            });
        }
        else if (parentId) {
            deleteBookingException(props);
        }
        else {
            deleteBooking(props);
        }
    }, [props, deleteBooking]);
    const handleContactHost = useCallback(() => {
        const phoneChoice = () => openLinkingUrl(`tel:${userPhone}`);
        const emailChoice = () => openLinkingUrl(`mailto:${userEmail}`);
        const modalTitle = translate("label.contact_host");
        const contentText = translate("text.contact_host_by_phone_or_email");
        const confirmButtonLabel = translate("label.phone");
        const cancelButtonLabel = translate("label.email");
        modal.openModal("confirmModal", {
            onConfirm: phoneChoice,
            onCancel: emailChoice,
            modalTitle,
            contentText,
            confirmButtonLabel,
            cancelButtonLabel,
        });
    }, [userPhone, userEmail]);
    const guestsList = useMemo(() => {
        if (!isEmpty(quests)) {
            return (<Box mt={8}>
          <Text style={styles.dimmedLargeText}>
            {translate("label.guests")}
          </Text>
          <Spacer h={2}/>
          {map(quests, (guest) => (<Text style={styles.personText}>{guest}</Text>))}
        </Box>);
        }
        else
            null;
    }, [quests]);
    const displayButtons = useMemo(() => {
        if (isMine || isPrivate) {
            return (<Stack alignItems="center" space={6}>
          <Button onPress={handleEditPress} label={translate('label.edit_booking')}/>
          <Touchable justifyContent={"center"} alignItems={"center"} onPress={handleDeletePress}>
            <Text style={styles.buttonText}>
              {translate("label.delete_booking")}
            </Text>
          </Touchable>
        </Stack>);
        }
        else {
            return <Button onPress={handleContactHost} label={translate("label.contact_host")}/>;
        }
    }, []);
    const commentsList = useMemo(() => {
        if (!isEmpty(comments)) {
            return (<Box mt={8}>
          <Text style={styles.dimmedLargeText}>
            {translate("label.comments")}
          </Text>
          <Spacer h={2}/>
          {map(comments, (comment) => (<Text style={styles.personText}>{comment}</Text>))}
        </Box>);
        }
        else
            null;
    }, [comments]);
    const InfoItem = ({ label, value, align }) => {
        return (<Stack>
        <Text fontSize="xs" lineHeight="sm" color="dimmedText" textAlign={align}>
          {label}
        </Text>
        <Text fontSize="md" textAlign={align} bold>
          {value}
        </Text>
      </Stack>);
    };
    return (<ModalContainer title={translate("label.bookings")} closeModal={modal.closeModal}>
      <Stack paddingX={4} space={8}>
        <Text style={styles.titleText}>{title}</Text>
        <Row justifyContent="space-between">
          <InfoItem label={upperFirst(formatDate(dayjs.unix(startTime), "dddd"))} value={formatDate(dayjs.unix(startTime), "MMMM DD")}/>
          <InfoItem align="right" label={translate('text.heating_time')} value={`${formatDate(dayjs.unix(startTime), "HH:mm")} - ${formatDate(dayjs.unix(endTime), "HH:mm")}`}/>
        </Row>
        {guestsList}
        <Row justifyContent="space-between">
          <InfoItem label={translate('text.hosted_by')} value={ownerName}/>
          {toNumber(targetTemperature) > 0 && (<Stack alignItems="flex-end">
              <Text fontSize="xs" lineHeight="sm" color="dimmedText" textAlign="right">
                {translate('text.target_temperature')}
              </Text>
              <Row justifyContent="flex-end" alignItems="center">
                <Text textAlign="right" bold>
                    {getTemperatureValue(toNumber(targetTemperature), sauna.temperatureUnit)}
                    
                </Text>
                <Text textAlign="right" fontSize="md">
                    {getTemperatureUnit(sauna.temperatureUnit)}
                </Text>
              </Row>
            </Stack>)}
        </Row>
        {commentsList}
        <Box mt={8}>{displayButtons}</Box>
      </Stack>
    </ModalContainer>);
};
export default BookingDetailModal;
