import SaunaStatusCode from "@huum/utils/sauna-status-code";
import { Box, Stack } from "native-base";
import { Dimensions } from "react-native";
import { isTablet } from 'react-native-device-info';
import SaunaBlockedSection from "../sauna-blocked-section";
import Loader from "@huum/components/organisms/loader";
import TemperatureController from "@huum/components/organisms/sauna-controller/temperature-controller";
import PowerLevelController from "@huum/components/organisms/sauna-controller/power-level-controller";
import { toNumber } from "lodash";
import { DEFAULT_CONTROLLER_WIDTH, MAX_CONTROLLER_WIDTH } from "@huum/components/organisms/sauna-controller/helpers";
import { useSaunaInfoSections } from "@huum/hooks/useSaunaInfoSections";
import { useStores } from "@huum/store";
import AnimatedInfoSection from "@huum/components/organisms/animated-info-section";
const { width: SCREEN_WIDTH } = Dimensions.get('screen');
const SaunaController = (props) => {
    const { onStartSauna, onStopSauna, onSaunaTemperatureChange, width, isDisabled, topOffset = 0, saunaStatus = {}, } = props;
    const { sauna } = useStores();
    const { statusCode, targetTemperature } = saunaStatus;
    const temperature = sauna.latestTargetTemp || targetTemperature;
    const infoSections = useSaunaInfoSections({ saunaStatus });
    const controllerType = toNumber(sauna.saunaId) >= 500000 ? "power" : "temperature";
    const controllerWidth = isTablet() ? Math.min(width * 0.6, MAX_CONTROLLER_WIDTH) : DEFAULT_CONTROLLER_WIDTH;
    const Controller = controllerType === "power" ? PowerLevelController : TemperatureController;
    if (!sauna.saunaId || !statusCode)
        return <Loader />;
    if (saunaStatus.statusCode === SaunaStatusCode.SAUNA_OFFLINE
        || saunaStatus.statusCode === SaunaStatusCode.SAUNA_EMERGENCY_STOP) {
        return <SaunaBlockedSection statusCode={saunaStatus.statusCode}/>;
    }
    return (<Stack>
      <Controller handleSaunaStartPress={onStartSauna} handleSaunaStopPress={onStopSauna} onTemperatureChange={onSaunaTemperatureChange} topOffset={topOffset} width={controllerWidth} {...saunaStatus} targetTemperature={temperature}/>
      {infoSections && (<Box position="absolute" bottom={-6} left={0} right={0}>
          {!isDisabled &&
                <AnimatedInfoSection contentSections={infoSections}/>}
      </Box>)}
      {isDisabled && (<Box position="absolute" top={0} left={0} right={0} bottom={0} background="grey.5" opacity={0.7}/>)}
    </Stack>);
};
export default SaunaController;
