import Button from "@huum/components/atoms/button";
import { Text } from "@huum/components/atoms/text";
import { useLedController } from "@huum/features/led-controller/hooks/useLedController";
import { translate } from "@huum/i18n";
import { map } from "lodash";
import { Pressable, ScrollView, Spinner, Stack } from "native-base";
import { useEffect } from "react";
const DevicesList = (props) => {
    const { onConnectDevice } = props;
    const { devices, scanDevices, isScanningDevices, connectDevice } = useLedController();
    useEffect(() => {
        scanDevices();
    }, []);
    const handleDeviceConnection = (id, name) => () => {
        onConnectDevice && onConnectDevice();
        connectDevice(id, name);
    };
    return (<ScrollView>
      <Stack space={4} px={4}>
        {(devices?.length > 0 || isScanningDevices) && (<Text preset="caption">{translate("text.nearby_led_devices")}</Text>)}
        {devices?.length === 0 && !isScanningDevices && (<Text preset="caption">
            {translate("text.no_nearby_led_devices")}
          </Text>)}
        {map(devices, (device) => (<Pressable key={device.id} onPress={handleDeviceConnection(device.id, device.name)}>
            <Text>{device.name || device.id}</Text>
          </Pressable>))}

        {isScanningDevices && <Spinner color="primaryDark"/>}

        {!isScanningDevices && (<Stack alignItems={{
                md: "center",
                sm: "start"
            }}>
            <Button mt={8} onPress={scanDevices} label={translate("button.scan_devices")}/>
          </Stack>)}
      </Stack>
    </ScrollView>);
};
export default DevicesList;
