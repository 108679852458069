import React from "react";
import { Heading, Row } from "native-base";
import { BaseHeader } from "./base-header";
import { useRoute } from "@react-navigation/native";
import { translate } from "@huum/i18n";
/**
 * Header that appears on many screens. Will hold navigation buttons and screen title.
 */
export function NavigationHeader(props) {
    const { title, isRoute = true, onBackPress, withBackNavigation = true, RightElement, } = props;
    const route = isRoute ? useRoute() : { name: "" };
    const screenName = title ?? (route.name && translate(`navigation.${route.name}`));
    return (<BaseHeader withBackNavigation={withBackNavigation} onBackPress={onBackPress} isPlain>
     <Row alignItems="center" justifyContent="space-between" flex={1}>
        <Heading>{screenName}</Heading>
        {RightElement}
     </Row>
    </BaseHeader>);
}
;
