import { emergencyBreakRequest, saunaSessionRequest, stopSaunaSessionRequest } from "@huum/api/requests/sauna";
import { useStores } from "@huum/store";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { get, toNumber } from "lodash";
import dayjs from "dayjs";
import { useCallback } from "react";
import { translate } from "@huum/i18n";
export const useSaunaController = (defaultSaunaId, status) => {
    const { mutateAsync: requestSaunaSession } = useMutation({
        mutationFn: saunaSessionRequest,
    });
    const { mutateAsync: emergencyBreak } = useMutation({
        mutationFn: emergencyBreakRequest,
    });
    const { mutateAsync: stopSauna } = useMutation({
        mutationFn: stopSaunaSessionRequest,
    });
    const { door, remoteSafetyState } = status || {};
    const { auth, sauna, modal } = useStores();
    const session = get(auth, "sessionHash", null);
    const saunaId = defaultSaunaId || sauna.saunaId;
    const statusQueryKey = !saunaId ? [`saunasList`, "all"] : [`saunastatus`, saunaId];
    const queryClient = useQueryClient();
    const startSaunaSession = async (values) => {
        const fallBackDuration = sauna.latestDuration > 0 ? sauna.latestDuration : 180;
        const start = dayjs(values.start || undefined);
        if (!door) {
            return modal.openModal("iconModal", {
                icon: "door-open",
                contentText: translate("text.door_is_open"),
            });
        }
        if (remoteSafetyState === "notSafe") {
            return modal.openModal("iconModal", {
                icon: "stopped",
                contentText: translate("error.sauna_not_safe"),
                infoText: translate("error.sauna_not_safe_info"),
            });
        }
        if (start.isBefore(dayjs())) {
            start.add(1, "day"); // this mutates start so we don't need to change the end separately
        }
        const end = dayjs(values.end || dayjs(start).add(fallBackDuration, 'minutes'));
        await requestSaunaSession({
            session,
            type: "start",
            startDate: start.unix(),
            endDate: end.unix(),
            targetTemperature: values?.targetTemperature || sauna.latestTargetTemp,
            saunaId: saunaId,
            humidity: values?.humidity,
        });
        queryClient.refetchQueries({ queryKey: statusQueryKey });
        sauna.setLatestDuration(end.diff(start, 'minutes'));
        sauna.setLatestTargetTemp(toNumber(values?.targetTemperature) || sauna.latestTargetTemp);
    };
    const stopSaunaSession = useCallback(async () => {
        await stopSauna({ session, saunaId: saunaId });
        queryClient.refetchQueries({ queryKey: statusQueryKey });
    }, [stopSauna, statusQueryKey]);
    const modifySaunaSession = useCallback(async (saunaStatus, updateData) => {
        await requestSaunaSession({
            session,
            type: "modify",
            saunaId: saunaId,
            ...saunaStatus,
            ...updateData,
        });
        queryClient.invalidateQueries({ queryKey: statusQueryKey });
    }, []);
    const pullEmergencyBreak = useCallback(async () => {
        await emergencyBreak({ session, saunaId: saunaId });
        queryClient.invalidateQueries({ queryKey: statusQueryKey });
    }, []);
    return {
        startSaunaSession,
        stopSaunaSession,
        modifySaunaSession,
        pullEmergencyBreak,
        defaultDuration: sauna.latestDuration,
        defaultTargetTemp: sauna.latestTargetTemp,
    };
};
