import { Text } from "@huum/components/atoms/text";
import { translate } from "@huum/i18n";
import SaunaStatusCode from "@huum/utils/sauna-status-code";
import { Center, Image, Stack } from "native-base";
const getBlockedContentText = (statusCode, paymentIsRequired) => {
    switch (statusCode) {
        case SaunaStatusCode.SAUNA_OFFLINE:
            return {
                title: translate('text.sauna_is_offline'),
                info: translate('text.check_sauna_connection'),
            };
        case SaunaStatusCode.SAUNA_EMERGENCY_STOP:
            return {
                title: translate('text.sauna_emergency_stop_info'),
            };
        default:
            if (paymentIsRequired) {
                return {
                    title: translate('text.sauna_is_offline'),
                    info: translate('text.payment_required_info'),
                };
            }
            return {};
    }
};
const SaunaBlockedSection = (props) => {
    const { title, info } = getBlockedContentText(props.statusCode, props.paymentIsRequired);
    return (<Stack space={2} padding={4} alignItems="center" justifyContent="center">
      <Text fontWeight="bold">
        {title}
      </Text>
      <Text textAlign="center">
        {info}
      </Text>
      <Center mt={4}>
        <Image height="106px" width="75px" alt="drop" resizeMode="contain" source={require('@huum/assets/legacy/drop-red.png')}/>
        <Image width="45px" height="45px" top={10} alt="alert" resizeMode="contain" position="absolute" source={require('@huum/assets/legacy/alert-bold.png')}/>
      </Center>
    </Stack>);
};
export default SaunaBlockedSection;
