//@ts-nocheck
import React from 'react';
import { translate } from '@huum/i18n';
import { ModalContainer } from './modal-container';
import { Center, Image, Stack } from 'native-base';
import { Text } from '@huum/components/atoms/text';
import SlideButton from 'rn-slide-button';
import colors from '@huum/theme/huum/colors';
const EmergencyStopModal = (props) => {
    const { closeModal, onStop } = props;
    const onSlideComplete = () => {
        closeModal();
        onStop();
    };
    return (<ModalContainer closeModal={closeModal} title={translate('label.emergency_stop')}>
      <Stack space={4}>
          <Center>
            <Image resizeMode="contain" size={20} source={require('@huum/assets/legacy/drop-red.png')}/>
            <Image bottom={4} height={8} resizeMode="contain" position="absolute" source={require('@huum/assets/legacy/alert-bold.png')}/>
          </Center>
          <Center>
            <Text bold textAlign="center" preset="body1">{translate('text.about_to_stop_sauna')}</Text>
            <Text textAlign="center" preset="subtitle1">{translate('text.sauna_control_will_be_disabled')}</Text>
            <Text textAlign="center" preset="subtitle1">{translate('text.to_restart_sauna_use_control')}</Text>
          </Center>
          <Stack mt={4}>
            <SlideButton title={translate('button.swipe_to_stop_sauna')} onReachedToEnd={onSlideComplete} containerStyle={{ backgroundColor: colors.red[600] }} underlayStyle={{ backgroundColor: colors.red[600] }}/>
          </Stack>
        </Stack>
    </ModalContainer>);
};
export default EmergencyStopModal;
