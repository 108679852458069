import { Icon } from "@huum/components/atoms/icon";
import { hitSlop } from "@huum/theme/helpers/styles";
import React from "react";
import { Pressable } from "react-native";
export const IconButton = (props) => {
    const { onPress, icon, visible = true, disabled, ...rest } = props;
    if (!visible) {
        return null;
    }
    return (<Pressable onPress={onPress} hitSlop={hitSlop}>
      <Icon icon={icon} {...rest}/>
    </Pressable>);
};
