import * as React from "react";
import { View, Image, StyleSheet } from "react-native";
import icons from "@huum/assets/icons";
import { useTheme } from "native-base";
import { get } from "lodash";
const ROOT = {
    resizeMode: "contain",
};
export function Icon(props) {
    const { style: styleOverride, icon, containerStyle, tint, size = 16, ...rest } = props;
    const { colors } = useTheme();
    if (!icon)
        return null;
    const iconStyle = StyleSheet.flatten([
        ROOT,
        styleOverride,
        { height: size * 2, width: size * 2 },
        { tintColor: get(colors, tint || '') || tint },
    ]);
    return (<View style={containerStyle} {...rest}>
      <Image style={iconStyle} source={icons[icon]}/>
    </View>);
}
