import React, { useMemo } from 'react';
import dayjs from "@huum/utils/date";
import { range, map, includes, toString, } from 'lodash';
import { Touchable } from '@huum/components/atoms/touchable/touchable';
import { Box, ScrollView, View } from 'native-base';
import { Text } from '@huum/components/atoms/text';
import { useStores } from '@huum/store';
import { useBookingEventsAgendaStyles } from './styles';
import { navigate } from '@huum/services/NavigationService';
const BookingEventsAgenda = (props) => {
    const { activeDate, unAvailableHours, events, onNewEventPress, onEventPress } = props;
    const contentOffset = {
        x: 0,
        y: dayjs(activeDate).isSame(dayjs(), 'day') ? dayjs().hour() * 60 : 0
    };
    const { modal: { openModal } } = useStores();
    const styles = useBookingEventsAgendaStyles();
    const getEventParameters = (event) => {
        const start = dayjs.unix(event.startTime).isBefore(dayjs(activeDate), 'day')
            ? dayjs(activeDate).startOf('day')
            : dayjs.unix(event.startTime);
        const end = dayjs.unix(event.endTime).isAfter(dayjs(activeDate), 'day')
            ? dayjs(activeDate).endOf('day').add(1, 'hours')
            : dayjs.unix(event.endTime);
        return {
            event,
            start,
            end,
            duration: end.diff(start, 'hours'),
        };
    };
    const handleNewBookingPress = (hour) => () => {
        const date = dayjs(activeDate);
        const startTime = date.set('hour', hour).startOf('hour').unix();
        navigate("createBooking", { event: { startTime } });
    };
    const BookingListItem = ({ hour }) => {
        const hasEvent = includes(unAvailableHours, hour);
        const isInPast = dayjs(activeDate).set('hour', hour).isBefore(dayjs());
        return (<Touchable height="60px" justifyContent="center" onPress={handleNewBookingPress(hour)} disabled={hasEvent || isInPast} opacity={hasEvent || isInPast ? 0.5 : 1} key={toString(hour)}>
        <View height="1px" width="full" backgroundColor="primaryLight"/>
        <Box position="absolute" left={4} backgroundColor="white.100" padding={2}>
          <Text>
            {`${hour}:00`}
          </Text>
        </Box>
        
      </Touchable>);
    };
    const EventItem = (eventData) => {
        const { duration, start, end, event, } = getEventParameters(eventData);
        const eventHeight = Math.max(duration, 1) * 60;
        const eventOffset = (dayjs(start).get('hour') + (dayjs(start).get('minute') / 60)) * 60 + 45; // 60 is EventItem height. 45 is correction pixels
        const originalStartTime = start.format('HH:mm');
        const originalEndTime = end.format('HH:mm');
        const eventVerticalPadding = eventHeight === 60 ? 3 : 4;
        const eventTimesMargin = eventHeight === 60 ? 0 : 2;
        const eventTimesFontSize = eventHeight === 60 ? 'xs' : 'sm';
        return (<Touchable height={eventHeight || 0} top={eventOffset || 0} position="absolute" width={233} right={16} shadow={2} backgroundColor="primaryLight" paddingX={4} paddingY={eventVerticalPadding} borderRadius={16} onPress={onEventPress(event)} key={`${event.title}-${event.startTime}-${event.id}`}>
        <Text bold lineHeight="md">
          {event.title}
        </Text>
       {eventHeight >= 60 && (<Text color="white.100" fontSize={eventTimesFontSize} lineHeight="xs" marginTop={eventTimesMargin}>
              {`${originalStartTime}-${originalEndTime}`}
            </Text>)}
      </Touchable>);
    };
    const Events = useMemo(() => {
        return map(events, (event) => EventItem(event));
    }, [events]);
    const BookingHours = useMemo(() => map(range(0, 24), (value) => <BookingListItem key={value} hour={value}/>), [activeDate, unAvailableHours]);
    return (<ScrollView contentOffset={contentOffset} background="white.100">
      {BookingHours}
      {Events}
    </ScrollView>);
};
export default BookingEventsAgenda;
