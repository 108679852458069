import Button from "@huum/components/atoms/button";
import { Input } from "@huum/components/atoms/input/input";
import { Text } from "@huum/components/atoms/text";
import { Switch } from "@huum/components/molecules/switch";
import { useLedController } from "@huum/features/led-controller/hooks/useLedController";
import { translate } from "@huum/i18n";
import { navigate } from "@huum/services/NavigationService";
import { useStores } from "@huum/store";
import { observer } from "mobx-react-lite";
import { Row, ScrollView, Spinner, Stack } from "native-base";
import { useState } from "react";
const DeviceSettingsView = (props) => {
    const { isRgbwEnabled, isPairingRemote, setRgbwType, setRemotePairing, resetDeviceToFactorySettings, setLedDeviceName, disconnectDevice, } = useLedController();
    const { modal, iot } = useStores();
    const [deviceName, setDeviceName] = useState(iot.connectedDeviceName || "");
    const [isRgbwType, setIsRgbwType] = useState(isRgbwEnabled);
    const handleDeviceRgbMode = (isRgbw) => {
        setIsRgbwType(isRgbw);
        setRgbwType(isRgbw);
    };
    const handleToggleRemotePairing = () => {
        setRemotePairing(!isPairingRemote);
    };
    const handleFactoryReset = () => {
        modal.openModal("confirmModal", {
            onConfirm: resetDeviceToFactorySettings,
            modalTitle: translate("label.factory_reset"),
            contentText: translate("text.led_factory_reset_confirmation"),
            confirmButtonLabel: translate("label.reset"),
        });
    };
    const handleSetMultipleChannels = (value) => {
        iot.setUseMultipleChannels(value);
        iot.setActiveChannel(value ? 1 : 0);
    };
    const handleDeviceNameUpdate = () => {
        iot.setConnectedDeviceName(deviceName);
        setLedDeviceName(deviceName);
    };
    const handleConnectToOtherDevice = () => {
        disconnectDevice();
        navigate("ledController", { showList: true });
    };
    return (<ScrollView>
      <Stack space={4} px={4}>
        <Input defaultValue={deviceName} placeholder={translate("label.device_name")} onChangeText={setDeviceName} onBlur={handleDeviceNameUpdate} maxLength={10} InputRightElement={<Text mr={2} fontSize="sm">{12 - deviceName.length}</Text>}/>
        <Row alignItems="center" justifyContent="space-between" space={4}>
          <Text>{translate("label.rgbw_mode")}</Text>
          <Switch value={isRgbwType} onToggle={handleDeviceRgbMode}/>
        </Row>
        {isRgbwType && (<Row mt={4} alignItems="center" justifyContent="space-between" space={4}>
              <Text>{translate("label.rgbw_channels")}</Text>
              <Switch value={iot.useMutlipleChannels} onToggle={handleSetMultipleChannels}/>
            </Row>)}
        <Row alignItems="center" justifyContent="space-between" space={4}>
          <Text>{translate("label.pair_remote")}</Text>
          <Row>
          {isPairingRemote && (<Spinner color="primaryDark"/>)}
            <Button variant="ghost" label={!!isPairingRemote ? translate("label.stop_pair") : translate("label.pair")} onPress={handleToggleRemotePairing}/>
          </Row>
        </Row>

        <Row alignItems="center" justifyContent="space-between" space={4}>
          <Text>{translate("label.factory_reset")}</Text>
          <Button variant="ghost" label={translate("label.reset")} onPress={handleFactoryReset} colorScheme="red"/>
        </Row>

        <Row alignItems="center" justifyContent="space-between" space={4}>
          <Text>{translate("label.connect_to_other_device")}</Text>
          <Button variant="ghost" label={translate("label.connect")} onPress={() => handleConnectToOtherDevice()} colorScheme="green"/>
        </Row>
      </Stack>
    </ScrollView>);
};
export default observer(DeviceSettingsView);
