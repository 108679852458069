export default {
    name: 'huum',
    logo: require('./assets/logo.png'),
    helpEmail: 'support@huum.eu',
    contact: {
        et: "https://huum.ee/huum-saunakeriste-edasimuujad/",
        en: "https://huum.eu/distributors-of-huum-sauna-heaters-and-sauna-stoves/",
        de: "https://huum.de/vertriebspartner/",
        fi: "https://huum.eu/distributors-of-huum-sauna-heaters-and-sauna-stoves/",
        fr: "https://huum.eu/distributors-of-huum-sauna-heaters-and-sauna-stoves/",
        it: "https://huum.eu/distributors-of-huum-sauna-heaters-and-sauna-stoves/",
        pl: "https://huum.eu/distributors-of-huum-sauna-heaters-and-sauna-stoves/",
        ru: "https://huum.eu/distributors-of-huum-sauna-heaters-and-sauna-stoves/",
    },
    support: {
        et: "https://huum.ee/klienditugi/",
        en: "https://huum.eu/support/",
        de: "https://huum.de/support/",
        fi: "https://huum.eu/support/",
        fr: "https://huum.eu/support/",
        it: "https://huum.eu/support/",
        pl: "https://huum.eu/support/",
        ru: "https://huum.eu/support/",
    },
    fontFamily: 'Manrope',
    secondaryFontFamily: 'Manrope',
    serverCost: 29,
};
