import { StyleSheet, Dimensions } from "react-native";
import { palette } from '@huum/theme/helpers';
const { width } = Dimensions.get("screen");
const svgConfig = {
    strokeWidth: 3,
    strokeLinejoin: 'bevel',
};
export const svgGreenConfig = {
    ...svgConfig,
    stroke: palette.primaryDark,
};
export const svgRedConfig = {
    ...svgConfig,
    stroke: palette.invalid,
};
export const yAxisSVG = {
    fontWeight: "bold",
    fill: palette.primaryDark,
};
export const xAxisSVG = {
    fill: palette.dimmedText,
};
export const getStatisticsLineGraphStyles = (theme) => {
    const { colors, space, shadows } = theme;
    return StyleSheet.create({
        graphContainer: {
            height: 300,
            flexDirection: "row",
            backgroundColor: palette.white[100],
            width: width,
        },
        lineChart: {
            height: 280,
        },
        yAxis: {
            height: 280,
            paddingLeft: 10,
            width: 40,
        },
        slider: {
            width: "100%",
        },
    });
};
