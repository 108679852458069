import api from "@huum/services/api";
import { getGeneralApiProblem } from "@huum/services/api/api-problem";
export async function userSafetyNotificationChange(data) {
    const response = await api.huum.post("action/save-settings", {
        ...data,
        version: 3,
    });
    if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem)
            throw problem;
    }
    return response.data;
}
export async function getCustomerInfo(data) {
    const response = await api.huum.get("payment/get_customer", {
        ...data,
        version: 3,
    });
    if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem)
            throw problem;
    }
    return response.data;
}
export async function getClientToken(data) {
    const { saunaId, ...rest } = data;
    const response = await api.huum.get("payment/get_client_token", {
        ...rest,
        version: 3,
        saunaId,
    });
    if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem)
            throw problem;
    }
    return response.data;
}
export async function getPaymentAmount(data) {
    const response = await api.huum.get("payment/get_amount", {
        ...data,
        version: 3,
    });
    if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem)
            throw problem;
    }
    return response.data;
}
export async function savePaymentNonce(data) {
    const response = await api.huum.post("payment/save_payment_nonce", {
        ...data,
        version: 3,
    });
    if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem)
            throw problem;
    }
    return response.data;
}
