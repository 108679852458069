import { Icon } from "@huum/components/atoms/icon";
import { Text } from "@huum/components/atoms/text";
import { Row, Slider } from "native-base";
import { useCallback, useState } from "react";
const SliderSelect = (props) => {
    const { disabled, value, onChange, icon, defaultValue, unit = '℃', showValue = true, onProgress, min = 0, max = 100, ...rest } = props;
    const [sliderValue, setSliderValue] = useState(value || defaultValue || 0);
    const onChangeValue = useCallback((newValue) => {
        setSliderValue(newValue);
        onProgress && onProgress(newValue);
    }, [onProgress]);
    const handleOnChangeEnd = useCallback((newValue) => {
        onChange && onChange(newValue);
    }, [onChange]);
    return (<Row space={4} width="full" alignItems="center">
      <Icon icon={icon}/>
      <Slider w="3/4" {...rest} minValue={min} maxValue={max} value={sliderValue} onChange={onChangeValue} onChangeEnd={handleOnChangeEnd}>
        <Slider.Track>
          <Slider.FilledTrack background="primaryDark"/>
        </Slider.Track>
        <Slider.Thumb borderWidth="0" background="primaryDark"/>
      </Slider>
      {showValue && <Text>{`${sliderValue}${unit}`}</Text>}
    </Row>);
};
export default SliderSelect;
