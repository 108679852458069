import { useStores } from "@huum/store";
import { useMutation } from "@tanstack/react-query";
import { get } from "lodash";
import { useCallback } from "react";
import { verifyEmail } from "@huum/api/requests/profile";
export const useVerifyEmail = () => {
    const { mutate, isError, isSuccess } = useMutation({
        mutationFn: verifyEmail,
    });
    const { auth } = useStores();
    const session = get(auth, "sessionHash", null);
    const emailVerify = useCallback(() => {
        mutate(session);
    }, []);
    return { emailVerify, isError, isSuccess };
};
