import { types } from "mobx-state-tree";
/**
 * Model description here for TypeScript hints.
 */
export const LedControllerStoreModel = types
    .model("LedControllerStore")
    .props({
    connectedDeviceId: types.maybeNull(types.string),
    connectedDeviceName: types.maybeNull(types.string),
    connectedDeviceActive: types.optional(types.boolean, false),
    useMutlipleChannels: types.optional(types.boolean, false),
    useRgbw: types.optional(types.boolean, false),
    activeChannel: types.optional(types.number, 0),
    isLedEnabled: types.optional(types.boolean, false),
})
    .views(self => ({
    get isConnected() {
        return !!self.connectedDeviceId && self.connectedDeviceActive;
    }
})) // eslint-disable-line @typescript-eslint/no-unused-vars
    .actions(self => ({
    setConnectedDeviceId(id, connectedDeviceName) {
        self.connectedDeviceId = id || null;
        self.connectedDeviceName = connectedDeviceName || null;
        self.connectedDeviceActive = !!id;
    },
    setLedEnabled(value) {
        self.isLedEnabled = value;
    },
    setConnectionInactive() {
        self.connectedDeviceActive = false;
    },
    setUseMultipleChannels(value) {
        self.useMutlipleChannels = value;
    },
    setRgbwType(value) {
        self.useRgbw = value;
    },
    setActiveChannel(channel) {
        self.activeChannel = channel;
    },
    setConnectedDeviceName(name) {
        self.connectedDeviceName = name;
    }
})); // eslint-disable-line @typescript-eslint/no-unused-vars
export const createLedControllerStoreDefaultModel = () => types.optional(LedControllerStoreModel, {});
