import { Icon } from "@huum/components/atoms/icon";
import { Text } from "@huum/components/atoms/text";
import { IconButton } from "@huum/components/molecules/icon-button";
import { translate } from "@huum/i18n";
import { navigate } from "@huum/services/NavigationService";
import { useStores } from "@huum/store";
import { getHitSlop } from "@huum/utils/hitSlop";
import SaunaStatusCode from "@huum/utils/sauna-status-code";
import { observer } from "mobx-react-lite";
import { Row, useTheme } from "native-base";
import { useCallback } from "react";
import { Pressable } from "react-native";
const TopButtonsRow = observer((props) => {
    const { door, showClose, statusCode, onBluetoothPress, remoteSafetyState } = props;
    const { colors } = useTheme();
    const { modal, sauna } = useStores();
    const handleShowDoorOpenModal = useCallback(() => {
        if (!door) {
            modal.openModal('iconModal', {
                icon: "door-open",
                contentText: translate('text.door_is_open')
            });
        }
    }, [door]);
    const handleShowRemoteSafetyModal = useCallback(() => {
        if (remoteSafetyState === "unSafe") {
            modal.openModal('iconModal', {
                icon: "stopped",
                contentText: translate('error.sauna_not_safe_info'),
            });
        }
    }, [remoteSafetyState]);
    const saunaInUse = statusCode === SaunaStatusCode.SAUNA_ACTIVE_BLOCKED;
    const title = saunaInUse ? translate('title.sauna_in_use') : "";
    return (<Row p={5}>
      <Row space={4} flex={1}>
        {/* <IconButton
          onPress={() => {}}
          icon="heater"
          tint={colors.primaryDark}
          disabled
        /> */}
        {!!sauna.ledDevice &&
            (<IconButton onPress={onBluetoothPress} icon="bluetooth" tint={colors.primaryDark}/>)}
        {remoteSafetyState === "unSafe" && (<IconButton onPress={handleShowRemoteSafetyModal} icon="stopped" 
        // size={10}
        tint={colors.primaryDark}/>)}
        {!door && <IconButton onPress={handleShowDoorOpenModal} icon="door-open" tint={door ? colors.primaryDark : colors.invalid}/>}
      </Row>
      <Row space={4} justifyContent="flex-end" alignItems="center">
        <Pressable hitSlop={getHitSlop(8)} onPress={() => navigate("saunaControllersList")}>
          <Row alignItems="center" space={4}>
            <Text>
              {`${translate("label.id")}: ${sauna.saunaId}`}
            </Text>
            <Icon size={8} icon="down"/>
          </Row>
        </Pressable>
      </Row>
    </Row>);
});
export default TopButtonsRow;
