import { StyleSheet } from "react-native";
import { palette } from '@huum/theme/helpers';
export const getStatisticsStyles = (theme) => {
    const { colors, space, shadows } = theme;
    return StyleSheet.create({
        averagesSection: {
            flexDirection: "row",
            flexWrap: "wrap",
        },
        averageItemContainer: {
            width: "50%",
            alignItems: "center",
            justifyContent: "flex-start",
            paddingBottom: space[8],
        },
        infoText: {
            color: palette.dimmedText,
            fontSize: 14,
            lineHeight: 20,
            marginTop: space[1],
            textAlign: "center"
        },
        sessionText: {
            fontSize: 14,
            lineHeight: 20,
        },
        selectorContainer: {
            justifyContent: "space-between",
            alignItems: "center",
            borderRadius: 16,
            paddingHorizontal: 24,
            backgroundColor: colors.white["100"],
            ...shadows[6],
        },
        scrollContainer: {
            paddingTop: 0,
        }
    });
};
