import React, { useCallback, useState } from "react";
import { Box, Row } from 'native-base';
import { Icon } from "@huum/components/atoms/icon";
import { Text } from "@huum/components/atoms/text";
import SliderSelect from "@huum/components/molecules/slider-select";
const EffectSpeedController = (props) => {
    const { defaultValue = 1, onChange } = props;
    const [effectSpeed, setEffectSpeed] = useState(defaultValue || 1);
    const onSlideComplete = useCallback((value) => {
        setEffectSpeed(value);
        onChange(value);
    }, [onChange]);
    return (<Row alignItems="center" width="full" justifyContent="space-between">
        <Box width={8} alignItems="center">
          <Icon size={10} tint="primaryDark" icon="timer"/>
        </Box>
        <Box flex={1} px={4}>
          <SliderSelect step={1} min={0} max={5} showValue={false} value={effectSpeed} onProgress={setEffectSpeed} onChange={onSlideComplete}/>
        </Box>
        <Box width={10}>
          <Text fontSize="sm">{`${effectSpeed} x`}</Text>
        </Box>
      </Row>);
};
export default EffectSpeedController;
