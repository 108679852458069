import api from "@huum/services/api";
import { getGeneralApiProblem } from "@huum/services/api/api-problem";
import messaging from "@react-native-firebase/messaging";
import { Platform } from "react-native";
export async function loginUserWithEmail(data) {
    const response = await api.huum.post("/action/login", data);
    if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem)
            throw problem;
    }
    return response.data;
}
export async function registerUserWithEmail(data) {
    const response = await api.huum.get("/user/register", data);
    if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem)
            throw problem;
    }
    return response.data;
}
export async function getUserInfo(username) {
    const response = await api.huum.get("/user/info", {
        username,
    });
    if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem)
            throw problem;
    }
    return response.data;
}
export async function submitUserInfo(data) {
    const response = await api.huum.post("/user/info", data);
    if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem)
            throw problem;
    }
    return response.data;
}
export async function passwordForgot(username) {
    const response = await api.huum.post("/user/password/forgot", {}, { params: { username } });
    if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem)
            throw problem;
    }
    return response.data;
}
export async function passwordChange(data) {
    const { username, password, token } = data;
    const response = await api.huum.post("/user/password/recover", { username, password }, { params: { password_recovery_key: token } });
    if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem)
            throw problem;
    }
    return response.data;
}
export async function register(data) {
    const response = await api.huum.post("/action/register", {
        version: 3,
        ...data,
    });
    if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem)
            throw problem;
    }
    return response.data;
}
export async function fcmTokenInitiation(session) {
    if (Platform.OS === "web")
        return;
    const permissionStatus = await messaging().hasPermission();
    if (permissionStatus === messaging.AuthorizationStatus.NOT_DETERMINED) {
        await messaging().requestPermission();
    }
    const fcmToken = await messaging().getToken();
    const response = await api.huum.post("/action/save_fcm_token", {
        session,
        fcmToken,
    });
    if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem)
            throw problem;
    }
    return response.data;
}
export async function resetPassword(email) {
    const response = await api.huum.get("/action/forgot_password", {
        email,
        version: 3,
    });
    if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem)
            throw problem;
    }
    return response.data;
}
export async function deleteAccount({ session }) {
    const response = await api.huum.get("/action/user_delete", {
        session,
        version: 3,
    });
    if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem)
            throw problem;
    }
    return response.data;
}
