import { languageChange } from "@huum/api/requests/profile";
import { changeLanguage } from "@huum/i18n";
import { types } from "mobx-state-tree";
import { withEnvironment, withRootStore } from "..";
import api from "../../services/api";
export const AuthStoreModel = types
    .model("AuthStore")
    .props({
    sessionHash: types.maybeNull(types.string),
    accountId: types.maybeNull(types.string),
    saunaId: types.maybeNull(types.string),
    settings: types.maybe(types.frozen()),
    paymentDue: types.maybeNull(types.string),
    saunaIsPrivate: types.optional(types.boolean, true),
    language: types.optional(types.string, "en"),
})
    .actions((self) => ({
    startSession: ({ sessionHash, accountId, settings, language, }) => {
        self.sessionHash = sessionHash;
        if (accountId) {
            self.accountId = accountId;
        }
        self.settings = settings;
        self.language = language || "et";
    },
    setLanguage: (lang) => {
        languageChange({ language: lang, session: self.sessionHash });
        changeLanguage(lang);
        self.language = lang;
    },
    setNotificationSettings: (showNotification) => {
        self.settings = showNotification;
    },
}))
    .extend(withEnvironment)
    .extend(withRootStore)
    .actions((self) => ({
    logout: () => {
        api.setAuthToken(undefined);
        self.sessionHash = null;
        self.accountId = null;
        self.saunaId = null;
        self.settings = undefined;
        self.paymentDue = null;
        self.rootStore.sauna.clearSaunaState();
    },
}));
export const createAuthStoreDefaultModel = () => types.optional(AuthStoreModel, {});
