import React, { useCallback } from "react";
import { useThemedStyles } from "@huum/hooks/useThemedStyles";
import { Stack } from "native-base";
import { getStyles } from "./styles";
import TextButton from "../../molecules/text-button";
export const TEMPERATUREUNITS = {
    celsius: "celsius",
    fahrenheit: "fahrenheit",
};
const TemperatureUnitSelect = (props) => {
    const { onValueChange, selectedUnit } = props;
    const styles = useThemedStyles(getStyles);
    const celsiusStyles = selectedUnit === "celsius" ? styles.activeText : styles.text;
    const fahrenheitStyles = selectedUnit === "fahrenheit" ? styles.activeText : styles.text;
    const handleValueChange = useCallback((value) => () => {
        onValueChange(value);
    }, []);
    return (<Stack direction={"row"} space="8">
      <TextButton preset="body1" title="Celsius" style={celsiusStyles} onPress={handleValueChange("celsius")}/>
      <TextButton preset="body1" title="Fahrenheit" style={fahrenheitStyles} onPress={handleValueChange("fahrenheit")}/>
    </Stack>);
};
export default TemperatureUnitSelect;
