import OnboardingScreen from "@huum/features/authentication/screens/onboarding";
import SaunaConnectingScreen from "@huum/features/authentication/screens/sauna-connecting";
import MenuView from "@huum/features/settings";
import ProfileView from "@huum/features/settings/views/profile";
import ChangePasswordView from "@huum/features/settings/views/profile/change-password/index";
import SafetySettings from "@huum/features/settings/views/safety";
import PaymentSettings from "@huum/features/settings/views/payment";
import BillingForm from "@huum/features/settings/views/payment/credit-card-form/index";
import StatisticsView from "@huum/features/statistics";
import BookingsView from "@huum/features/booking/bookings";
import { useStores } from "@huum/store";
import { createStackNavigator } from "@react-navigation/stack";
import { observer } from "mobx-react-lite";
import { useEffect } from "react";
import { navigate } from "@huum/services/NavigationService";
import CreateBooking from "@huum/features/booking/create-bookings";
import MainControllerScreen from "@huum/features/sauna-controller/screens/main";
import WifiConfigConnectScreen from "@huum/features/iot/wifi-config-connect";
import SaunaControllersListScreen from "@huum/features/sauna-select/screens/sauna-controllers-list-screen";
import StartSmartConnectScreen from "@huum/features/iot/start-connection-flow";
import SaunaSettingsView from "@huum/features/settings/views/sauna";
import LedController from "@huum/features/led-controller";
import LedControllerSettings from "@huum/features/led-controller/led-settings-screen";
import AudioController from "@huum/features/audio-controller";
const forFade = ({ current }) => ({
    cardStyle: {
        opacity: current.progress,
    },
});
const Stack = createStackNavigator();
const mainNavigatorOptions = {
    headerShown: false,
};
const MainNavigator = observer(() => {
    const { auth, sauna } = useStores();
    useEffect(() => {
        if (!auth.sessionHash) {
            navigate("onboarding");
        }
        else if (!sauna.saunaId) {
            navigate("saunaConnect");
        }
        else {
            navigate('controller');
        }
    }, [auth.sessionHash]);
    const initialRouteName = auth.sessionHash ? "controller" : "onboarding";
    return (<Stack.Navigator initialRouteName={initialRouteName} screenOptions={mainNavigatorOptions}>
      <Stack.Group screenOptions={{
            cardStyleInterpolator: forFade
        }}>
        {!auth.sessionHash && <Stack.Screen name="onboarding" component={OnboardingScreen}/>}
      </Stack.Group>
  
      {auth.sessionHash && (<Stack.Group key={`navigation-${auth.language}`}>
            <Stack.Screen name="controller" component={MainControllerScreen}/>
            <Stack.Screen name="bookings" component={BookingsView}/>
            <Stack.Screen name="saunaControllersList" component={SaunaControllersListScreen}/>
            <Stack.Screen name="ledController" component={LedController}/>
            <Stack.Screen name="ledControllerSettings" component={LedControllerSettings}/>
            <Stack.Screen name="saunaConnect" component={SaunaConnectingScreen}/>
            <Stack.Screen name="createBooking" component={CreateBooking}/>
            <Stack.Screen name="menu" component={MenuView}/>
            <Stack.Screen name="profile" component={ProfileView}/>
            <Stack.Screen name="statistics" component={StatisticsView}/>
            <Stack.Screen name="changePassword" component={ChangePasswordView}/>
            <Stack.Screen name="safetySettings" component={SafetySettings}/>
            <Stack.Screen name="paymentSettings" component={PaymentSettings}/>
            <Stack.Screen name="billingForm" component={BillingForm}/>
            <Stack.Screen name="startSmartConnect" component={StartSmartConnectScreen}/>
            <Stack.Screen name="wifiConfigConnect" component={WifiConfigConnectScreen}/>
            <Stack.Screen name="saunaSettingsScreen" component={SaunaSettingsView}/>
            <Stack.Screen name="audioController" component={AudioController}/>
          </Stack.Group>)}
    </Stack.Navigator>);
});
export default MainNavigator;
