import * as React from "react";
import { NavigationContainer } from "@react-navigation/native";
import { navigationRef } from "@huum/services/NavigationService";
import MainNavigator from "./main";
import { navigationTheme } from "@huum/theme";
function AppNavigator(props) {
    const { isDarkMode } = props;
    return (<NavigationContainer theme={navigationTheme} ref={navigationRef}>
      <MainNavigator />
    </NavigationContainer>);
}
export default AppNavigator;
